<template>
  <div class="tool-container" v-if="toolData.id">
    <!-- 修改面包屑导航 -->
    <div class="breadcrumb-container">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item v-if="toolData.category">
          {{ toolData.category.name }}
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="toolData.subcategory">
          {{ toolData.subcategory.name }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ toolData.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-row :gutter="20" class="main-content">
      <!-- 左侧主要内容区 -->
      <el-col :span="18">
        <!-- 调整头部信息区布局 -->
        <el-card class="tool-header">
          <div class="tool-basic-info">
            <div class="logo-wrapper">
              <img :src="toolData.logo" alt="logo" class="tool-logo">
            </div>
            <div class="tool-title-area">
              <h1>{{ toolData.title }}</h1>
              <p class="tool-description">{{ toolData.description }}</p>
              <div class="tool-tags">
                <el-tag v-if="toolData.is_top" type="danger">置顶</el-tag>
                <el-tag v-if="toolData.is_featured" type="success">推荐</el-tag>
                <el-tag v-if="toolData.is_highlighted" type="warning">加精</el-tag>
                <el-tag v-if="toolData.is_chinese" type="info">国产</el-tag>
                <el-tag v-if="toolData.is_our_tool">本站</el-tag>
              </div>
            </div>
          </div>
          
          <!-- 工具操作按钮组 -->
          <div class="tool-actions">
            <el-button-group>
              <el-button 
                :type="isToolCollected ? 'primary' : 'default'"
                plain 
                @click="toggleCollectTool"
              >
                <el-icon>
                  <Star v-if="!isToolCollected" />
                  <StarFilled v-else />
                </el-icon>
                {{ isToolCollected ? '已收藏' : '收藏' }} {{ toolData.collection_count }}
              </el-button>
              <el-button 
                :type="isToolLiked ? 'primary' : 'default'"
                plain 
                @click="toggleLikeTool"
              >
                <el-icon><CaretTop /></el-icon>
                {{ isToolLiked ? '已点赞' : '点赞' }} {{ toolData.likes_count }}
              </el-button>
              <el-button type="primary" plain @click="scrollToComment">
                <el-icon><ChatLineRound /></el-icon>
                评论
              </el-button>
              <el-button type="primary" plain @click="handleShare">
                <el-icon><Share /></el-icon>
                分享
              </el-button>
              <el-button type="success" plain @click="handleFeedback">
                <el-icon><Warning /></el-icon>
                反馈
              </el-button>
            </el-button-group>
          </div>

          <!-- 工具信息卡片 -->
          <div class="tool-info-cards">
            <el-row :gutter="20">
              <!-- 价格信息卡片 -->
              <el-col :span="6">
                <el-card class="info-card" shadow="hover">
                  <div class="card-content">
                    <div class="icon-wrapper price-icon">
                      <el-icon :size="24"><Wallet /></el-icon>
                    </div>
                    <div class="info-text">
                      <h3>价格</h3>
                      <div class="status-text">
                        <el-tag 
                          :type="getPriceTagType(toolData.is_free)"
                          effect="dark"
                        >
                          {{ toolData.is_free }}
                        </el-tag>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>

              <!-- 开源信息卡片 -->
              <el-col :span="6">
                <el-card class="info-card" shadow="hover">
                  <div class="card-content">
                    <div class="icon-wrapper opensource-icon">
                      <el-icon :size="24"><Connection /></el-icon>
                    </div>
                    <div class="info-text">
                      <h3>开源</h3>
                      <div class="status-text">
                        <el-tag 
                          :type="getOpenSourceTagType(toolData.is_open_source)"
                          effect="dark"
                        >
                          {{ toolData.is_open_source }}
                        </el-tag>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>

              <!-- 广告信息卡片 -->
              <el-col :span="6">
                <el-card class="info-card" shadow="hover">
                  <div class="card-content">
                    <div class="icon-wrapper ad-icon">
                      <el-icon :size="24"><InfoFilled /></el-icon>
                    </div>
                    <div class="info-text">
                      <h3>广告</h3>
                      <div class="status-text">
                        <el-tag 
                          :type="getAdTagType(toolData.has_ads)"
                          effect="dark"
                        >
                          {{ toolData.has_ads }}
                        </el-tag>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>

              <!-- 平台支持卡片 -->
              <el-col :span="6">
                <el-card class="info-card" shadow="hover">
                  <div class="card-content">
                    <div class="icon-wrapper platform-icon">
                      <el-icon :size="24"><Monitor /></el-icon>
                    </div>
                    <div class="info-text">
                      <h3>支持平台</h3>
                      <div class="platform-tags">
                        <el-tag 
                          v-for="platform in getPlatforms(toolData.Support_Platform)"
                          :key="platform"
                          size="small"
                          class="platform-tag"
                          effect="plain"
                        >
                          {{ platform }}
                        </el-tag>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <!-- 内容标签页 -->
        <el-card class="tool-content-tabs">
          <el-tabs>
            <el-tab-pane label="简介">
              <div class="introduction-content" v-html="toolData.introduction"></div>
            </el-tab-pane>
            
            <el-tab-pane label="图片" v-if="toolData.images?.length">
              <div class="image-showcase">
                <!-- 轮播图组 -->
                <el-carousel 
                  :interval="4000" 
                  arrow="always" 
                  type="card" 
                  height="400px"
                  indicator-position="outside"
                  class="image-carousel"
                  @change="handleCarouselChange"
                >
                  <el-carousel-item v-for="img in toolData.images" :key="img.id">
                    <el-image 
                      :src="img.image" 
                      :preview-src-list="previewImages"
                      :initial-index="toolData.images.indexOf(img)"
                      fit="contain"
                      class="carousel-image"
                      :preview-teleported="true"
                    >
                      <template #placeholder>
                        <div class="image-slot">
                          <el-icon><Picture /></el-icon>
                        </div>
                      </template>
                      <template #error>
                        <div class="image-slot">
                          <el-icon><PictureRound /></el-icon>
                        </div>
                      </template>
                    </el-image>
                  </el-carousel-item>
                </el-carousel>

                
              </div>
            </el-tab-pane>
            
            <el-tab-pane label="视频" name="video">
              <div class="empty-state" v-if="!hasVideo">
                <el-empty
                  :image-size="200"
                  description="暂无相关视频"
                >
                  <template #image>
                    <el-icon :size="60" class="empty-icon">
                      <VideoCamera />
                    </el-icon>
                  </template>
                  
                  <!-- 可选：添加分享按钮 -->
                  <template #description>
                    <div class="empty-description">
                      <p>暂无相关视频</p>
                      <el-button 
                        type="primary" 
                        plain 
                        size="small"
                        @click="handleShareVideo"
                      >
                        <el-icon><Share /></el-icon>
                        分享视频
                      </el-button>
                    </div>
                  </template>
                </el-empty>
              </div>
              
              <!-- 有视频时显示的内容 -->
              <div v-else class="video-content">
                <!-- 视频内容将在这里显示 -->
              </div>
            </el-tab-pane>
            
            <el-tab-pane label="问答">
              <div v-if="toolData.related_questions && toolData.related_questions.length > 0">
                <el-card v-for="question in toolData.related_questions" 
                         :key="question.id" 
                         class="question-card clickable"
                         shadow="hover"
                         @click="openInNewTab(`/qa/question/${question.id}`)">
                  <div class="question-header">
                    <div class="question-title">
                      <el-tag v-if="question.is_solved" type="success" size="small">已解决</el-tag>
                      <div class="title-wrapper">
                        <span class="title-link">{{ question.title }}</span>
                      </div>
                    </div>
                    <div class="question-meta">
                      <el-avatar :size="24" :src="question.created_by.avatar_url" />
                      <span class="author-name">{{ question.created_by.username }}</span>
                      <span class="question-date">{{ formatDate(question.created_at) }}</span>
                    </div>
                  </div>
                  <div class="question-stats">
                    <span class="stat-item">
                      <el-icon><View /></el-icon>
                      {{ question.view_count }} 浏览
                    </span>
                    <span class="stat-item">
                      <el-icon><ChatLineSquare /></el-icon>
                      {{ question.answer_count }} 回答
                    </span>
                    <span class="stat-item">
                      <el-icon><CaretTop /></el-icon>
                      {{ question.likes_count }} 点赞
                    </span>
                  </div>
                </el-card>
              </div>
              <el-empty v-else description="暂无相关问答" />
            </el-tab-pane>
            
            <el-tab-pane label="教程">
              <div v-if="toolData.related_blogs && toolData.related_blogs.length > 0">
                <el-card v-for="blog in toolData.related_blogs" 
                         :key="blog.id" 
                         class="blog-card clickable"
                         shadow="hover"
                         @click="openInNewTab(`/blog/${blog.id}`)">
                  <div class="blog-header">
                    <div class="blog-title">
                      <el-tag :type="getBlogLevelType(blog.level)" size="small">
                        {{ getBlogLevelText(blog.level) }}
                      </el-tag>
                      <div class="title-wrapper">
                        <span class="title-link">{{ blog.title }}</span>
                      </div>
                    </div>
                    <div class="blog-meta">
                      <el-avatar :size="24" :src="blog.author.avatar_url" />
                      <span class="author-name">{{ blog.author.username }}</span>
                      <span class="blog-date">{{ formatDate(blog.created_at) }}</span>
                    </div>
                  </div>
                  <p class="blog-description">{{ blog.description }}</p>
                  <div class="blog-stats">
                    <span class="stat-item">
                      <el-icon><CaretTop /></el-icon>
                      {{ blog.likes_count }} 点赞
                    </span>
                    <span class="stat-item">
                      <el-icon><Star /></el-icon>
                      {{ blog.collection_count }} 收藏
                    </span>
                  </div>
                </el-card>
              </div>
              <el-empty v-else description="暂无相关教程" />
            </el-tab-pane>
          </el-tabs>
        </el-card>

        <!-- 评论区域 -->
        <!-- 发表评论区 -->
        <el-card class="comment-submit-section" shadow="hover">
          <template #header>
            <div class="card-header">
              <span>发表评论</span>
            </div>
          </template>

          <!-- 已登录用户显示评论框 -->
          <div v-if="isLoggedIn" class="comment-form">
            <el-input
              v-model="commentContent"
              type="textarea"
              :rows="3"
              placeholder="请输入您的评论"
            ></el-input>
            <div class="comment-button-wrapper">
              <el-button type="primary" @click="submitComment">
                提交评论
              </el-button>
            </div>
          </div>
          
          <!-- 未登录用户显示提示信息 -->
          <div v-else class="login-tip">
            <el-alert
              title="请录后发表评论"
              type="info"
              :closable="false"
              center
            >
              <template #default>
                <el-button type="primary" link @click="goToLogin">
                  去登录
                </el-button>
              </template>
            </el-alert>
          </div>
        </el-card>

        <!-- 评论展示区 -->
        <el-card class="comments-display-section" shadow="hover">
          <template #header>
            <div class="comments-header">
              <span>全部评论</span>
              <span class="comment-count">共 {{ comments.length }} 条评论</span>
            </div>
          </template>

          <div class="comments-list">
            <template v-if="comments.length">
              <comment-tree
                v-for="comment in topLevelComments"
                :key="comment.id"
                :comment="comment"
                :can-delete="canDeleteComment"
                @reply="handleReply"
                @delete="deleteComment"
                @like="(comment, callback) => likeComment(comment).then(callback)"
              />
            </template>
            <el-empty v-else description="暂无评论" />
          </div>
        </el-card>
      </el-col>

      <!-- 右侧边栏 -->
      <el-col :span="6">
        <!-- 作者信息 -->
        <el-card class="sidebar-card">
          <template #header>
            <div class="card-header">
              <span>作者</span>
            </div>
          </template>
          <div class="author-info">
            <img :src="toolData.logo" class="author-logo">
            <h3>{{ toolData.author }}</h3>
          </div>
        </el-card>

        <!-- 快捷导航 -->
        <el-card class="sidebar-card">
          <template #header>
            <div class="card-header">
              <span>导航</span>
            </div>
          </template>
          <div class="nav-buttons">
            <el-button v-if="toolData.toolpage_url" type="primary" block @click="openUrl(toolData.toolpage_url)">
              软件首页
            </el-button>
            <el-button v-if="toolData.doc_url" type="primary" block @click="openUrl(toolData.doc_url)">
              软件档
            </el-button>
            <el-button v-if="toolData.official_download_url" type="primary" block @click="openUrl(toolData.official_download_url)">
              官方下载
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
  <div v-else class="loading-container">
    <el-skeleton :rows="10" animated />
  </div>
  <TicketDialog
    v-model="ticketDialogVisible"
    :tool-id="route.params.id"
  />
  <!-- 添加反馈对话框组件 -->
  <feedback-dialog ref="feedbackDialogRef" />
</template>

<style scoped>
.tool-container {
  max-width: 1200px;
  margin: 0 auto;
 
}

.tool-header {
  margin-bottom: 20px;
}

.tool-basic-info {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.tool-logo {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.tool-tags {
  margin-top: 10px;
  display: flex;
  gap: 8px;
}

.tool-actions {
  margin: 20px 0;
}

.tool-info-cards {
  margin: 20px 0;
}

.info-card {
  height: auto;
  min-height: 120px;
  transition: all 0.3s;
}

.info-card:hover {
  transform: translateY(-2px);
}

.card-content {
  min-height: 80px;
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.icon-wrapper {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.price-icon {
  background-color: #f0f9eb;
  color: #67c23a;
}

.ad-icon {
  background-color: #fdf6ec;
  color: #e6a23c;
}

.platform-icon {
  background-color: #ecf5ff;
  color: #409eff;
}

.info-text {
  flex: 1;
  min-width: 0;
}

.info-text h3 {
  margin: 0 0 12px 0;
  font-size: 16px;
  color: #606266;
}

.status-text {
  font-size: 14px;
}

.platform-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.platform-tag {
  margin: 0;
  white-space: nowrap;
}

.sidebar-card {
  margin-bottom: 20px;
}

.author-info {
  text-align: center;
}

.author-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.nav-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loading-container {
  padding: 20px;
}

.introduction-content {
  margin-bottom: 20px;
}

.image-showcase {
  padding: 20px;
}

.image-carousel {
  margin-bottom: 20px;
}

.carousel-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #f5f7fa;
}

.image-thumbnails {
  margin-top: 20px;
  padding: 10px 0;
}

.thumbnail-container {
  display: flex;
  gap: 10px;
  padding: 0 10px;
}

.thumbnail-item {
  flex: 0 0 100px;
  height: 70px;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.thumbnail-item:hover {
  border-color: #409EFF;
}

.thumbnail-item.active {
  border-color: #409EFF;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

/* 优化图片览的样 */
:deep(.el-image-viewer__wrapper) {
  z-index: 2100;
}

:deep(.el-image-viewer__close) {
  color: #fff;
}

:deep(.el-carousel__item) {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f7fa;
}

.video-container {
  margin-bottom: 20px;
}

.comment-section {
  margin-top: 20px;
}

.comment-header {
  margin-bottom: 20px;
}

.comment-input {
  margin-bottom: 20px;
}

.comment-button-wrapper {
  display: flex;
  justify-content: flex-end;

}

/* 调整面包屑导航样式 */
.breadcrumb-container {
  padding: 20px;
}

/* 调整logo相关样式 */
.logo-wrapper {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  background: #f5f7fa;
  border-radius: 8px;
  overflow: hidden;
}

.tool-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.tool-basic-info {
  display: flex;
  align-items: flex-start;
  padding: 20px;
}

.tool-title-area {
  flex: 1;
}

.tool-title-area h1 {
  margin: 0 0 12px 0;
  font-size: 24px;
  line-height: 1.4;
}

.tool-description {
  margin: 0 0 16px 0;
  color: #666;
  line-height: 1.6;
}

.tool-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.empty-state {
  padding: 40px 0;
  text-align: center;
  background: #f5f7fa;
  border-radius: 8px;
}

.empty-icon {
  color: #909399;
  margin-bottom: 20px;
}

.empty-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.empty-description p {
  color: #909399;
  font-size: 14px;
  margin: 0;
}

.video-content {
  min-height: 400px;
  padding: 20px;
}

/* 论区样式 */
.layui-elem-field {
  margin-top: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.layui-elem-field legend {
  margin-left: 20px;
  padding: 0 10px;
  font-size: 20px;
  font-weight: 300;
}

.layui-field-box {
  padding: 20px;
}

/* 确保评论区与其他内容样式一致 */
.layui-elem-field h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.comment-input :deep(.el-textarea__inner) {
  resize: none;
  font-size: 14px;
  padding: 12px;
}

.comment-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #ebeef5;
}

.comment-list {
  margin-top: 20px;
}

.comment-item {
  margin-bottom: 20px;
  padding-left: 20px;
  border-left: 2px solid #ebeef5;
}

.comment-user {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.comment-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-username {
  font-weight: bold;
  margin-right: 10px;
}

.comment-date {
  color: #909399;
}

.comment-text {
  margin-bottom: 10px;
}

.comment-actions {
  display: flex;
  gap: 10px;
}

.comment-children {
  margin-left: 20px;
  border-left: 1px dashed #ebeef5;
  padding-left: 10px;
}

/* 添加登录提示样式 */
.login-tip {
  padding: 20px 0;
  text-align: center;
}

.login-tip :deep(.el-alert__content) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* 评论区样式 */
.comment-submit-section {
  margin-top: 20px;
  margin-bottom: 20px;
}

.comments-display-section {
  margin-bottom: 20px;
}

.comments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment-count {
  color: #909399;
  font-size: 14px;
}

.comment-form {
  padding: 0 20px;
}

.comment-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #ebeef5;
}



/* 登录提示样式 */
.login-tip {
  padding: 40px 0;
  text-align: center;
  background: #f5f7fa;
  border-radius: 4px;
}

.login-tip :deep(.el-alert) {
  width: fit-content;
  margin: 0 auto;
  background: transparent;
  padding: 0;
  border: none;
}

.login-tip :deep(.el-alert__content) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.login-tip :deep(.el-alert__title) {
  font-size: 14px;
  margin: 0;
  color: #606266;
}

/* 评论输入框样式 */
:deep(.el-textarea__inner) {
  resize: none;
  font-size: 14px;
  padding: 12px;
}


/* 添加新的样式：为问答和教程列表容器添加顶部内边距 */
.el-tab-pane {
  padding-top: 5px;  /* 添加顶部内边距，防止hover效果导致内容被遮挡 */
}
.question-header, .blog-header {
  margin-bottom: 12px;
}

.question-title, .blog-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.title-wrapper {
  flex: 1;
  min-width: 0;
}

.title-link {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.title-link:hover {
  color: #409EFF;
  text-decoration: underline;
}

.question-meta, .blog-meta {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #909399;
  font-size: 14px;
}

.author-name {
  color: #606266;
}

.question-date, .blog-date {
  color: #909399;
}

.blog-description {
  color: #606266;
  margin: 12px 0;
  line-height: 1.5;
}

.question-stats, .blog-stats {
  display: flex;
  gap: 16px;
  color: #909399;
  font-size: 14px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.stat-item .el-icon {
  margin-right: 4px;
}

.clickable {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.clickable:hover {
  transform: translateY(-2px);
}
</style>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user'
import { Star, ChatLineRound, Share, Warning, Picture, PictureRound, VideoCamera, Wallet, InfoFilled, Monitor, StarFilled, CaretTop, Connection, View, ChatLineSquare } from '@element-plus/icons-vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import axiosInstance from '@/utils/axios'
import TicketDialog from '@/components/TicketDialog.vue'
import CommentTree from '@/components/CommentTree.vue'
import FeedbackDialog from '@/components/FeedbackDialog.vue'
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const toolData = ref({
  id: '',
  images: [],
  // ... 其他数据
});

const fetchToolData = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/tools/tools/${route.params.id}/`)
    toolData.value = response.data
  } catch (error) {
    console.error('获取工具数据失败:', error)
    ElMessage.error('获取工具数据失败，请稍后重试')
  }
}

const openUrl = (url) => {
  window.open(url, '_blank');
};

// 当前显示的图片索引
const currentImageIndex = ref(0);

// 预览图片列表
const previewImages = computed(() => 
  toolData.value.images?.map(img => img.image) || []
);


// 监听播图切换
const handleCarouselChange = (index) => {
  currentImageIndex.value = index;
};

// 是否有视频的状态
const hasVideo = ref(false);

// 处理视频分享
const handleShareVideo = () => {
  ElMessage({
    message: '视频分享能开发中...',
    type: 'info'
  });
};

onMounted(() => {
  fetchToolData();
});

// 获取广告标签类型
const getAdTagType = (adText) => {
  switch(adText) {
    case '没有': return 'success';
    case '有：影响使用': return 'danger';
    case '有：不影响使用': return 'warning';
    default: return 'info';
  }
}

// 处理平台数据
const getPlatforms = (platforms) => {
  if (!platforms || !Array.isArray(platforms)) return [];
  return platforms.map(platform => platform.name);
}

// 评论相关的状态
const commentContent = ref('')
const comments = ref([])

// 获取评论列表
const fetchComments = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/tools/tools/${route.params.id}/comments/`)
    comments.value = response.data
  } catch (error) {
    console.error('获取评论失败:', error)
    ElMessage.error('获取评论失败，请稍后重试')
  }
}

// 提交评论
const submitComment = async () => {
  const token = localStorage.getItem('accessToken')
  if (!token) {
    ElMessage.warning('请先登录后再发表评论')
    return
  }

  if (!commentContent.value.trim()) {
    ElMessage.warning('评论内容不能为空')
    return
  }

  try {
    await axiosInstance.post(`/api/v1/tools/tools/${route.params.id}/add_comment/`, {
      text: commentContent.value,
      parent: null
    })
    
    ElMessage.success('评论发表成功')
    commentContent.value = ''
    await fetchComments()
  } catch (error) {
    console.error('提交评论失败:', error)
    ElMessage.error('提交评论失败，请稍后重试')
  }
}

// 处理回复
const handleReply = async (comment, content) => {
  try {
    await axiosInstance.post(`/api/v1/tools/tools/${route.params.id}/add_comment/`, {
      text: content,
      parent: comment.id
    })
    
    ElMessage.success('回复成功')
    await fetchComments()
  } catch (error) {
    console.error('提交回复失:', error)
    ElMessage.error('提交回复失败，请稍后重试')
  }
}

// 评论点赞处理
const likeComment = async (comment) => {
  if (!userStore.isAuthenticated) {
    ElMessage.warning('请先登录后再点赞')
    return
  }

  try {
    const response = await axiosInstance.post(
      `/api/v1/tools/tools/${route.params.id}/comments/${comment.id}/toggle_like/`,
      {},
      { requiresAuth: true }
    )
    
    // 更新评论的点赞状态
    if (response.data) {
      comment.is_liked = !comment.is_liked
      comment.likes_count = response.data.likes_count
    }
    return response.data
  } catch (error) {
    console.error('评论点赞失败:', error)
    ElMessage.error('操作失败，请稍后重试')
    throw error
  }
}

// 删除评论
const deleteComment = async (comment) => {
  try {
    await ElMessageBox.confirm('确定要删除这条评论吗?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })

    await axiosInstance.delete(`/api/v1/tools/tools/${route.params.id}/comments/${comment.id}/`)
    ElMessage.success('评论删除成功')
    await fetchComments()
  } catch (error) {
    if (error !== 'cancel') {
      console.error('删除评论失败:', error)
      ElMessage.error('删除评论失败，请稍后重试')
    }
  }
}

// 判断是否可以删除评论
const canDeleteComment = (comment) => {
  const currentUser = localStorage.getItem('userName')
  return comment.user === currentUser
}

// 过滤出顶级评论
const topLevelComments = computed(() => {
  return comments.value.filter(comment => comment.parent === null)
})

// 处理工具点赞
const toggleLikeTool = async () => {
  if (!userStore.isAuthenticated) {
    ElMessage.warning('请先登录后再点赞')
    return
  }

  try {
    const response = await axiosInstance.post(
      `/api/v1/tools/tools/${route.params.id}/toggle_like/`,
      {},
      { requiresAuth: true }
    )
    
    if (response.data) {
      toolData.value = {
        ...toolData.value,
        is_liked: !toolData.value.is_liked,
        likes_count: response.data.likes_count
      }
      ElMessage.success(toolData.value.is_liked ? '点赞成功' : '已取消点赞')
    }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || '操作失败'
    ElMessage.error(errorMessage)
  }
}

// 处理工具收藏
const toggleCollectTool = async () => {
  if (!userStore.isAuthenticated) {
    ElMessage.warning('请先登录后再收藏')
    return
  }

  try {
    const response = await axiosInstance.post(
      `/api/v1/tools/tools/${route.params.id}/toggle_collection/`,
      {},
      { requiresAuth: true }
    )

    if (response.data) {
      toolData.value = {
        ...toolData.value,
        is_collected: !toolData.value.is_collected,
        collection_count: response.data.collection_count
      }
      ElMessage.success(toolData.value.is_collected ? '收藏成功' : '已取消收藏')
    }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || '操作失败'
    ElMessage.error(errorMessage)
  }
}

// 在 onMounted 中获取数据
onMounted(() => {
  fetchToolData()
  fetchComments()
})

// 当工具数据加载完成后设置标题
watch(() => toolData.value, (newVal) => {
  if (newVal?.title) {
    document.title = `工具 - ${newVal.title}`
  }
}, { deep: true })

// 添加登录状态检查
const isLoggedIn = computed(() => {
  return !!localStorage.getItem('accessToken')
})

// 在 script setup 中添加 goToLogin 方法
const goToLogin = () => {
  router.push({
    path: '/login',
    query: {
      redirect: route.fullPath // 保存当前完整的路由路径
    }
  });
};

// 将点赞和收藏状态改为计算属性
const isToolLiked = computed(() => {
  return userStore.isAuthenticated && toolData.value.is_liked
})

const isToolCollected = computed(() => {
  return userStore.isAuthenticated && toolData.value.is_collected
})

// 监听登录状态变化
watch(() => userStore.isAuthenticated, (newVal, oldVal) => {
  if (!newVal && oldVal) {  // 从登录状态变为未登录状态
    // 重置所有评论的点赞状态
    comments.value = comments.value.map(comment => ({
      ...comment,
      is_liked: false,
      replies: comment.replies ? comment.replies.map(reply => ({
        ...reply,
        is_liked: false
      })) : []
    }))
  }
})

// 添加反馈对话框引用
const feedbackDialogRef = ref(null)

// 处理反馈按钮点击
const handleFeedback = () => {
  feedbackDialogRef.value?.open()
}

// 获取价格标签类型
const getPriceTagType = (priceText) => {
  switch(priceText) {
    case '免费': return 'success';
    case '基础功能免费': return 'warning';
    case '收费': return 'danger';
    default: return 'info';
  }
}

// 获取开源标签类型
const getOpenSourceTagType = (openSourceText) => {
  switch(openSourceText) {
    case '开源': return 'success';
    case '不开源': return 'danger';
    case '不确定': return 'info';
    default: return 'info';
  }
}

// 添加新的方法
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('zh-CN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

const getBlogLevelType = (level) => {
  const types = {
    'beginner': 'success',
    'intermediate': 'warning',
    'advanced': 'danger'
  };
  return types[level] || 'info';
}

const getBlogLevelText = (level) => {
  const texts = {
    'beginner': '入门',
    'intermediate': '进阶',
    'advanced': '高级'
  };
  return texts[level] || '其他';
}

// 添加新的方法
const openInNewTab = (path) => {
  const baseUrl = window.location.origin;
  window.open(baseUrl + path, '_blank');
};
</script>
