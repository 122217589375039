import axios from 'axios'
import { API_BASE_URL } from '@/config'

export const searchService = {
  // 全局搜索
  globalSearch(query) {
    return axios.get(`${API_BASE_URL}/api/v1/search/`, {
      params: { q: query }
    })
  },

  // 分类搜索
  searchByType(type, params) {
    return axios.get(`${API_BASE_URL}/api/v1/search/${type}/`, {
      params: {
        q: params.query,
        category: params.category,
        platform: params.platform,
        tag: params.tag
      }
    })
  }
}