import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { API_BASE_URL } from '@/config'
import axios from 'axios'

// 定义 Token 相关常量
const TOKEN_CONFIG = {
  ACCESS_TOKEN_EXPIRES: 2 * 60 * 60 * 1000,  // 2小时
  REFRESH_TOKEN_EXPIRES: 7 * 24 * 60 * 60 * 1000,  // 7天
  REFRESH_THRESHOLD: 5 * 60 * 1000,  // 提前5分钟刷新
  MAX_REFRESH_ATTEMPTS: 3  // 添加最大刷新尝试次数
}

export const useUserStore = defineStore('user', () => {
  const accessToken = ref(localStorage.getItem('accessToken') || '')
  const refreshToken = ref(localStorage.getItem('refreshToken') || '')
  const userInfo = ref({
    id: localStorage.getItem('userId') || '',
    username: localStorage.getItem('userName') || '',
    email: localStorage.getItem('userEmail') || '',
    avatar: localStorage.getItem('userAvatar') || ''
  })

  const isAuthenticated = computed(() => !!accessToken.value)

  // 添加 token 时间戳
  const tokenTimestamp = ref({
    accessTokenCreatedAt: parseInt(localStorage.getItem('accessTokenCreatedAt')) || 0,
    refreshTokenCreatedAt: parseInt(localStorage.getItem('refreshTokenCreatedAt')) || 0
  })

  // 添加请求队列
  let refreshQueue = []
  let isRefreshing = false

  // 添加刷新尝试计数
  let refreshAttempts = 0

  // 检查 token 是否即将过期
  const isTokenExpiringSoon = computed(() => {
    const now = Date.now()
    const expiresAt = tokenTimestamp.value.accessTokenCreatedAt + TOKEN_CONFIG.ACCESS_TOKEN_EXPIRES
    return now > expiresAt - TOKEN_CONFIG.REFRESH_THRESHOLD
  })

  // 更新设置令牌的方法
  const setTokens = ({ access, refresh }) => {
    accessToken.value = access
    refreshToken.value = refresh
    tokenTimestamp.value = {
      accessTokenCreatedAt: Date.now(),
      refreshTokenCreatedAt: Date.now()
    }
    
    localStorage.setItem('accessToken', access)
    localStorage.setItem('refreshToken', refresh)
    localStorage.setItem('accessTokenCreatedAt', tokenTimestamp.value.accessTokenCreatedAt)
    localStorage.setItem('refreshTokenCreatedAt', tokenTimestamp.value.refreshTokenCreatedAt)
  }

  // 设置用户信息
  const setUserInfo = (info) => {
    userInfo.value = info
    localStorage.setItem('userInfo', JSON.stringify(info))
    localStorage.setItem('userId', info.id)
    localStorage.setItem('userName', info.username)
    localStorage.setItem('userEmail', info.email)
    localStorage.setItem('userAvatar', info.avatar || '')
  }

  // 添加更新用户信息的方法
  const updateUserInfo = (info) => {
    // 更新现有用户信息，保留未更新的字段
    userInfo.value = {
      ...userInfo.value,
      ...info
    }
    
    // 更新 localStorage
    if (info.username) localStorage.setItem('userName', info.username)
    if (info.email) localStorage.setItem('userEmail', info.email)
    if (info.avatar) localStorage.setItem('userAvatar', info.avatar)
    if (info.id) localStorage.setItem('userId', info.id)
  }

  // 优化刷新令牌方法
  const refreshAccessToken = async () => {
    if (refreshAttempts >= TOKEN_CONFIG.MAX_REFRESH_ATTEMPTS) {
      clearUserInfo()
      throw new Error('超过最大刷新次数限制')
    }
    
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshQueue.push({ resolve, reject })
      })
    }

    refreshAttempts++
    isRefreshing = true

    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/user/token/refresh/`, {
        refresh: refreshToken.value
      })
      
      setTokens({
        access: response.data.access,
        refresh: refreshToken.value
      })

      // 处理队列中的请求
      refreshQueue.forEach(({ resolve }) => {
        resolve(response.data.access)
      })
      refreshQueue = []

      return response.data.access
    } catch (error) {
      refreshQueue.forEach(({ reject }) => {
        reject(error)
      })
      refreshQueue = []
      clearUserInfo()
      throw error
    } finally {
      isRefreshing = false
    }
  }

  // 添加自动刷新检查
  const checkAndRefreshToken = async () => {
    if (isTokenExpiringSoon.value && accessToken.value) {
      try {
        await refreshAccessToken()
      } catch (error) {
        console.error('Token refresh failed:', error)
      }
    }
  }

  // 优化清除用户信息方法
  const clearUserInfo = () => {
    // 清理 Pinia 状态
    accessToken.value = ''
    refreshToken.value = ''
    userInfo.value = {}
    refreshAttempts = 0
    refreshQueue = []
    isRefreshing = false

    // 清理 localStorage
    const keysToRemove = [
      'accessToken',
      'refreshToken',
      'userId',
      'userName',
      'userEmail',
      'userAvatar',
      'accessTokenCreatedAt',
      'refreshTokenCreatedAt',
      'userInfo'
    ]
    keysToRemove.forEach(key => localStorage.removeItem(key))

    // 清理 cookies（如果有使用）
    document.cookie.split(';').forEach(cookie => {
      document.cookie = cookie
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)
    })
  }

  // 检查并更新认证状态
  const checkAuthStatus = async () => {
    if (!accessToken.value) {
      return false
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/user/detail/`, {
        headers: { Authorization: `Bearer ${accessToken.value}` }
      })
      setUserInfo(response.data)
      return true
    } catch (error) {
      if (error.response?.status === 401) {
        try {
          await refreshAccessToken()
          return true
        } catch (refreshError) {
          clearUserInfo()
          return false
        }
      }
      return false
    }
  }

  // 添加 localStorage 事件监听
  const setupTokenSync = () => {
    window.addEventListener('storage', (event) => {
      switch (event.key) {
        case 'accessToken':
          accessToken.value = event.newValue || ''
          break
        case 'refreshToken':
          refreshToken.value = event.newValue || ''
          break
        case 'userInfo':
          try {
            userInfo.value = event.newValue ? JSON.parse(event.newValue) : {}
          } catch (e) {
            console.error('解析用户信息失败:', e)
            userInfo.value = {}
          }
          break
        case 'logout':
          // 其他标签页触发登出
          if (event.newValue === 'true') {
            clearUserInfo()
            window.location.href = '/login'
          }
          break
      }
    })
  }

  // 添加登出方法
  const logout = async () => {
    try {
      // 调用登出 API（如果有的话）
      if (accessToken.value) {
        try {
          await axios.post(`${API_BASE_URL}/api/v1/user/logout/`, {}, {
            headers: { Authorization: `Bearer ${accessToken.value}` }
          })
        } catch (error) {
          console.error('登出 API 调用失败:', error)
        }
      }
    } finally {
      // 清理所有状态
      clearUserInfo()
      // 通知其他标签页
      localStorage.setItem('logout', 'true')
      // 延迟移除登出标记
      setTimeout(() => localStorage.removeItem('logout'), 100)
    }
  }

  // 初始化时设置同步监听
  setupTokenSync()

  // 添加 userId 计算属性
  const userId = computed(() => userInfo.value.id || '')

  return {
    accessToken,
    refreshToken,
    userInfo,
    userId,
    isAuthenticated,
    setTokens,
    setUserInfo,
    updateUserInfo,
    refreshAccessToken,
    clearUserInfo,
    checkAuthStatus,
    isTokenExpiringSoon,
    checkAndRefreshToken,
    logout,
  }
}) 