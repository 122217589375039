<template>
  <div class="qa-container">
    <el-row :gutter="20">
      <!-- 左侧列表区域 -->
      <el-col :span="18">
        <div class="question-list">
          <div v-for="question in questions" :key="question.id" class="question-item">
            <!-- 问题主体 -->
            <div class="question-main">
              <!-- 标题 -->
              <router-link 
                :to="`/qa/question/${question.id}`" 
                class="question-title"
              >
                {{ question.title }}
              </router-link>
              
              <!-- 问题描述 - 使用 v-html 来渲染 HTML 内容 -->
              <div class="question-desc" v-html="processContent(question.content)"></div>
            </div>

            <!-- 问题信息 -->
            <div class="question-info">
              <!-- 作者信息 -->
              <div class="author-info">
                <el-avatar 
                  :size="24" 
                  :src="API_BASE_URL + question.created_by.avatar"
                />
                <span class="author-name">{{ question.created_by.username }}</span>
                <span class="post-time">{{ formatDate(question.created_at) }}</span>
              </div>

              <!-- 统计信息 -->
              <div class="stats">
                <span class="stat-item">
                  <el-icon><View /></el-icon>
                  {{ question.view_count }} 浏览
                </span>
                <span class="stat-item">
                  <el-icon><ChatRound /></el-icon>
                  {{ question.answer_count }} 回答
                </span>
                <span class="stat-item">
                  <el-icon><Star /></el-icon>
                  {{ question.collection_count }} 收藏
                </span>
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <!-- 右侧功能区域 -->
      <el-col :span="6" class="sidebar">
        <!-- 提问按钮 -->
        <el-card class="sidebar-card ask-card">
          <el-button 
            type="primary" 
            size="large" 
            class="ask-button"
            @click="goToAsk"
          >
            <el-icon><Plus /></el-icon> 我要提问
          </el-button>
        </el-card>

        <!-- 最新问题列表 -->
        <el-card class="sidebar-card">
          <template #header>
            <div class="card-header">
              <span>最新问题</span>
            </div>
          </template>
          <ul class="latest-list">
            <li v-for="item in latestQuestions" :key="item.id">
              <router-link :to="`/qa/question/${item.id}`">{{ item.title }}</router-link>
              <span class="time">{{ formatDate(item.created_at) }}</span>
            </li>
          </ul>
        </el-card>

        <!-- 热门标签 -->
        <el-card class="sidebar-card">
          <template #header>
            <div class="card-header">
              <span>热门标签</span>
            </div>
          </template>
          <div class="tag-cloud">
            <el-tag
              v-for="tag in hotTags"
              :key="tag.id"
              :type="tag.type"
              effect="plain"
              class="tag-item"
              @click="searchByTag(tag)"
            >
              {{ tag.name }}
              <span class="tag-count">({{ tag.count }})</span>
            </el-tag>
          </div>
        </el-card>

        <!-- 广告区域 -->
        <el-card class="sidebar-card ad-card">
          <template #header>
            <div class="card-header">
              <span>推广</span>
            </div>
          </template>
          <div class="ad-content">
            <img src="https://via.placeholder.com/300x200" alt="广告" class="ad-image">
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.qa-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* 问题列表样式优化 */
.question-list {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  box-shadow: 0 1px 3px rgba(18, 18, 18, 0.1);
}

.question-item {
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
  transition: all 0.3s ease;
  position: relative;
}

.question-item:last-child {
  border-bottom: none;
}

.question-item:hover {
  background-color: #fafafa;
}

.question-item::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: #f0f0f0;
}

.question-item:last-child::after {
  display: none;
}

.question-main {
  margin-bottom: 12px;
}

.question-title {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #121212;
  text-decoration: none;
  margin-bottom: 8px;
  line-height: 1.6;
}

.question-title:hover {
  color: #175199;
}

.question-desc {
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.question-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 13px;
  color: #8590a6;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.author-name {
  color: #444;
  font-weight: 500;
}

.post-time {
  color: #8590a6;
  font-size: 12px;
}

.stats {
  display: flex;
  align-items: center;
  gap: 16px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #8590a6;
  font-size: 12px;
}

.stat-item:hover {
  color: #175199;
}

/* 右侧卡片样式优化 */
.sidebar-card {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 1px 3px rgba(18, 18, 18, 0.1);
}

.card-header {
  padding: 12px 16px;
  font-weight: 500;
}

/* 添加一些间距和过渡效果 */
.qa-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.el-row {
  margin: 0 !important;
}

.el-col {
  padding: 0 10px;
}

/* 右侧样式保持不变 */
.sidebar {
  position: sticky;
  top: 20px;
}

.ask-card {
  padding: 20px;
  text-align: center;
}

.ask-button {
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.latest-list {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 8px;
}

.latest-list li {
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.latest-list li:last-child {
  border-bottom: none;
}

.latest-list a {
  color: #121212;
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin-bottom: 4px;
}

.latest-list .time {
  font-size: 12px;
  color: #8590a6;
}

.tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 8px;
}

.tag-item {
  cursor: pointer;
  transition: all 0.3s;
}

.tag-item:hover {
  transform: translateY(-2px);
}

.tag-count {
  margin-left: 4px;
  font-size: 12px;
  color: #8590a6;
}

.ad-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

/* 保持其他现有样式不变 */
</style>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { View, ChatRound, Star, Plus } from '@element-plus/icons-vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { API_BASE_URL } from '@/config'
import { useUserStore } from '@/stores/user'

const router = useRouter()
const userStore = useUserStore()
const questions = ref([])
const latestQuestions = ref([])
const hotTags = ref([])

// 修改跳转到提问页面的方法
const goToAsk = () => {
  // 使用 pinia store 检查用户是否已登录
  const isLoggedIn = userStore.isAuthenticated
  
  if (!isLoggedIn) {
    ElMessage({
      message: '请先登录后再提问',
      type: 'warning',
      duration: 2000
    })
    router.push({
      path: '/login',
      query: { redirect: router.currentRoute.value.fullPath }
    })
  } else {
    // 修改为用户中心的发布问题路径
    router.push({
      name: 'PublishQuestion'  // 或者直接使用路径 '/user/publish-question'
    })
  }
}

// 格式化日期
const formatDate = (dateString) => {
  const date = new Date(dateString)
  const now = new Date()
  const diff = now - date

  // 如果小于24小时，显示"x小时前"
  if (diff < 24 * 60 * 60 * 1000) {
    const hours = Math.floor(diff / (60 * 60 * 1000))
    return `${hours}小时前`
  }

  // 如果小于30天，显示"x天前"
  if (diff < 30 * 24 * 60 * 60 * 1000) {
    const days = Math.floor(diff / (24 * 60 * 60 * 1000))
    return `${days}天前`
  }

  // 否则显示具体日期
  return date.toLocaleDateString('zh-CN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

// 获取问题列表
const fetchQuestions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/qa/questions/`)
    questions.value = response.data.results
  } catch (error) {
    console.error('获取问题列表失败:', error)
    ElMessage.error('获取问题列表失败')
  }
}

// 获取最新问题
const fetchLatestQuestions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/qa/questions/`, {
      params: {
        page: 1,
        page_size: 5,
        ordering: '-created_at'
      }
    })
    latestQuestions.value = response.data.results
  } catch (error) {
    console.error('获取最新问题失败:', error)
  }
}

// 按标签搜索
const searchByTag = (tag) => {
  router.push({
    path: '/qa',
    query: { tag: tag.name }
  })
}

// 添加处理内容的方法
const processContent = (content) => {
  if (!content) return ''
  
  // 替换图片的 src 属性，添加基础 URL
  return content.replace(
    /<img.*?src="(.*?)".*?>/g, 
    (match, src) => {
      // 如果已经是完整的 URL，则不做处理
      if (src.startsWith('http') || src.startsWith('https')) {
        return match
      }
      // 否则添加基础 URL
      return match.replace(src, `${API_BASE_URL}${src}`)
    }
  ).replace(/<\/?p>/g, '') // 保持原有的 p 标签处理
}

// 修改获取标签的方法
const fetchTags = async () => {
  try {
    // 获取所有标签
    let allTags = [];
    let nextUrl = `${API_BASE_URL}/api/v1/qa/tags/`;
    
    while (nextUrl) {
      const response = await axios.get(nextUrl);
      allTags = [...allTags, ...response.data.results];
      nextUrl = response.data.next;
    }

    const types = ['', 'success', 'warning', 'danger', 'info'];
    hotTags.value = allTags.map((tag, index) => ({
      ...tag,
      type: types[index % types.length],
      count: tag.question_count
    }));
  } catch (error) {
    console.error('获取标签列表失败:', error);
    ElMessage.error('获取标签列表失败');
  }
};

// 在组件挂载时获取标签数据
onMounted(() => {
  fetchQuestions()
  fetchLatestQuestions()
  fetchTags()
})
</script>
