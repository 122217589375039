<template>
  <div class="qa-container">
    <!-- 1. 问题展示区域 -->
    <el-card class="section question-section" v-if="question">
      <div class="question-header">
        <h1 class="title">{{ question.title }}</h1>
        <div class="meta">
          <div class="author-info">
            <el-avatar :size="40" :src="API_BASE_URL + question.created_by.avatar" />
            <span class="username">{{ question.created_by.username }}</span>
            <span class="time">提问于 {{ formatDate(question.created_at) }}</span>
          </div>
          <div class="stats">
            <span><el-icon><View /></el-icon> {{ question.view_count }}</span>
            <el-button-group>
              <el-button 
                :type="question.is_liked ? 'primary' : ''" 
                @click="handleQuestionLike"
                :disabled="question.created_by.id === userStore.userId"
              >
                <el-icon><CaretTop /></el-icon> {{ question.likes_count }}
              </el-button>
              <el-button 
                :type="question.is_collected ? 'primary' : ''" 
                @click="handleCollect"
                :disabled="question.created_by.id === userStore.userId"
              >
                <el-icon><Star /></el-icon> {{ question.collection_count }}
              </el-button>
              <el-button :type="question.is_followed ? 'success' : ''" @click="handleFollow">
                <el-icon><Bell /></el-icon> 关注
              </el-button>
            </el-button-group>
          </div>
        </div>
      </div>

      <div class="question-content">
        <div class="markdown-body" v-html="question.content"></div>
        <div class="tags">
          <el-tag v-for="tag in question.tags" :key="tag.id" size="small">{{ tag.name }}</el-tag>
          <el-tag size="small" type="info">{{ question.keywords }}</el-tag>
        </div>
      </div>
    </el-card>

    <!-- 2. 回答编辑区域 -->
    <el-card class="section answer-editor" v-if="isLoggedIn">
      <template #header>
        <div class="section-header">
          <h2>撰写回答</h2>
        </div>
      </template>
      <el-input
        v-model="newAnswer"
        type="textarea"
        :rows="6"
        placeholder="写下你的回答..."
      />
      <div class="submit-area">
        <el-button 
          type="primary" 
          @click="submitAnswer"
          :loading="submitting"
          :disabled="!newAnswer.trim()"
        >
          发布回答
        </el-button>
      </div>
    </el-card>

    <!-- 3. 回答列表区域 -->
    <el-card class="section answers-section">
      <template #header>
        <div class="section-header">
          <h2>{{ answers.length }} 个回答</h2>
          <el-radio-group v-model="sortType" size="small">
            <el-radio-button label="newest">最新</el-radio-button>
            <el-radio-button label="likes">最热</el-radio-button>
          </el-radio-group>
        </div>
      </template>

      <div class="answers-list">
        <template v-if="answers.length > 0">
          <div v-for="answer in sortedAnswers" :key="answer.id" class="answer-item">
            <div class="answer-meta">
              <div class="user-info">
                <el-avatar :size="30" :src="API_BASE_URL + answer.user.avatar" />
                <span class="username">{{ answer.user.username }}</span>
                <span class="time">回答于 {{ formatDate(answer.created_at) }}</span>
                <el-tag v-if="answer.is_accepted" type="success" size="small">已采纳</el-tag>
              </div>
              <div class="vote-area">
                <el-button 
                  circle 
                  :type="answer.is_liked ? 'primary' : ''"
                  @click="handleAnswerLike(answer)"
                  :loading="loading"
                  :disabled="answer.user.id === userStore.userId"
                >
                  <el-icon><CaretTop /></el-icon>
                </el-button>
                <span>{{ answer.likes_count }}</span>
              </div>
            </div>
            
            <div class="answer-content">
              <div class="markdown-body" v-html="answer.content"></div>
            </div>

            <div class="answer-actions">
              <el-button 
                v-if="isAuthor && question && !question.accepted_answer"
                type="success" 
                size="small"
                @click="acceptAnswer(answer)"
              >采纳此答案</el-button>
              <el-button 
                size="small"
                @click="toggleComments(answer)"
              >
                <el-icon><ChatLineRound /></el-icon>
                评论 ({{ answer.comments_count || 0 }})
              </el-button>
            </div>

            <!-- 评论区 -->
            <div class="comments-area">
              <!-- 主评论输入框 -->
              <div class="add-comment" v-if="isLoggedIn">
                <el-input
                  v-model="answer.newComment"
                  size="small"
                  placeholder="添加评论..."
                  @keyup.enter="submitComment(answer)"
                >
                  <template #append>
                    <el-button @click="submitComment(answer)">评论</el-button>
                  </template>
                </el-input>
              </div>

              <!-- 评论列表 -->
              <div class="comments-list">
                <template v-for="comment in displayComments(answer)" :key="comment.id">
                  <!-- 主评论 -->
                  <div class="comment-item">
                    <el-avatar :size="24" :src="getAvatarUrl(comment.user)" />
                    <span class="comment-author">{{ comment.user.username }}</span>
                    <span class="comment-text">{{ comment.text }}</span>
                    <span class="comment-time">{{ formatDate(comment.created_at) }}</span>
                    <el-button 
                      v-if="isLoggedIn" 
                      link 
                      type="primary" 
                      size="small"
                      @click="comment.showReplyInput = !comment.showReplyInput"
                    >
                      回复
                    </el-button>
                    <el-button 
                      v-if="checkUserPermission(comment)"
                      link 
                      type="danger" 
                      size="small"
                      @click="deleteComment(answer, comment)"
                    >
                      删除
                    </el-button>
                  </div>

                  <!-- 回复输入框 -->
                  <div v-if="comment.showReplyInput" class="reply-input">
                    <el-input
                      v-model="comment.replyText"
                      size="small"
                      placeholder="回复评论..."
                      @keyup.enter="submitReply(answer, comment)"
                    >
                      <template #append>
                        <el-button @click="submitReply(answer, comment)">回复</el-button>
                      </template>
                    </el-input>
                  </div>

                  <!-- 所有回复评论 -->
                  <div v-if="comment.replies && comment.replies.length > 0" class="replies-list">
                    <div v-for="reply in getAllReplies(comment)" 
                         :key="reply.id" 
                         class="reply-item">
                      <el-avatar :size="20" :src="getAvatarUrl(reply.user)" />
                      <span class="reply-author">{{ reply.user.username }}</span>
                      <span v-if="reply.reply_to" class="reply-at">@{{ reply.reply_to.username }}</span>
                      <span class="reply-text">{{ reply.text }}</span>
                      <span class="reply-time">{{ formatDate(reply.created_at) }}</span>
                      <el-button 
                        v-if="isLoggedIn" 
                        link 
                        type="primary" 
                        size="small"
                        @click="reply.showReplyInput = !reply.showReplyInput"
                      >
                        回复
                      </el-button>
                      <el-button 
                        v-if="reply.user.id === userStore.userId"
                        link 
                        type="danger" 
                        size="small"
                        @click="deleteComment(answer, reply)"
                      >
                        删除
                      </el-button>

                      <!-- 回复的回复输入框 -->
                      <div v-if="reply.showReplyInput" class="nested-reply-input">
                        <el-input
                          v-model="reply.replyText"
                          size="small"
                          placeholder="回复评论..."
                          @keyup.enter="submitNestedReply(answer, comment, reply)"
                        >
                          <template #append>
                            <el-button @click="submitNestedReply(answer, comment, reply)">回复</el-button>
                          </template>
                        </el-input>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
        <el-empty v-else description="暂无回答" />
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user'
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { API_BASE_URL } from '@/config'
import { 
  View, 
  Star, 
  Bell, 
  CaretTop,
  ChatLineRound
} from '@element-plus/icons-vue'

const route = useRoute()
const userStore = useUserStore()
const question = ref(null)
const answers = ref([])
const loading = ref(true)
const error = ref(false)
const sortType = ref('newest')
const newAnswer = ref('')
const submitting = ref(false)

// 计算属性
const isAuthor = computed(() => {
  return question.value?.created_by?.id === userStore.userId
})

const isLoggedIn = computed(() => userStore.isAuthenticated)

const sortedAnswers = computed(() => {
  if (!answers.value) return []
  return [...answers.value].sort((a, b) => {
    if (sortType.value === 'newest') {
      return new Date(b.created_at) - new Date(a.created_at)
    } else {
      return b.likes_count - a.likes_count
    }
  })
})

// 当问题数据加载完成后设置标题
watch(() => question.value, (newVal) => {
  if (newVal?.title) {
    document.title = `问答 - ${newVal.title}`
  }
}, { deep: true })

// 获取问题详情
const fetchQuestionDetail = async () => {
  loading.value = true
  error.value = false
  try {
    // 创建请求配置，包含 Authorization header
    const config = {
      headers: {}
    }
    // 如果用户已登录，添加 token
    if (localStorage.getItem('accessToken')) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`
    }

    const response = await axios.get(
      `${API_BASE_URL}/api/v1/qa/questions/${route.params.id}/`,
      config
    )
    
    // 处理问内容中的图片 URL
    const questionData = response.data
    questionData.content = questionData.content.replace(/src="\/media\//g, `src="${API_BASE_URL}/media/`)
    question.value = questionData
    console.log('问题详情:', questionData)
  } catch (error) {
    console.error('获取问题详情错误:', error)
    ElMessage.error('获取问题详情失败')
    error.value = true
  } finally {
    loading.value = false
  }
}

// 获取回答列表
const fetchAnswers = async () => {
  try {
    // 创建请求配置，包含 Authorization header
    const config = {
      params: { question: route.params.id },
      headers: {}
    }
    // 如果用户已登录，添加 token
    if (localStorage.getItem('accessToken')) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`
    }

    const response = await axios.get(
      `${API_BASE_URL}/api/v1/qa/answers/`,
      config
    )
    
    // 处理每个回答中的图片 URL
    const processedAnswers = response.data.results.map(answer => ({
      ...answer,
      content: answer.content.replace(/src="\/media\//g, `src="${API_BASE_URL}/media/`)
    }))
    answers.value = processedAnswers
    
    // 为每个回答获取评论
    for (const answer of answers.value) {
      await fetchComments(answer)
    }
  } catch (error) {
    console.error('获取回答列表错误:', error)
    ElMessage.error('获取回答列表失败')
  }
}

// 提交回答
const submitAnswer = async () => {
  if (!newAnswer.value.trim()) {
    ElMessage.warning('请输入回答内容')
    return
  }
  submitting.value = true
  try {
    await axios.post(`${API_BASE_URL}/api/v1/qa/answers/`, {
      question: route.params.id,
      content: newAnswer.value
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    })
    newAnswer.value = ''
    await fetchAnswers()
    ElMessage.success('回答已提交')
  } catch (error) {
    // 显示后端返回的具体错误信息
    const errorMessage = error.response?.data?.detail || '提交失败'
    ElMessage.error(errorMessage)
  } finally {
    submitting.value = false
  }
}

// 处理回答点赞
const handleAnswerLike = async (answer) => {
  if (!isLoggedIn.value) {
    ElMessage.warning('请先登录')
    return
  }
  
  const loading = ref(true)
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/v1/qa/answers/${answer.id}/toggle_like/`, 
      {}, 
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      }
    )
    
    // 根据返回的状态更新数据
    if (response.data) {
      // 更新当前回答状态
      const index = answers.value.findIndex(a => a.id === answer.id)
      if (index !== -1) {
        answers.value[index] = {
          ...answers.value[index],
          is_liked: response.data.status === 'liked',
          likes_count: response.data.likes_count
        }
      }
      
      // 根据状态显示不同的提示
      const message = response.data.status === 'liked' ? '点赞成功' : '已取消点赞'
      ElMessage.success(message)
    }
  } catch (error) {
    // 处理特定的错误信息
    if (error.response?.data?.detail === '不能自己的回答点赞') {
      ElMessage.warning('不能给自己的回答点赞')
    } else {
      const errorMessage = error.response?.data?.detail || '操作失败'
      ElMessage.error(errorMessage)
    }
  } finally {
    loading.value = false
  }
}

// 采纳答案
const acceptAnswer = async (answer) => {
  try {
    await axios.post(`${API_BASE_URL}/api/v1/qa/answers/${answer.id}/accept/`)
    await fetchQuestionDetail()
    await fetchAnswers()
    ElMessage.success('已采纳该答案')
  } catch (error) {
    ElMessage.error('操作失败')
  }
}

// 切换评论显示
const toggleComments = async (answer) => {
  answer.showComments = !answer.showComments
  if (answer.showComments && !answer.comments) {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/qa/comments/`, {
        params: { answer: answer.id }
      })
      answer.comments = response.data.results
    } catch (error) {
      ElMessage.error('获取评论失败')
    }
  }
}

// 提交评论
const submitComment = async (answer) => {
  if (!answer.newComment?.trim()) {
    ElMessage.warning('请输入评论内容')
    return
  }
  try {
    await axios.post(`${API_BASE_URL}/api/v1/qa/comments/`, {
      answer: answer.id,
      text: answer.newComment
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    })
    answer.newComment = ''
    // 重新获取该回答的评论
    await fetchComments(answer)
    ElMessage.success('评论已提交')
  } catch (error) {
    ElMessage.error('评论失败')
  }
}

// 格式化日期
const formatDate = (dateString) => {
  if (!dateString) return ''
  return new Date(dateString).toLocaleDateString('zh-CN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

// 处理问题点赞
const handleQuestionLike = async () => {
  if (!isLoggedIn.value) {
    ElMessage.warning('请先登录')
    return
  }
  
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/v1/qa/questions/${route.params.id}/toggle_like/`,
      {},
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      }
    )
    
    // 根据返回的状态更新数据
    if (response.data) {
      question.value = {
        ...question.value,
        is_liked: response.data.status === 'liked',
        likes_count: response.data.likes_count
      }
      
      // 根据状态显示不同的提示
      const message = response.data.status === 'liked' ? '点赞成功' : '已取消点赞'
      ElMessage.success(message)
    }
  } catch (error) {
    // 处理特定的错误信息
    if (error.response?.data?.detail === '不能给自己的问题点赞') {
      ElMessage.warning('不能给自己的问题点赞')
    } else {
      const errorMessage = error.response?.data?.detail || '操作失败'
      ElMessage.error(errorMessage)
    }
  }
}

// 处理收藏
const handleCollect = async () => {
  if (!isLoggedIn.value) {
    ElMessage.warning('请先登录')
    return
  }
  
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/v1/qa/questions/${route.params.id}/toggle_collection/`,
      {},
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      }
    )
    
    // 根据返回的状态更新数据
    if (response.data) {
      question.value = {
        ...question.value,
        is_collected: response.data.status === 'collected',
        collection_count: response.data.collection_count
      }
      
      // 根据状态显示不同的提示
      const message = response.data.status === 'collected' ? '收藏成功' : '已取消收藏'
      ElMessage.success(message)
    }
  } catch (error) {
    // 处理定的错误信息
    if (error.response?.data?.detail === '不能收藏自己的问题') {
      ElMessage.warning('不能收藏自己的问题')
    } else {
      const errorMessage = error.response?.data?.detail || '操作失败'
      ElMessage.error(errorMessage)
    }
  }
}

// 提交回复的方法
const submitReply = async (answer, parentComment) => {
  if (!isLoggedIn.value) {
    ElMessage.warning('请先登录后再回复')
    return
  }

  if (!parentComment.replyText?.trim()) {
    ElMessage.warning('请输入回复内容')
    return
  }
  
  try {
    await axios.post(`${API_BASE_URL}/api/v1/qa/comments/`, {
      answer: answer.id,
      text: parentComment.replyText,
      parent: parentComment.id
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    })
    
    parentComment.replyText = ''
    parentComment.showReplyInput = false
    await fetchComments(answer)
    ElMessage.success('回复已提交')
  } catch (error) {
    if (error.response?.status === 401) {
      ElMessage.warning('请先登录后再回复')
    } else {
      ElMessage.error('回复失败')
    }
  }
}

// 修改 fetchComments 方法，确保所有评论(包括子评论)都有必要的响应式属性
const fetchComments = async (answer) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/qa/comments/`, {
      params: { answer: answer.id }
    })
    console.log('获取到的评论数据:', response.data.results)
    answer.comments = response.data.results
    answer.showAllComments = false
    answer.newComment = ''
  } catch (error) {
    ElMessage.error('获取评论失败')
  }
}

// 添加显示评论的计算方法
const displayComments = (answer) => {
  if (!answer.comments) return []
  return answer.showAllComments 
    ? answer.comments 
    : answer.comments.slice(0, 5)
}

// 获取所有回复(包括嵌套回复)
const getAllReplies = (comment) => {
  let allReplies = []
  if (comment.replies) {
    comment.replies.forEach(reply => {
      allReplies.push(reply)
      if (reply.replies) {
        allReplies = allReplies.concat(getAllReplies(reply))
      }
    })
  }
  return allReplies
}

// 修改提交嵌套回复的方法
const submitNestedReply = async (answer, parentComment, replyTo) => {
  if (!isLoggedIn.value) {
    ElMessage.warning('请先登录后再回复')
    return
  }

  if (!replyTo.replyText?.trim()) {
    ElMessage.warning('请输入回复内容')
    return
  }
  
  try {
    await axios.post(`${API_BASE_URL}/api/v1/qa/comments/`, {
      answer: answer.id,
      text: replyTo.replyText,
      parent: replyTo.id,
      reply_to: replyTo.user.id
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    })
    
    replyTo.replyText = ''
    replyTo.showReplyInput = false
    await fetchComments(answer)
    ElMessage.success('回复已提交')
  } catch (error) {
    if (error.response?.status === 401) {
      ElMessage.warning('请先登录后再回复')
    } else {
      ElMessage.error('回复失败')
    }
  }
}

// 删除评论
const deleteComment = async (answer, comment) => {
  try {
    // 添加确认对话框
    await ElMessageBox.confirm('确定要删除这条评论吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
    
    // 发送删除请求
    await axios.delete(
      `${API_BASE_URL}/api/v1/qa/comments/${comment.id}/`, 
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      }
    )
    
    // 重新获取该回答的评论
    await fetchComments(answer)
    ElMessage.success('评论已删除')
  } catch (error) {
    if (error !== 'cancel') {
      const errorMessage = error.response?.data?.detail || '删除失败'
      ElMessage.error(errorMessage)
    }
  }
}

// 在 script setup 中添加检查权限的方法
const checkUserPermission = (comment) => {
  console.log('当前评论用户ID:', comment.user.id)
  console.log('当前登录用户ID:', userStore.userId)
  console.log('userInfo:', userStore.userInfo)
  
  // 确保两个值都转换为数字进行比较
  return Number(comment.user.id) === Number(userStore.userId)
}

// 添加获取头像URL的方法
const getAvatarUrl = (user) => {
  if (!user.avatar || user.avatar === '/media/images/default_logo.png') {
    return `https://ui-avatars.com/api/?name=${encodeURIComponent(user.username)}`
  }
  return API_BASE_URL + user.avatar
}

onMounted(() => {
  fetchQuestionDetail()
  fetchAnswers()
})
</script>

<style scoped>
.qa-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* 区块通用样式 */
.section {
  margin-bottom: 40px; /* 增加区块之间的间距 */
  border-radius: 8px; /* 圆角更大一些 */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.section-header h2 {
  margin: 0;
  font-size: 18px;
  color: #303133;
}

/* 问题区域样式 */
.question-section {
  .question-header {
    margin-bottom: 20px;
    
    .title {
      font-size: 24px;
      margin-bottom: 16px;
      color: #303133;
    }
  }

  .question-content {
    padding: 20px 0;
  }
}

/* 回答编辑区域样式 */
.answer-editor {
  .submit-area {
    margin-top: 20px;
    text-align: right;
  }
}

/* 回答列表区域样式优化 */
.answers-section {
  .answers-list {
    padding: 10px 0;
  }

  .answer-item {
    padding: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #EBEEF5;
    
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .answer-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 12px;
    
    .username {
      font-weight: 500;
      color: #303133;
    }
    
    .time {
      color: #909399;
      font-size: 14px;
    }
  }

  .vote-area {
    display: flex;
    align-items: center;
    gap: 8px;
    
    span {
      color: #606266;
      font-size: 16px;
      min-width: 20px;
      text-align: center;
    }
  }

  .answer-content {
    .markdown-body {
      padding: 16px 0;
    }
  }

  .answer-actions {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #f0f0f0;
  }

  /* 评论区样式 */
  .comments-area {
    margin-top: 16px;
    padding: 16px;
    background-color: #f8f9fa;
    border-radius: 4px;
  }

  .comments-list {
    margin-top: 20px;
  }

  .comment-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 0;
  }

  .replies-list {
    margin-left: 40px;
  }

  .reply-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 0;
    font-size: 14px;
  }

  .reply-input,
  .nested-reply-input {
    margin: 8px 0 8px 40px;
    max-width: 90%;
  }

  .comment-author,
  .reply-author {
    font-weight: 500;
    color: #303133;
  }

  .comment-text,
  .reply-text {
    color: #606266;
  }

  .comment-time,
  .reply-time {
    color: #909399;
    font-size: 12px;
    margin-left: auto;
  }

  .show-more-comments {
    text-align: center;
    margin: 8px 0;
  }

  .add-comment {
    margin-top: 12px;
  }
}

/* 其他样式保持不变 */
.markdown-body {
  font-size: 16px;
  line-height: 1.6;
  
  :deep(img) {
    max-width: 100%;
    height: auto;
  }
}

.meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stats {
  display: flex;
  align-items: center;
  gap: 15px;

  .el-button-group {
    .el-button {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.tags {
  margin-top: 20px;
  display: flex;
  gap: 8px;
}

.replies-list {
  margin-left: 40px;
  margin-top: 8px;
}

.reply-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
  font-size: 14px;
}

.reply-input {
  margin: 8px 0 8px 40px;
  max-width: 90%;
}

.reply-author {
  font-weight: 500;
  color: #303133;
}

.reply-text {
  color: #606266;
}

.reply-time {
  color: #909399;
  font-size: 12px;
  margin-left: auto;
}

.add-comment {
  margin-bottom: 20px; /* 添加底部间距 */
  padding: 10px 0;
  border-bottom: 1px solid #eee; /* 可选：添加一个分隔线 */
}

.nested-reply-input {
  margin: 8px 0 8px 40px;
  max-width: 90%;
}

.reply-at {
  color: #409EFF;
  margin: 0 4px;
}
</style>
