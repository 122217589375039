<template>
  <div class="blog-content-container">
    <!-- 添加面包屑导航 -->
    <div class="breadcrumb-container">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ blog?.author }}</el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ blog?.category?.name || '未分类' }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ blog?.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-row :gutter="20" class="content-row">
      <!-- 左侧内容区 -->
      <el-col :span="18">
        <!-- 博客内容 -->
        <el-card v-if="blog" class="blog-content" shadow="hover">
          <h1>{{ blog.title }}</h1>
          <!-- 修改操作按钮组的样式 -->
          <div class="blog-actions">
            <div class="action-item" @click="toggleLikeBlog">
              <div class="action-wrapper" :class="{ active: isBlogLiked }">
                <el-icon class="action-icon">
                  <Star v-if="!isBlogLiked" />
                  <StarFilled v-else />
                </el-icon>
                <span class="action-count">{{ blog.likes_count }}</span>
                <span class="action-text">{{ isBlogLiked ? '已点赞' : '点赞' }}</span>
              </div>
            </div>
            <div class="action-item" @click="toggleCollectBlog">
              <div class="action-wrapper" :class="{ active: isBlogCollected }">
                <el-icon class="action-icon">
                  <Collection v-if="!isBlogCollected" />
                  <CollectionTag v-else />
                </el-icon>
                <span class="action-count">{{ blog.collection_count }}</span>
                <span class="action-text">{{ isBlogCollected ? '已收藏' : '收藏' }}</span>
              </div>
            </div>
          </div>
          <p class="post-meta">
            <el-tag v-if="blog.category" size="small" effect="plain">
              {{ blog.category.name }}
            </el-tag>
            <el-tag v-else size="small" effect="plain">未分类</el-tag>
            <span class="post-date">{{ blog.date }}</span>
            <!-- 添加作者信息 -->
            <span class="post-author">作者: {{ blog.author }}</span>
          </p>
          <div class="post-tags">
            <el-tag v-if="blog.is_top" type="danger" effect="light" size="small">置顶</el-tag>
            <el-tag v-if="blog.is_featured" type="success" effect="light" size="small">推荐</el-tag>
            <el-tag v-if="blog.is_highlighted" type="warning" effect="light" size="small">精华</el-tag>
            <el-tag v-if="blog.level === 'beginner'" type="info" effect="light" size="small">入门</el-tag>
          </div>
          <div class="blog-content" v-html="blog.content" ref="blogContentRef"></div>
        </el-card>
        
        <!-- 提交评论区 -->
        <el-card class="comment-form" shadow="hover">
          <h3>发表评论</h3>
          <!-- 已登录用户显示评论框 -->
          <div v-if="isLoggedIn" class="comment-input-wrapper">
            <el-input
              v-model="newComment"
              type="textarea"
              :rows="3"
              placeholder="请输入您的评论"
            ></el-input>
            <div class="comment-button-wrapper">
              <el-button type="primary" @click="submitComment" style="margin-top: 10px;">
                提交评论
              </el-button>
            </div>
          </div>
          
          <!-- 未登录用户显示提示信息 -->
          <div v-else class="login-tip">
            <el-alert
              title="请登录后发论"
              type="info"
              :closable="false"
              center
            >
              <template #default>
                <el-button type="primary" link @click="goToLogin">
                  去登录
                </el-button>
              </template>
            </el-alert>
          </div>
        </el-card>
        
        <!-- 评论展示区 -->
        <el-card class="comments-section" shadow="hover">
          <h3>评论列表</h3>
          <div class="comment-list">
            <comment-tree
              v-for="comment in topLevelComments"
              :key="comment.id"
              :comment="comment"
              :can-delete="canDeleteComment"
              @reply="handleReply"
              @delete="deleteComment"
              @like="(comment, callback) => likeComment(comment).then(callback)"
            />
          </div>
        </el-card>
      </el-col>
      
      <!-- 右侧作者信息 -->
      <el-col :span="6">
        <!-- 作者信息卡片 -->
        <el-card v-if="blog" class="author-info" shadow="hover">
          <h2>作者信息</h2>
          <p>{{ blog.author }}</p>
        </el-card>

        <!-- 新增作者文章列表卡片 -->
        <el-card v-if="blog" class="author-articles" shadow="hover">
          <template #header>
            <div class="card-header">
              <span>作者的其他文章</span>
            </div>
          </template>
          
          <el-skeleton :rows="5" animated v-if="loadingArticles"/>
          <template v-else>
            <ul v-if="authorArticles.length" class="article-list">
              <li v-for="article in authorArticles" :key="article.id">
                <a 
                  href="javascript:;" 
                  @click="openInNewTab(article.id)"
                  class="article-link"
                >
                  <el-tooltip :content="article.title" placement="left" :show-after="1000">
                    <div class="article-title">{{ article.title }}</div>
                  </el-tooltip>
                  <span class="article-date">{{ formatDate(article.created_at) }}</span>
                </a>
              </li>
            </ul>
            <el-empty v-else description="暂无其他文章" :image-size="60" />
          </template>
        </el-card>
      </el-col>
    </el-row>
    <el-image-viewer
      v-if="previewVisible"
      :url-list="previewSrcList"
      :initial-index="previewSrcList.indexOf(previewSrc)"
      @close="previewVisible = false"
    />
  </div>
</template>

<script>
import { ref, onMounted, nextTick, watch, computed } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import CommentTree from '@/components/CommentTree.vue'
import { API_BASE_URL } from '@/config'
import { Star, StarFilled, Collection, CollectionTag } from '@element-plus/icons-vue'
import axiosInstance from '@/utils/axios'
import { useUserStore } from '@/stores/user'

export default {
  name: 'BlogContentView',
  components: {
    CommentTree,
    Star,
    StarFilled,
    Collection,
    CollectionTag
  },
  setup() {
    const blog = ref(null)
    const route = useRoute()
    const router = useRouter()
    const newComment = ref('')
    const comments = ref([])
    const replyingTo = ref(null)
    const replyContent = ref('')
    const blogContentRef = ref(null)
    const previewVisible = ref(false)
    const previewSrc = ref('')
    const previewSrcList = ref([])
    const authorArticles = ref([])
    const loadingArticles = ref(false)
    const userStore = useUserStore()

    const fetchBlogContent = async () => {
      try {
        const response = await axiosInstance.get(`/api/v1/blog/blogs/${route.params.id}/`)
        
        // 处理博客内容中的图片 URL
        const blogData = response.data
        blogData.content = blogData.content.replace(/src="\/media\//g, `src="${API_BASE_URL}/media/`)
        
        blog.value = {
          ...blogData,
          date: new Date(blogData.created_at).toLocaleDateString()
        }

        nextTick(() => {
          processImages()
        })
      } catch (error) {
        console.error('获取博客内容失败:', error)
        ElMessage.error('获取博客内容失败，请稍后重试')
      }
    }

    const fetchComments = async () => {
      try {
        const response = await axiosInstance.get(`/api/v1/blog/blogs/${route.params.id}/comments/`)
        comments.value = response.data
      } catch (error) {
        console.error('获取评论失败:', error)
        ElMessage.error('获取评论失败，请稍后重试')
      }
    }

    const formatDate = (dateString) => {
      const date = new Date(dateString)
      return date.toLocaleString()
    }

    const getAvatarUrl = (username) => {
      // 这里可以根据用户名生成头像URL,或者使用默认头像
      return `https://api.dicebear.com/6.x/initials/svg?seed=${username}`
    }

    
    const likeComment = async (comment) => {
      if (!isLoggedIn.value) {
        ElMessage.warning('请先登录后再点赞')
        return
      }

      try {
        const response = await axiosInstance.post(
          `/api/v1/blog/blogs/${route.params.id}/comments/${comment.id}/toggle_like/`
        )
        
        if (response.data) {
          comment.is_liked = !comment.is_liked
          comment.likes_count = response.data.likes_count
        }
        return response.data
      } catch (error) {
        console.error('评论点赞失败:', error)
        ElMessage.error('操作失败，请稍后重试')
        throw error
      }
    }

    const replyToComment = (comment) => {
      // 实现回复功能
      console.log('回复评论:', comment.id)
    }

    const submitComment = async () => {
      if (!newComment.value.trim()) {
        ElMessage.warning('评论内容不能为空')
        return
      }

      try {
        await axiosInstance.post(`/api/v1/blog/blogs/${route.params.id}/add_comment/`, {
          text: newComment.value,
          parent: null
        })

        ElMessage.success('评论发表成功')
        newComment.value = ''
        await fetchComments()
      } catch (error) {
        console.error('提交评论失败:', error)
        ElMessage.error('提交评论失败，请稍后重试')
      }
    }

    const showReplyForm = (comment) => {
      replyingTo.value = comment.id
    }

    const cancelReply = () => {
      replyingTo.value = null
    }

    const canDeleteComment = (comment) => {
      const currentUser = localStorage.getItem('userName')
      return comment.user === currentUser
    }

    const deleteComment = async (comment) => {
      try {
        await ElMessageBox.confirm('确定要删除这条评论吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })

        await axiosInstance.delete(`/api/v1/blog/comments/${comment.id}/`)
        ElMessage.success('评论删除成功')
        await fetchComments()
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除评论失败:', error)
          ElMessage.error('删除评论失败，请稍后重试')
        }
      }
    }

    onMounted(() => {
      fetchBlogContent()
      fetchComments()
    })

    const processImages = () => {
      if (blogContentRef.value) {
        const images = blogContentRef.value.querySelectorAll('img')
        previewSrcList.value = Array.from(images).map(img => img.src)
        images.forEach(img => {
          img.onclick = () => showImagePreview(img.src)
          img.style.cursor = 'pointer'
        })
      }
    }

    const showImagePreview = (src) => {
      previewSrc.value = src
      previewVisible.value = true
    }

    const handleReply = async (comment, content) => {
      try {
        await axiosInstance.post(`/api/v1/blog/blogs/${route.params.id}/add_comment/`, {
          text: content,
          parent: comment.id
        })
        
        ElMessage.success('回复成功')
        await fetchComments()
      } catch (error) {
        console.error('提交回复失败:', error)
        ElMessage.error('提交回复失败，请稍后重试')
      }
    }

    // 获取作者文章列表
    const fetchAuthorArticles = async () => {
      if (!blog.value?.author) return
      
      loadingArticles.value = true
      try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/blog/blogs/`, {
          params: {
            author: blog.value.author,
            limit: 5
          }
        })
        // 过滤掉当前文章
        authorArticles.value = response.data.results
          .filter(article => article.id !== blog.value.id)
          .slice(0, 5)
      } catch (error) {
        console.error('获取作者文章失败:', error)
        ElMessage.error('获取作者文章列表失败')
      } finally {
        loadingArticles.value = false
      }
    }

    watch(() => blog.value?.author, (newAuthor) => {
      if (newAuthor) {
        fetchAuthorArticles()
      }
    }, { immediate: true })

    // 监听路由参数变化
    watch(
      () => route.params.id,
      async (newId) => {
        if (newId) {
          await fetchBlogContent()
          await fetchComments()
        }
      }
    )

    const openInNewTab = (articleId) => {
      const route = window.location.origin + `/blog/${articleId}`
      window.open(route, '_blank')
    }

    // 添加点赞功能
    const toggleLikeBlog = async () => {
      if (!isLoggedIn.value) {
        ElMessage.warning('请先登录后再点赞')
        return
      }

      try {
        const response = await axiosInstance.post(
          `/api/v1/blog/blogs/${route.params.id}/toggle_like/`
        )
        
        if (response.data) {
          blog.value = {
            ...blog.value,
            is_liked: !blog.value.is_liked,
            likes_count: response.data.likes_count
          }
          ElMessage.success(blog.value.is_liked ? '点赞成功' : '已取消点赞')
        }
      } catch (error) {
        console.error('点赞操作失败:', error)
        ElMessage.error('操作失败，请稍后重试')
      }
    }

    // 添加收藏功能
    const toggleCollectBlog = async () => {
      if (!isLoggedIn.value) {
        ElMessage.warning('请先登录后再收藏')
        return
      }

      try {
        const response = await axiosInstance.post(
          `/api/v1/blog/blogs/${route.params.id}/toggle_collection/`
        )
        
        if (response.data) {
          blog.value = {
            ...blog.value,
            is_collected: !blog.value.is_collected,
            collection_count: response.data.collection_count
          }
          ElMessage.success(blog.value.is_collected ? '收藏成功' : '已取消收藏')
        }
      } catch (error) {
        console.error('收藏操作失败:', error)
        ElMessage.error('操作失败，请稍后重试')
      }
    }

    // 过滤出顶级评论
    const topLevelComments = computed(() => {
      return comments.value.filter(comment => comment.parent === null)
    })

    // 当博客数据加载完成后设置标题
    watch(() => blog.value, (newVal) => {
      if (newVal?.title) {
        document.title = `博客 - ${newVal.title}`
      }
    }, { deep: true })

    // 添加登录状态检查
    const isLoggedIn = computed(() => {
      return !!localStorage.getItem('accessToken')
    })

    const goToLogin = () => {
      router.push({ path: '/login', query: { redirect: route.fullPath } })
    }

    // 监听登录状态变化
    watch(() => userStore.isAuthenticated, (newVal, oldVal) => {
      if (!newVal && oldVal) {  // 从登录状态变为未登录状态
        // 重置博客的点赞和收藏状态
        if (blog.value) {
          blog.value = {
            ...blog.value,
            is_liked: false,
            is_collected: false,
            // 保持计数不变，因为这是总数
            likes_count: blog.value.likes_count,
            collection_count: blog.value.collection_count
          }
        }
        
        // 重置所有评论的点赞状态
        comments.value = comments.value.map(comment => ({
          ...comment,
          is_liked: false,
          replies: comment.replies ? comment.replies.map(reply => ({
            ...reply,
            is_liked: false
          })) : []
        }))
      }
    })

    // 修改计算属性
    const isBlogLiked = computed(() => {
      return userStore.isAuthenticated ? blog.value?.is_liked : false
    })

    const isBlogCollected = computed(() => {
      return userStore.isAuthenticated ? blog.value?.is_collected : false
    })

    return {
      blog,
      newComment,
      comments,
      replyContent,
      blogContentRef,
      previewVisible,
      previewSrc,
      previewSrcList,
      showImagePreview,
      handleReply,
      authorArticles,
      loadingArticles,
      fetchAuthorArticles,
      openInNewTab,
      toggleLikeBlog,
      toggleCollectBlog,
      formatDate,
      getAvatarUrl,
      likeComment,
      replyToComment,
      submitComment,
      showReplyForm,
      cancelReply,
      canDeleteComment,
      deleteComment,
      topLevelComments,
      isLoggedIn,
      goToLogin,
      isBlogLiked,
      isBlogCollected,
    }
  }
}
</script>

<style scoped>
.blog-content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.content-row {
  display: flex;
  align-items: flex-start;
}

.blog-content,
.comment-form,
.comments-section,
.author-info {
  margin-bottom: 20px;
}

.post-meta {
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.post-meta .el-tag {
  margin: 0;
}

.post-date {
  color: #666;
  position: relative;
  padding-left: 20px;
}

.post-date::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #999;
}

.post-author {
  color: #666;
  position: relative;
  padding-left: 20px;
}

.post-author::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #999;
}

.post-tags {
  margin-bottom: 20px;
}

.post-tags .el-tag {
  margin-right: 5px;
}

.author-info {
  text-align: center;
  padding: 20px;
}

.author-avatar {
  margin-bottom: 15px;
}

.author-info h2 {
  margin-bottom: 10px;
}

.author-info p {
  margin: 5px 0;
}

.author-info i {
  margin-right: 5px;
}

.comment {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.comment-info {
  display: flex;
  align-items: center;
}

.comment-author {
  font-weight: bold;
  margin-left: 10px;
}

.comment-date {
  color: #999;
  margin-left: 10px;
  font-size: 0.9em;
}

.comment-actions {
  display: flex;
  gap: 10px;
}

.comment-content {
  margin-left: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.5;
}

.comment-replies {
  margin-left: 50px;
  margin-top: 15px;
  border-left: 2px solid #eee;
  padding-left: 15px;
}

.reply {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #eee;
}

.reply:last-child {
  border-bottom: none;
}

.reply .comment-content {
  margin-left: 40px;
}

.el-button--small {
  padding: 6px 10px;
  font-size: 0.9em;
}

.comments-section {
  margin-top: 20px;
}

.reply-form {
  margin-top: 10px;
  margin-left: 50px;
  margin-bottom: 10px;
}

.comment-actions .el-button {
  margin-left: 5px;
}

.blog-content {
  max-width: 100%;
  overflow-x: auto;
}

.blog-content ::v-deep(img) {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.post-author {
  margin-left: 10px;
  color: #666;
}

.author-info {
  padding: 20px;
}

.author-info h2 {
  margin-bottom: 10px;
}

.author-articles {
  margin-top: 20px;
}

.card-header {
  font-weight: bold;
  font-size: 16px;
}

.article-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.article-list li {
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.article-list li:last-child {
  border-bottom: none;
}

.article-link {
  display: block;
  text-decoration: none;
  color: #333;
}

.article-link:hover .article-title {
  color: #409EFF;
}

.article-date {
  display: block;
  font-size: 12px;
  color: #999;
  margin-top: 4px;
}

.blog-actions {
  display: flex;
  gap: 20px;
  margin: 16px 0;
  padding: 8px 0;
}

.action-item {
  cursor: pointer;
}

.action-wrapper {
  display: inline-flex;
  align-items: center;
  padding: 6px 16px;
  border-radius: 20px;
  background: #f5f7fa;
  transition: all 0.3s ease;
}

.action-wrapper:hover {
  background: #e6f0ff;
}

.action-wrapper.active {
  background: #409EFF;
  color: white;
}

.action-icon {
  font-size: 18px;
  margin-right: 6px;
}

.action-count {
  font-weight: 500;
  margin: 0 6px;
}

.action-text {
  font-size: 14px;
}

/* 添加点击涟漪效果 */
.action-wrapper {
  position: relative;
  overflow: hidden;
}

.action-wrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.action-wrapper:active::after {
  animation: ripple 0.4s ease-out;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(40, 40);
    opacity: 0;
  }
}

.login-tip {
  padding: 40px 0;
  text-align: center;
  background: #f5f7fa;
  border-radius: 4px;
  margin: 20px 0;
}

.login-tip :deep(.el-alert) {
  width: fit-content;
  margin: 0 auto;
  background: transparent;
  padding: 0;
  border: none;
}

.login-tip :deep(.el-alert__content) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.login-tip :deep(.el-alert__title) {
  font-size: 14px;
  margin: 0;
  color: #606266;
}

/* 调整按钮样式 */
.login-tip :deep(.el-button) {
  margin-left: 15px;
}

/* 添加面包屑样式 */
.breadcrumb-container {
  margin-bottom: 20px;
  padding: 8px 0;
}
</style>


