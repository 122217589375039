<template>
  <div class="tickets-container">
    <div class="tickets-header">
      <h2>我的工单</h2>
      <el-button type="primary" @click="showCreateTicketDialog = true">
        <el-icon><Plus /></el-icon>新建工单
      </el-button>
    </div>

    <el-table
      v-loading="loading"
      :data="tickets"
      style="width: 100%"
    >
      <el-table-column prop="id" label="工单号" width="100" />
      <el-table-column prop="title" label="标题" />
      <el-table-column prop="ticket_type" label="类型" width="120">
        <template #default="{ row }">
          <el-tag :type="getTicketTypeTag(row.ticket_type)">
            {{ getTicketTypeText(row.ticket_type) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="priority" label="优先级" width="120">
        <template #default="{ row }">
          <el-tag :type="getPriorityTag(row.priority)">
            {{ getPriorityText(row.priority) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="120">
        <template #default="{ row }">
          <el-tag :type="getStatusTag(row.status)">
            {{ getStatusText(row.status) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" width="180">
        <template #default="{ row }">
          {{ formatDate(row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template #default="{ row }">
          <el-button link type="primary" @click="viewTicketDetail(row)">
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 创建工单对话框 -->
    <el-dialog
      v-model="showCreateTicketDialog"
      title="新建工单"
      width="500px"
      :close-on-click-modal="false"
      class="custom-dialog create-ticket-dialog"
      :append-to-body="true"
      destroy-on-close
    >
      <el-form
        ref="ticketFormRef"
        :model="ticketForm"
        :rules="ticketRules"
        label-width="80px"
        class="create-ticket-form"
      >
        <el-form-item label="标题" prop="title">
          <el-input 
            v-model="ticketForm.title" 
            placeholder="请输入工单标题"
            :maxlength="100"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="类型" prop="ticket_type">
          <el-select 
            v-model="ticketForm.ticket_type" 
            placeholder="请选择工单类型"
            class="full-width-select"
          >
            <el-option 
              v-for="item in ticketTypes" 
              :key="item.value" 
              :label="item.label" 
              :value="item.value"
            >
              <template #default>
                <el-icon>
                  <component :is="item.icon" />
                </el-icon>
                <span>{{ item.label }}</span>
              </template>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优先级" prop="priority">
          <el-select 
            v-model="ticketForm.priority" 
            placeholder="请选择优先级"
            class="full-width-select"
          >
            <el-option 
              v-for="item in priorityOptions" 
              :key="item.value" 
              :label="item.label" 
              :value="item.value"
            >
              <template #default>
                <el-tag :type="item.tagType" :effect="item.effect">
                  {{ item.label }}
                </el-tag>
              </template>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input
            v-model="ticketForm.content"
            type="textarea"
            :rows="6"
            placeholder="请详细描述您的问题或建议"
            :maxlength="1000"
            show-word-limit
            resize="none"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showCreateTicketDialog = false">取消</el-button>
          <el-button 
            type="primary" 
            @click="submitTicket" 
            :loading="submitting"
            :disabled="submitting"
          >
            {{ submitting ? '提交中...' : '提交' }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 工单详情对话框 -->
    <el-dialog
      v-model="showDetailDialog"
      title="工单详情"
      width="700px"
      :close-on-click-modal="false"
      class="custom-dialog ticket-detail-dialog"
      :append-to-body="true"
      destroy-on-close
      @close="handleCloseDialog"
    >
      <div v-if="currentTicket" class="ticket-detail">
        <div class="ticket-header">
          <h3>{{ currentTicket.title }}</h3>
        </div>

        <div class="status-info">
          <el-tag :type="getStatusTag(currentTicket.status)">
            {{ getStatusText(currentTicket.status) }}：{{ getStatusDescription }}
          </el-tag>
        </div>

        <div v-if="isAdmin && currentTicket.status !== 'completed'" class="admin-actions">
          <el-button 
            type="primary" 
            @click="confirmResolution"
            :loading="confirmingResolution"
          >
            确认解决工单
          </el-button>
        </div>

        <div class="ticket-info">
          <div class="info-item">
            <span class="label">工单号：</span>
            <span>{{ currentTicket.id }}</span>
          </div>
          <div class="info-item">
            <span class="label">类型：</span>
            <span>{{ getTicketTypeText(currentTicket.ticket_type) }}</span>
          </div>
          <div class="info-item">
            <span class="label">优先级：</span>
            <span>{{ getPriorityText(currentTicket.priority) }}</span>
          </div>
          <div class="info-item">
            <span class="label">创建时间：</span>
            <span>{{ formatDate(currentTicket.created_at) }}</span>
          </div>
        </div>

        <div class="ticket-content">
          <div class="content-label">问题描述：</div>
          <div class="content-text">{{ currentTicket.content }}</div>
        </div>

        <div class="ticket-replies">
          <div class="replies-title">对话记录</div>
          <div v-if="currentTicket.replies && currentTicket.replies.length > 0" class="reply-list">
            <div v-for="reply in currentTicket.replies" :key="reply.id" 
                 :class="['reply-item', { 'staff-reply': reply.user.is_staff }]">
              <div class="reply-header">
                <span class="reply-user">
                  {{ reply.user.username }}
                  <el-tag v-if="reply.user.is_staff" size="small" type="success">客服</el-tag>
                </span>
                <span class="reply-time">{{ formatDate(reply.created_at) }}</span>
              </div>
              <div class="reply-content">{{ reply.content }}</div>
            </div>
          </div>
          <div v-else class="no-replies">暂无对话记录</div>
        </div>

        <div class="reply-section" v-if="canReply">
          <el-input
            v-model="replyContent"
            type="textarea"
            :rows="3"
            placeholder="请输入回复内容"
          />
          <el-button 
            type="primary" 
            @click="submitReply"
            :loading="submittingReply"
            style="margin-top: 10px;"
          >
            提交回复
          </el-button>
        </div>

        <div v-if="!canReply" class="ticket-end-status">
          <el-alert
            :title="ticketEndStatusTip"
            type="info"
            :closable="false"
            show-icon
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { Plus, Warning, QuestionFilled, More } from '@element-plus/icons-vue'
import axios from 'axios'
import { API_BASE_URL } from '@/config'
import { useUserStore } from '@/stores/user'

const loading = ref(false)
const tickets = ref([])
const showCreateTicketDialog = ref(false)
const showDetailDialog = ref(false)
const submitting = ref(false)
const currentTicket = ref(null)

const ticketForm = ref({
  title: '',
  content: '',
  ticket_type: '',
  priority: 'medium'
})

const ticketFormRef = ref(null)

const ticketRules = {
  title: [
    { required: true, message: '请输入工单标题', trigger: 'blur' },
    { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
  ],
  ticket_type: [
    { required: true, message: '请选择工单类型', trigger: 'change' }
  ],
  priority: [
    { required: true, message: '请选择优先级', trigger: 'change' }
  ],
  content: [
    { required: true, message: '请输入工单内容', trigger: 'blur' },
    { min: 10, max: 1000, message: '长度在 10 到 1000 个字符', trigger: 'blur' }
  ]
}

// 添加新的响应式变量
const replyContent = ref('')
const submittingReply = ref(false)

const userStore = useUserStore()
const confirmingResolution = ref(false)

// 判断是否为管理员
const isAdmin = computed(() => {
  return userStore.userInfo?.is_staff || false
})

// 用户确认问题已解决
const confirmResolution = async () => {
  if (confirmingResolution.value) return
  
  try {
    confirmingResolution.value = true
    await axios.post(
      `${API_BASE_URL}/api/v1/tools/tickets/${currentTicket.value.id}/change_status/`,
      { status: 'completed' },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      }
    )
    
    currentTicket.value.status = 'completed'
    ElMessage.success('已确认问题解决')
    fetchTickets() // 刷新工单列表
  } catch (error) {
    console.error('确认失败:', error)
    ElMessage.error('操作失败，请稍后重试')
  } finally {
    confirmingResolution.value = false
  }
}

// 更新工单状态提示文本
const getStatusDescription = computed(() => {
  const descriptions = {
    pending: '工单正在等待处理',
    processing: '客服正在处理您的问题',
    completed: '问题已解决，工单已完成',
    closed: '工单已关闭，无法继续操作'
  }
  return descriptions[currentTicket.value?.status] || ''
})

// 获取工单列表
const fetchTickets = async () => {
  loading.value = true
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/tools/tickets/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    tickets.value = response.data.results
  } catch (error) {
    console.error('获取工单列表失败:', error)
    ElMessage.error('获取工单列表失败')
  } finally {
    loading.value = false
  }
}

// 提交工单
const submitTicket = async () => {
  if (!ticketFormRef.value) return
  
  await ticketFormRef.value.validate(async (valid) => {
    if (!valid) return
    
    submitting.value = true
    try {
      await axios.post(`${API_BASE_URL}/api/v1/tools/tickets/`, ticketForm.value, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      
      ElMessage.success('工单提交成功')
      showCreateTicketDialog.value = false
      ticketForm.value = {
        title: '',
        content: '',
        ticket_type: '',
        priority: 'medium'
      }
      fetchTickets()
    } catch (error) {
      console.error('提交工单失败:', error)
      ElMessage.error('提交工单失败')
    } finally {
      submitting.value = false
    }
  })
}

// 查看工单详情
const viewTicketDetail = (ticket) => {
  currentTicket.value = ticket
  showDetailDialog.value = true
}

// 工单类型标签
const getTicketTypeTag = (type) => {
  const tags = {
    bug: 'danger',
    feature: 'success',
    question: 'warning',
    other: 'info'
  }
  return tags[type] || 'info'
}

const getTicketTypeText = (type) => {
  const texts = {
    bug: '问题反馈',
    feature: '功能建议',
    question: '使用咨询',
    other: '其他'
  }
  return texts[type] || '其他'
}

// 优先级标签
const getPriorityTag = (priority) => {
  const tags = {
    low: 'info',
    medium: 'warning',
    high: 'danger',
    urgent: 'danger'
  }
  return tags[priority] || 'info'
}

const getPriorityText = (priority) => {
  const texts = {
    low: '低',
    medium: '中',
    high: '高',
    urgent: '紧急'
  }
  return texts[priority] || '未知'
}

// 状态标签
const getStatusTag = (status) => {
  const tags = {
    pending: 'info',
    processing: 'warning',
    completed: 'success',
    closed: 'info'
  }
  return tags[status] || 'info'
}

const getStatusText = (status) => {
  const texts = {
    pending: '待处理',
    processing: '处理中',
    completed: '已完成',
    closed: '已关闭'
  }
  return texts[status] || '未知'
}

// 格式化日期
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString()
}

// 修改提交回复的方法中的 API 路径
const submitReply = async () => {
  if (!replyContent.value.trim()) {
    ElMessage.warning('请输入回复内容')
    return
  }

  submittingReply.value = true
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/v1/tools/tickets/${currentTicket.value.id}/reply/`,
      { content: replyContent.value },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      }
    )

    // 更新当前工单的回复列表
    if (response.data) {
      currentTicket.value.replies.push(response.data)
    }
    replyContent.value = '' // 清空回复框
    ElMessage.success('回复成功')
    
    // 刷新工单列表
    fetchTickets()
  } catch (error) {
    console.error('提交回复失败:', error)
    ElMessage.error('回复失败，请稍后重试')
  } finally {
    submittingReply.value = false
  }
}

// 在关闭对话框时清空回复内容
const handleCloseDialog = () => {
  replyContent.value = ''
  currentTicket.value = null // 清空当前工单数据
}

// 添加是否可以回复的计算属性
const canReply = computed(() => {
  return currentTicket.value && 
         !['completed', 'closed'].includes(currentTicket.value.status)
})

// 添加工单结束状态提示文本
const ticketEndStatusTip = computed(() => {
  if (currentTicket.value?.status === 'completed') {
    return '此工单已完成，无法继续回复。如有新的问题，请创建新工单。'
  }
  return '此工单已关闭，无法继续回复。如有新的问题，请创建新工单。'
})

// 定义工单类型选项
const ticketTypes = [
  { label: '功能建议', value: 'feature', icon: Plus },
  { label: '问题反馈', value: 'bug', icon: Warning },
  { label: '使用咨询', value: 'question', icon: QuestionFilled },
  { label: '其他', value: 'other', icon: More }
]

// 定义优先级选项
const priorityOptions = [
  { label: '低', value: 'low', tagType: 'info', effect: 'plain' },
  { label: '中', value: 'medium', tagType: 'warning', effect: 'plain' },
  { label: '高', value: 'high', tagType: 'danger', effect: 'plain' },
  { label: '紧急', value: 'urgent', tagType: 'danger', effect: 'dark' }
]

onMounted(() => {
  fetchTickets()
})
</script>

<style scoped>
.tickets-container {
  padding: 20px;
  height: calc(100vh - 60px); /* 减去顶部导航栏的高度 */
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  overflow-y: auto; /* 添加垂直滚动 */
}

.tickets-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ticket-detail {
  padding: 20px;
}

.ticket-info {
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.ticket-content {
  margin-top: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.ticket-replies {
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.replies-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #303133;
}

.reply-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.reply-item {
  padding: 12px;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.staff-reply {
  background-color: #ecf5ff;
  border-left: 3px solid #409eff;
}

.reply-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.reply-user {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

.reply-time {
  color: #909399;
  font-size: 13px;
}

.reply-content {
  line-height: 1.6;
  color: #606266;
  white-space: pre-wrap;
  word-break: break-word;
}

.no-replies {
  text-align: center;
  color: #909399;
  padding: 20px 0;
}

.ticket-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.status-control {
  min-width: 120px;
}

.user-actions {
  display: flex;
  gap: 10px;
}

.status-info {
  margin: 15px 0;
}

.el-alert {
  margin-bottom: 15px;
}

.el-tag {
  font-size: 14px;
  padding: 8px 12px;
}

.ticket-closed-tip {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.ticket-closed-tip .el-alert {
  margin: 0;
}

/* 工单详情对话框样式 */
:deep(.ticket-detail-dialog .el-dialog) {
  max-width: 800px;  /* 设置最大宽度 */
}

.ticket-detail {
  padding: 20px;
}

.ticket-header {
  margin-bottom: 20px;
}

.ticket-header h3 {
  margin: 0;
  font-size: 18px;
  color: #303133;
}

.status-info {
  margin: 15px 0;
}

.ticket-info {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 15px;
  margin: 15px 0;
}

.info-item {
  display: flex;
  margin-bottom: 8px;
  line-height: 1.8;
}

.info-item:last-child {
  margin-bottom: 0;
}

.info-item .label {
  width: 80px;
  color: #606266;
  font-weight: 500;
}

.ticket-content {
  margin-top: 20px;
}

.content-label {
  font-weight: 500;
  color: #606266;
  margin-bottom: 8px;
}

.content-text {
  line-height: 1.6;
  color: #303133;
  white-space: pre-wrap;
  word-break: break-word;
}

:deep(.el-tag) {
  padding: 6px 12px;
}

/* 确保对话框内容不会溢出 */
:deep(.el-dialog__body) {
  max-height: 70vh;
  overflow-y: auto;
}

/* 优化移动端显示 */
@media screen and (max-width: 768px) {
  :deep(.ticket-detail-dialog .el-dialog) {
    width: 90% !important;
    margin: 5vh auto !important;
  }

  .ticket-info {
    padding: 10px;
  }

  .info-item {
    flex-direction: column;
    margin-bottom: 12px;
  }

  .info-item .label {
    width: 100%;
    margin-bottom: 4px;
  }
}

.admin-actions {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.reply-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.ticket-end-status {
  margin-top: 20px;
}

/* 表格容器样式 */
.el-table {
  width: 100%;
}

/* 分页容器样式 */
.pagination-container {
  margin: 20px 0;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

/* 对话框样式优化 */
:deep(.el-dialog) {
  margin: 5vh auto !important;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

:deep(.el-dialog__body) {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

/* 确保内容区域填充 */
:deep(.el-main) {
  padding: 0;
  height: 100%;
  width: 100%;
}

.status-info {
  margin: 15px 0;
}

.admin-actions {
  margin: 15px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
  border-left: 3px solid #409eff;
}

/* 优化弹窗样式 */
:deep(.custom-dialog .el-dialog) {
  margin: 15vh auto !important;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  position: relative;
  transform: none;
}

:deep(.custom-dialog .el-dialog__header) {
  padding: 20px;
  margin: 0;
  border-bottom: 1px solid #ebeef5;
}

:deep(.custom-dialog .el-dialog__body) {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
}

:deep(.custom-dialog .el-dialog__footer) {
  padding: 15px 20px;
  border-top: 1px solid #ebeef5;
  background: #fff;
  border-radius: 0 0 8px 8px;
}

/* 工单详情弹窗特定样式 */
.ticket-detail {
  padding: 0;
  min-height: 200px;
}

.ticket-header {
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ebeef5;
}

.ticket-header h3 {
  font-size: 20px;
  color: #303133;
  margin: 0;
}

.ticket-info {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  :deep(.custom-dialog .el-dialog) {
    width: 95% !important;
    max-height: 95vh;
  }

  .ticket-info {
    padding: 15px;
  }

  .reply-item {
    padding: 10px;
  }

  .info-item {
    flex-direction: column;
    gap: 4px;
  }

  .info-item .label {
    width: auto;
  }
}

/* 美化滚动条 */
:deep(.el-dialog__body)::-webkit-scrollbar {
  width: 6px;
}

:deep(.el-dialog__body)::-webkit-scrollbar-thumb {
  background-color: #dcdfe6;
  border-radius: 3px;
}

:deep(.el-dialog__body)::-webkit-scrollbar-track {
  background-color: #f5f7fa;
}

/* 回复区域样式优化 */
.reply-section {
  margin-top: 24px;
  padding-top: 20px;
  border-top: 1px solid #ebeef5;
}

.reply-list {
  gap: 16px;
}

.reply-item {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

.staff-reply {
  background-color: #f0f9ff;
  border-left: 4px solid #409eff;
}

/* 新建工单弹窗样式 */
.create-ticket-dialog {
  :deep(.el-dialog) {
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 15vh auto !important;
    transform: none !important;
  }

  :deep(.el-dialog__header) {
    padding: 20px 24px;
    margin: 0;
    border-bottom: 1px solid #ebeef5;
  }

  :deep(.el-dialog__title) {
    font-size: 18px;
    font-weight: 600;
    color: #303133;
  }

  :deep(.el-dialog__body) {
    padding: 24px;
    max-height: calc(70vh - 120px);
    overflow-y: auto;
  }

  :deep(.el-form-item) {
    margin-bottom: 24px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* 移除可能冲突的动画效果 */
.create-ticket-dialog {
  :deep(.el-dialog) {
    animation: none;
    transition: none;
  }
}

/* 优化表单样式 */
.create-ticket-form {
  :deep(.el-form-item__content) {
    .el-input,
    .el-select,
    .el-textarea {
      width: 100%;
    }
  }

  :deep(.el-form-item__label) {
    font-weight: 500;
    color: #606266;
  }

  :deep(.el-input__wrapper),
  :deep(.el-textarea__inner) {
    box-shadow: none;
    border: 1px solid #dcdfe6;
    transition: all 0.3s;
    
    &:hover, &:focus {
      border-color: #409eff;
    }
  }
}

/* 优化下拉选项样式 */
.full-width-select {
  :deep(.el-select-dropdown__item) {
    padding: 8px 12px;
    
    .el-icon {
      margin-right: 8px;
      vertical-align: middle;
    }
    
    span {
      vertical-align: middle;
    }
    
    .el-tag {
      margin: 0;
    }
  }
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .create-ticket-dialog {
    :deep(.el-dialog) {
      width: 90% !important;
      margin: 10vh auto !important;
    }

    :deep(.el-form-item__label) {
      float: none;
      display: block;
      text-align: left;
      padding: 0 0 8px;
    }

    :deep(.el-form-item__content) {
      margin-left: 0 !important;
    }
  }
}

/* 表单项悬停效果 */
:deep(.el-form-item:hover) {
  .el-input__wrapper,
  .el-textarea__inner {
    border-color: #409eff;
  }
}

/* 提交按钮样式 */
.dialog-footer {
  .el-button--primary {
    padding: 12px 24px;
    font-weight: 500;
    
    &:not(:disabled):hover {
      transform: translateY(-1px);
      box-shadow: 0 2px 6px rgba(64, 158, 255, 0.2);
    }
  }
}

/* 字数统计样式优化 */
:deep(.el-input__count-inner),
:deep(.el-textarea__count) {
  background: transparent;
  font-size: 12px;
  color: #909399;
}
</style>

