<template>
  <div class="login-container">
    <el-card class="login-card">
      <h2 class="login-title">欢迎登录</h2>
      <el-tabs v-model="activeTab" @tab-click="handleTabClick">
        <el-tab-pane label="邮箱登录" name="email">
          <el-form 
            :model="loginForm" 
            :rules="rules" 
            ref="loginFormRef" 
            label-position="top"
          >
            <el-form-item prop="email">
              <el-input 
                v-model="loginForm.email" 
                placeholder="邮箱"
              >
                <template #prefix>
                  <el-icon><Message /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="captcha">
              <el-input 
                v-model="loginForm.captcha" 
                placeholder="验证码"
                class="captcha-input"
              >
                <template #append>
                  <div class="captcha-wrapper" @click="refreshCaptcha">
                    <img v-if="captchaImage" :src="captchaImage" alt="验证码" class="captcha-image"/>
                    <span v-else>加载中...</span>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="verificationCode">
              <el-input 
                v-model="loginForm.verificationCode" 
                placeholder="验证码"
              >
                <template #append>
                  <el-button @click="getVerificationCode" :disabled="isGettingCode || countdown > 0">
                    {{ countdown > 0 ? `${countdown}s后试` : '获取验证码' }}
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm" class="login-button">登录</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="账号密码登录" name="password">
          <el-form 
            :model="loginForm" 
            :rules="rules" 
            ref="loginFormRef" 
            label-position="top"
          >
            <el-form-item prop="login">
              <el-input 
                v-model="loginForm.login" 
                placeholder="账号或邮箱"
              >
                <template #prefix>
                  <el-icon><User /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input 
                v-model="loginForm.password" 
                type="password" 
                placeholder="密码"
                show-password
              >
                <template #prefix>
                  <el-icon><Lock /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="captcha">
              <el-input 
                v-model="loginForm.captcha" 
                placeholder="验证码"
                class="captcha-input"
              >
                <template #append>
                  <div class="captcha-wrapper" @click="refreshCaptcha">
                    <img v-if="captchaImage" :src="captchaImage" alt="验证码" class="captcha-image"/>
                    <span v-else>加载中...</span>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm" class="login-button">登录</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div class="additional-links">
        <el-link type="primary">忘记密码？</el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link type="primary" @click="$router.push('/register')">注册新账号</el-link>
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { User, Lock, Message } from '@element-plus/icons-vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';
import { API_BASE_URL } from '@/config'
import { useUserStore } from '@/stores/user'

const router = useRouter();
const route = useRoute();
const activeTab = ref('email');
const isGettingCode = ref(false);
const countdown = ref(0);
const hasRequestedCode = ref(false);
const captchaImage = ref('');
const captchaKey = ref('');

const loginForm = reactive({
  login: '',
  password: '',
  email: '',
  verificationCode: '',
  captcha: ''
});

const rules = {
  login: [
    { required: true, message: '请输入账号或邮箱', trigger: 'blur' },
    { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
  ],
  email: [
    { required: true, message: '请输入邮箱', trigger: 'blur' },
    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
  ],
  verificationCode: [
    { required: true, message: '请输入验证码', trigger: 'submit' },
    { len: 6, message: '验证码长度应为 6 位', trigger: 'submit' }
  ],
  captcha: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { len: 4, message: '验证码长度应为 4 位', trigger: 'blur' }
  ]
};

const loginFormRef = ref(null);

const store = useUserStore()

const handleTabClick = async () => {
  loginFormRef.value.resetFields();
  hasRequestedCode.value = false;
  isGettingCode.value = false;
  countdown.value = 0;
  if (activeTab.value === 'password') {
    await getCaptcha();
  }
};

const getVerificationCode = async () => {
  if (!loginForm.email) {
    ElMessage.warning('请先输入邮箱地址');
    return;
  }
  if (!loginForm.captcha) {
    ElMessage.warning('请输入验证码');
    return;
  }
  if (isGettingCode.value || countdown.value > 0) {
    return;
  }
  try {
    isGettingCode.value = true;
    const response = await axios.post(`${API_BASE_URL}/api/v1/user/gen-email-code/`, {
      email: loginForm.email,
      captcha_key: captchaKey.value,
      captcha_code: loginForm.captcha
    });
    
    if (response.data.status === 200) {
      ElMessage.success(response.data.message || '验证码已发送，请查收邮箱');
      startCountdown();
      hasRequestedCode.value = true;
    } else {
      ElMessage.warning(response.data.message || '获取验证码失效，请稍后重试');
    }
  } catch (error) {
    console.error('获取验证码失败:', error);
    if (error.response && error.response.data) {
      if (error.response.data.status === 100) {
        ElMessage.error(error.response.data.message);
      } else if (error.response.data.email) {
        ElMessage.error(error.response.data.email[0]);
      } else {
        ElMessage.error(error.response.data.message || '获取验证码失败，请稍后重试');
      }
    } else {
      ElMessage.error('获取验证码失败，请稍后重试');
    }
  } finally {
    isGettingCode.value = false;
  }
};

const startCountdown = () => {
  countdown.value = 60;
  const timer = setInterval(() => {
    countdown.value--;
    if (countdown.value <= 0) {
      clearInterval(timer);
    }
  }, 1000);
};

const getCaptcha = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/captcha/generate/`);
    captchaImage.value = response.data.image;
    captchaKey.value = response.data.key;
  } catch (error) {
    console.error('获取验证码失败:', error);
    ElMessage.error('获取验证码失败，请刷新重试');
  }
};

const refreshCaptcha = () => {
  loginForm.captcha = '';
  getCaptcha();
};

const submitForm = async () => {
  if (!loginFormRef.value) return;

  try {
    if (activeTab.value === 'password') {
      if (!loginForm.login) {
        ElMessage.warning('请输入账号或邮箱');
        return;
      }
      if (!loginForm.password) {
        ElMessage.warning('请输入密码');
        return;
      }
      if (!loginForm.captcha) {
        ElMessage.warning('请输入验证码');
        return;
      }

      const response = await axios.post(`${API_BASE_URL}/api/v1/user/token/`, {
        login: loginForm.login,
        password: loginForm.password,
        captcha_key: captchaKey.value,
        captcha_code: loginForm.captcha
      });

      if (response.data.tokens || (response.data.access && response.data.refresh)) {
        const tokens = response.data.tokens || {
          access: response.data.access,
          refresh: response.data.refresh
        };
        
        store.setTokens(tokens);
        await store.checkAuthStatus();
        
        ElMessage.success('登录成功！');
        
        const redirect = route.query.redirect;
        router.push(redirect || '/');
      }
    } else {
      if (!loginForm.email) {
        ElMessage.warning('请先输入邮箱地址');
        return;
      }
      
      if (!hasRequestedCode.value) {
        ElMessage.warning('请先获取验证码');
        return;
      }
      
      if (!loginForm.verificationCode) {
        ElMessage.warning('请输入验证码');
        return;
      }

      console.log('发送邮箱登录请求:', {
        email: loginForm.email,
        vercode: loginForm.verificationCode
      });
      
      const response = await axios.post(`${API_BASE_URL}/api/v1/user/email-login/`, {
        email: loginForm.email,
        vercode: loginForm.verificationCode
      });
      
      console.log('服务器响应:', response.data);
      
      if (response.data.status === 201) {
        ElMessage.error(response.data.message);
        return;
      }

      if (response.data.tokens || (response.data.access && response.data.refresh)) {
        const tokens = response.data.tokens || {
          access: response.data.access,
          refresh: response.data.refresh
        };
        
        store.setTokens(tokens);
        await store.checkAuthStatus();
        
        ElMessage.success('登录成功！');
        
        const redirect = route.query.redirect;
        router.push(redirect || '/');
      }
    }
  } catch (error) {
    console.error('登录失败:', error);
    if (error.response?.data) {
      let errorMsg = '登录失败';
      const errorData = error.response.data;
      
      if (errorData.non_field_errors && Array.isArray(errorData.non_field_errors)) {
        errorMsg = errorData.non_field_errors[0];
      } else if (typeof errorData === 'string') {
        errorMsg = errorData;
      } else if (errorData.message) {
        errorMsg = errorData.message;
      } else if (errorData.detail) {
        errorMsg = errorData.detail;
      } else if (errorData.login) {
        errorMsg = errorData.login[0];
      } else if (errorData.password) {
        errorMsg = errorData.password[0];
      }
      
      const errorMap = {
        '用户不存在': '账号或邮箱不存在，请检查输入是否正确',
        'Unable to log in with provided credentials.': '账号或密码错误，请重新输入',
        'This field may not be blank.': '请填写完整的登录信息'
      };
      
      ElMessage.error(errorMap[errorMsg] || errorMsg);
      if (error.response.data.captcha) {
        refreshCaptcha();
      }
    } else if (error.request) {
      ElMessage.error('网络请求失败，请检查网络连接');
    } else {
      ElMessage.error('请求发送失败，请稍后重试');
    }
  }
};

onMounted(async () => {
  await getCaptcha();
});

watch(activeTab, async (newValue) => {
  if (newValue === 'password') {
    await getCaptcha();
  }
});

</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(50vh - 120px);
  padding: 20px;
  padding-top: 100px;
  padding-bottom: -20px;
}

.login-card {
  width: 100%;
  max-width: 360px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.login-title {
  text-align: center;
  font-size: 24px;
  color: #303133;
  margin: 20px 0 30px;
}

.login-button {
  width: 100%;
}

.additional-links {
  margin-top: 20px;
  text-align: center;
}

:deep(.el-input__wrapper) {
  padding-left: 11px;
}

:deep(.el-input__prefix) {
  left: 5px;
}

@media (max-width: 768px) {
  .login-container {
    min-height: calc(50vh - 60px);
    padding: 10px;
    padding-top: 100px;
    padding-bottom: -20px;
  }

  .login-title {
    margin: 15px 0 25px;
  }
}

.el-tabs {
  margin-bottom: 20px;
}

.captcha-input :deep(.el-input-group__append) {
  padding: 0;
  background: #fff;
}

.captcha-wrapper {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.captcha-image {
  height: 30px;
  margin: 0 5px;
}
</style>
