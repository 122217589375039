<template>
  <div class="container">
    <div class="layout">
      <!-- 第一列：最小化图标导航栏 -->
      <div class="mini-nav">
        <div class="nav-top">
          <div class="nav-icons">
            <el-tooltip
              content="任务"
              placement="right"
              :show-after="100"
              :hide-after="0"
            >
              <div class="nav-icon" :class="{ active: currentView === 'tasks' }">
                <el-icon><List /></el-icon>
              </div>
            </el-tooltip>
            
            <el-tooltip
              content="四象限"
              placement="right"
              :show-after="100"
              :hide-after="0"
            >
              <div class="nav-icon" :class="{ active: currentView === 'matrix' }">
                <el-icon><Grid /></el-icon>
              </div>
            </el-tooltip>
            
            <el-tooltip
              content="定时任务"
              placement="right"
              :show-after="100"
              :hide-after="0"
            >
              <div class="nav-icon" :class="{ active: currentView === 'clock' }">
                <el-icon><Clock /></el-icon>
              </div>
            </el-tooltip>

            <el-tooltip
              content="搜索"
              placement="right"
              :show-after="100"
              :hide-after="0"
            >
              <div class="nav-icon" :class="{ active: currentView === 'search' }">
                <el-icon><Search /></el-icon>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="nav-bottom">
          <el-tooltip
            content="通知"
            placement="right"
            :show-after="100"
            :hide-after="0"
          >
            <div class="nav-icon">
              <el-icon><Bell /></el-icon>
            </div>
          </el-tooltip>

          <el-tooltip
            content="设置"
            placement="right"
            :show-after="100"
            :hide-after="0"
          >
            <div class="nav-icon">
              <el-icon><Setting /></el-icon>
            </div>
          </el-tooltip>
        </div>
      </div>

      <!-- 第二列：项目列表 -->
      <div class="project-list">
        <div class="list-header">
          <el-divider content-position="left">清单</el-divider>
          <el-button 
            type="text" 
            size="small" 
            @click="showAddCategoryDialog = true"
          >
            <el-icon><Plus /></el-icon>
          </el-button>
        </div>
        
        <div class="list-section">
          <div class="section-item" 
            v-for="item in quickAccess" 
            :key="item.id"
            :class="{ active: currentProject === item.id }"
            @click="currentProject = item.id"
          >
            <div class="icon-wrapper" :style="{ color: item.color }">
              <el-icon><component :is="item.icon" /></el-icon>
            </div>
            <span class="item-title">{{ item.title }}</span>
            <span class="item-count" :style="{ backgroundColor: currentProject === item.id ? item.color + '15' : '' }">
              {{ item.count }}
            </span>
          </div>
        </div>

        <el-divider content-position="left">清单</el-divider>
        
        <div class="list-section">
          <div class="section-item" 
            v-for="item in lists" 
            :key="item.id"
            :class="{ active: currentProject === item.id }"
            @click="currentProject = item.id"
          >
            <div class="icon-wrapper" :style="{ color: item.color }">
              <el-icon><component :is="item.icon" /></el-icon>
            </div>
            <span class="item-title">{{ item.title }}</span>
            <span class="item-count" :style="{ backgroundColor: currentProject === item.id ? item.color + '15' : '' }">
              {{ item.count }}
            </span>
          </div>
        </div>

        <div class="list-footer">
          <div class="section-item">
            <div class="icon-wrapper">
              <el-icon><Delete /></el-icon>
            </div>
            <span class="item-title">垃圾桶</span>
          </div>
        </div>

        <!-- 添加分类对话框 -->
        <el-dialog
          v-model="showAddCategoryDialog"
          title="创建新分类"
          width="500px"
        >
          <el-form :model="newCategory" label-width="80px">
            <el-form-item label="名称" required>
              <el-input v-model="newCategory.name" placeholder="请输入分类名称" />
            </el-form-item>
            
            <el-form-item label="描述">
              <el-input 
                v-model="newCategory.description" 
                type="textarea" 
                placeholder="请输入分类描述" 
              />
            </el-form-item>
            
            <el-form-item label="图标">
              <el-select v-model="newCategory.icon" placeholder="请选择图标">
                <el-option
                  v-for="icon in iconOptions"
                  :key="icon.value"
                  :label="icon.label"
                  :value="icon.value"
                >
                  <el-icon>
                    <component :is="icon.component" />
                  </el-icon>
                  <span style="margin-left: 8px">{{ icon.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item label="颜色">
              <el-select v-model="newCategory.color" placeholder="请选择颜色">
                <el-option
                  v-for="color in colorOptions"
                  :key="color.value"
                  :label="color.label"
                  :value="color.value"
                >
                  <div class="color-option">
                    <div 
                      class="color-block" 
                      :style="{ backgroundColor: color.value }"
                    ></div>
                    <span>{{ color.label }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="showAddCategoryDialog = false">取消</el-button>
              <el-button type="primary" @click="createCategory">创建</el-button>
            </span>
          </template>
        </el-dialog>
      </div>

      <!-- 第三列：任务列表 -->
      <div class="task-list">
        <div class="list-header">
          <h2>收集箱</h2>
          <div class="header-actions">
            <el-button-group>
              <el-button text><el-icon><Sort /></el-icon></el-button>
              <el-button text><el-icon><More /></el-icon></el-button>
            </el-button-group>
          </div>
        </div>

        <div class="quick-add">
          <el-input
            v-model="newTask"
            placeholder="添加任务..."
            @keyup.enter="addTask"
          >
            <template #prefix>
              <el-icon><Plus /></el-icon>
            </template>
          </el-input>
        </div>

        <div class="tasks">
          <!-- 未完成任务 -->
          <div class="task-item" 
            v-for="task in activeTasks" 
            :key="task.id"
          >
            <el-checkbox 
              :model-value="task.completed"
              @update:model-value="(val) => handleTaskStatusChange(task, val)"
              @click.stop
            />
            <div class="task-content" @click="handleTaskClick(task)">
              <template v-if="task.isEditing">
                <el-input
                  v-model="task.title"
                  @blur="saveTaskEdit(task)"
                  @keyup.enter="saveTaskEdit(task)"
                  @keyup.esc="cancelTaskEdit(task)"
                  v-focus
                />
              </template>
              <template v-else>
                <div class="task-title">{{ task.title }}</div>
                <div class="task-date" v-if="task.due_date">{{ task.due_date }}</div>
              </template>
            </div>
          </div>

          <!-- 已完成任务分组 -->
          <div class="completed-group" v-if="completedTasks.length">
            <div class="group-header" @click="showCompleted = !showCompleted">
              <el-icon :class="{ 'is-rotate': showCompleted }"><ArrowRight /></el-icon>
              <span>已完成</span>
              <span class="count">{{ completedTasks.length }}</span>
            </div>
            <div class="completed-tasks" v-show="showCompleted">
              <div class="task-item completed" v-for="task in completedTasks" :key="task.id">
                <el-checkbox 
                  :model-value="task.completed"
                  @update:model-value="(val) => handleTaskStatusChange(task, val)"
                  @click.stop
                />
                <div class="task-content">
                  <div class="task-title">{{ task.title }}</div>
                  <div class="task-date" v-if="task.due_date">{{ task.due_date }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 添加分页组件 -->
        <div class="pagination-container">
          <el-pagination
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :total="total"
            :page-sizes="[10, 20, 30, 50]"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <!-- 第四列：任务详情 -->
      <div class="task-detail-panel" v-if="currentTask">
        <div class="task-detail">
          <div class="detail-header">
            <el-input
              v-model="currentTask.title"
              placeholder="请输入标题"
              @blur="saveTaskChanges('title')"
            />
          </div>

          <div class="detail-section">
            <div class="detail-item">
              <label>优先级：</label>
              <el-select 
                v-model="currentTask.priority"
                @change="saveTaskChanges('priority')"
              >
                <el-option
                  v-for="(info, level) in priorityMap"
                  :key="level"
                  :label="info.label"
                  :value="Number(level)"
                />
              </el-select>
            </div>

            <div class="detail-item">
              <label>四象限：</label>
              <el-select 
                v-model="currentTask.quadrant"
                @change="saveTaskChanges('quadrant')"
              >
                <el-option
                  v-for="(info, quad) in quadrantMap"
                  :key="quad"
                  :label="info.label"
                  :value="Number(quad)"
                />
              </el-select>
            </div>

            <div class="detail-item">
              <label>所属分类：</label>
              <el-select 
                v-model="currentTask.category"
                @change="saveTaskChanges('category')"
              >
                <el-option
                  v-for="category in categories"
                  :key="category.id"
                  :label="category.name"
                  :value="category.id"
                />
              </el-select>
            </div>

            <div class="detail-item">
              <label>截止时间：</label>
              <el-date-picker
                v-model="currentTask.due_date"
                type="datetime"
                placeholder="选择截止时间"
                format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm:ss"
                @change="saveTaskChanges('due_date')"
              />
            </div>

            <div class="detail-item description">
              <label>描述：</label>
              <el-input
                v-model="currentTask.description"
                type="textarea"
                :rows="4"
                placeholder="请输入任务描述"
                @blur="saveTaskChanges('description')"
              />
            </div>

            <div class="detail-item">
              <label>创建时间：</label>
              <span>{{ formatDateTime(currentTask.created_at) }}</span>
            </div>

            <div class="detail-item">
              <label>更新时间：</label>
              <span>{{ formatDateTime(currentTask.updated_at) }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 如果没有选中任务，显示提示信息 -->
      <div class="task-detail-empty" v-else>
        <el-empty description="选择一个任务查看详情" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px;
  background-color: #f0f2f5;
}

.layout {
  width: 100%;
  padding: 10px;
  height: 80vh;
  display: grid;
  grid-template-columns: 60px 240px minmax(400px, 1fr) minmax(400px, 1fr);
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* 一列样式 */
.mini-nav {
  background: #fff;
  border-right: 1px solid #eee;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-icons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.nav-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  color: #606266;
  transition: all 0.3s;
}

.nav-icon:hover {
  background: #f5f7fa;
}

.nav-icon.active {
  background: #e6f4ff;
  color: #409EFF;
}

/* 第二列样式 */
.project-list {
  background: #fff;
  border-right: 1px solid #eee;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.list-section {
  margin-bottom: 16px;
}

.section-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  margin: 4px 0;
  transition: all 0.3s;
}

.section-item:hover {
  background: #f5f7fa;
}

.section-item.active {
  background: #f0f7ff;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 16px;
}

.item-title {
  margin-left: 8px;
  flex: 1;
  font-size: 14px;
}

.item-count {
  background: #f5f7fa;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 12px;
  color: #666;
  min-width: 20px;
  text-align: center;
}

.list-footer {
  margin-top: auto;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.list-footer .section-item {
  color: #999;
}

.list-footer .section-item:hover {
  color: #666;
}

/* 第三列样式 */
.task-list {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eee;
}

.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.quick-add {
  margin-bottom: 20px;
}

.tasks {
  flex: 1;
  overflow-y: auto;
}

.task-item {
  display: flex;
  align-items: flex-start;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.task-item:hover {
  background-color: #f5f7fa;
}

.task-item.active {
  background-color: #f0f7ff;
}

.task-content {
  flex: 1;
  margin-left: 12px;
}

.task-content .el-input {
  width: 100%;
}

.task-date {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
}

.completed-group {
  margin-top: 20px;
}

.group-header {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: #666;
  cursor: pointer;
}

.group-header .el-icon {
  margin-right: 8px;
  transition: transform 0.2s;
}

.group-header .is-rotate {
  transform: rotate(90deg);
}

.count {
  margin-left: 8px;
  color: #999;
  font-size: 12px;
}

.completed-tasks .task-item {
  opacity: 0.6;
}

/* 第四列样式 */
.task-detail-panel {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.detail-item {
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.detail-item label {
  min-width: 80px;
  color: #606266;
}

.detail-item .el-select,
.detail-item .el-date-picker {
  width: 100%;
  max-width: 300px;
}

.description {
  flex-direction: column;
}

.description .el-input {
  width: 100%;
}

.task-detail-empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--el-bg-color);
  border-left: 1px solid var(--el-border-color-light);
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.detail-header h2 {
  margin: 0;
}

.detail-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detail-item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.detail-item label {
  color: #666;
  min-width: 80px;
}

.tags-list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.description {
  flex-direction: column;
}

.description-content {
  margin-top: 8px;
  white-space: pre-wrap;
  color: #333;
  line-height: 1.5;
}

/* 添加新的样式 */
.header-actions {
  display: flex;
  gap: 8px;
}

.edit-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid var(--el-border-color-lighter);
}

.detail-item .el-select,
.detail-item .el-date-picker {
  width: 100%;
  max-width: 300px;
}

.detail-item .el-input {
  width: 100%;
}

.task-content {
  flex: 1;
  margin-left: 12px;
}

.task-content .el-input {
  width: 100%;
}
</style>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import {
  List, Grid, Clock, Search, Bell, Setting, Plus,
  Sort, More, Delete, Calendar, Collection, Finished,
  ArrowRight, Folder, HomeFilled, Briefcase, 
  Reading, Basketball, Coffee, Shop, Sunny, Moon,
  Location, School, Notebook, Headset
} from '@element-plus/icons-vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { API_BASE_URL } from '@/config'

// 获取 router 实例
const router = useRouter()

// 创建 axios 实例
const axiosInstance = axios.create({
  baseURL: API_BASE_URL
})

// 添加请求拦截器
axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken')
  config.headers = {
    ...config.headers,
    'Authorization': `Bearer ${token}`,
    'Connection': 'keep-alive'
  }
  return config
})

// 响应式数据
const currentView = ref('tasks')
const currentProject = ref('inbox')
const currentTask = ref(null)
const newTask = ref('')
const showCompleted = ref(true)
const tasks = ref([])

// 分页相关
const currentPage = ref(1)
const pageSize = ref(10)
const total = ref(0)
const loading = ref(false)

// 任务点击处理
const handleTaskClick = async (task) => {
  try {
    // 获取任务详情
    const response = await axiosInstance.get(`/api/v1/todos/todos/${task.id}/`)
    currentTask.value = response.data
  } catch (error) {
    console.error('获取任务详情失败:', error)
    handleError(error)
  }
}

// 保存任务更改
const saveTaskChanges = async () => {
  if (!currentTask.value) return

  try {
    const updateData = {
      title: currentTask.value.title,
      description: currentTask.value.description,
      priority: currentTask.value.priority,
      quadrant: currentTask.value.quadrant,
      category: currentTask.value.category,
      due_date: currentTask.value.due_date,
    }

    await axiosInstance.put(
      `/api/v1/todos/todos/${currentTask.value.id}/`,
      updateData
    )
    
    // 刷新任务列表
    await fetchTodos()
    ElMessage.success('更新成功')
  } catch (error) {
    console.error('更新任务失败:', error)
    handleError(error)
  }
}

// 处理任务状态变化
const handleTaskStatusChange = async (task, newValue) => {
  try {
    await axiosInstance.post(`/api/v1/todos/todos/${task.id}/set_status/`, {
      completed: newValue,
      action: newValue ? 'complete' : 'incomplete'
    })
    
    task.completed = newValue
    await fetchTodos()
    ElMessage.success(newValue ? '已标记为完成' : '已标记为未完成')
  } catch (error) {
    console.error('更新任务状态失败:', error)
    handleError(error)
  }
}

// 格式化日期时间
const formatDateTime = (dateStr) => {
  if (!dateStr) return ''
  return new Date(dateStr).toLocaleString('zh-CN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  })
}

// 映射对象
const priorityMap = {
  1: { label: '低', type: 'info' },
  2: { label: '中', type: 'warning' },
  3: { label: '高', type: 'danger' }
}

const quadrantMap = {
  1: { label: '重要且紧急', type: 'danger' },
  2: { label: '重要不紧急', type: 'warning' },
  3: { label: '不重要但紧急', type: 'info' },
  4: { label: '不重要不紧急', type: 'success' }
}

// 计算属性
const activeTasks = computed(() => tasks.value.filter(task => !task.completed))
const completedTasks = computed(() => tasks.value.filter(task => task.completed))

// 获取待办事项列表
const fetchTodos = async () => {
  loading.value = true
  try {
    const response = await axiosInstance.get('/api/v1/todos/todos/', {
      params: {
        page: currentPage.value,
        page_size: pageSize.value
      }
    })
    
    if (response.data && response.data.results) {
      tasks.value = response.data.results
      total.value = response.data.count
    }
  } catch (error) {
    console.error('获取待办事项失败:', error)
    handleError(error)
  } finally {
    loading.value = false
  }
}

// 分页处理
const handleSizeChange = (newSize) => {
  pageSize.value = newSize
  currentPage.value = 1
  fetchTodos()
}

const handleCurrentChange = (newPage) => {
  currentPage.value = newPage
  fetchTodos()
}

// 错误处理
const handleError = (error) => {
  if (error.response?.status === 401) {
    ElMessage.error('登录已过期，请重新登录')
    localStorage.removeItem('accessToken')
    router.push('/login')
  } else {
    console.error('Error details:', error)
    ElMessage.error(error.response?.data?.message || '操作失败，请稍后重试')
  }
}

// 组件挂载时执行
onMounted(() => {
  fetchTodos()
})

// 快捷访问列表
const quickAccess = ref([
  { 
    id: 'today', 
    title: '今天', 
    count: 2,
    icon: Calendar,
    color: '#1677ff'
  },
  { 
    id: 'recent', 
    title: '最近7天', 
    count: 5,
    icon: Clock,
    color: '#52c41a'
  },
  { 
    id: 'inbox', 
    title: '收集箱', 
    count: 4,
    icon: Collection,
    color: '#1677ff'
  },
  { 
    id: 'finished', 
    title: '已完成', 
    count: 8,
    icon: Finished,
    color: '#52c41a'
  }
])

// 响应式数据
const categories = ref([])

// 获取分类列表
const fetchCategories = async () => {
  try {
    const response = await axiosInstance.get('/api/v1/todos/categories/')
    if (response.data && Array.isArray(response.data.results)) {
      categories.value = response.data.results
    } else {
      console.error('意外的分类数据格式:', response.data)
      categories.value = []
      ElMessage.error('获取分类列表失败：意外的数据格式')
    }
  } catch (error) {
    console.error('获取分列表失败:', error)
    categories.value = []
    handleError(error)
  }
}

// 预定义图标选项
const iconOptions = [
  { label: '文件夹', value: 'Folder', component: Folder },
  { label: '工作', value: 'Briefcase', component: Briefcase },
  { label: '学习', value: 'Reading', component: Reading },
  { label: '生活', value: 'HomeFilled', component: HomeFilled },
  { label: '运动', value: 'Basketball', component: Basketball },
  { label: '休闲', value: 'Coffee', component: Coffee },
  { label: '购物', value: 'Shop', component: Shop },
  { label: '日间', value: 'Sunny', component: Sunny },
  { label: '夜间', value: 'Moon', component: Moon },
  { label: '地', value: 'Location', component: Location },
  { label: '学校', value: 'School', component: School },
  { label: '笔记', value: 'Notebook', component: Notebook },
  { label: '音乐', value: 'Headset', component: Headset }
]

// 预定义颜色选项
const colorOptions = [
  { label: '蓝色', value: '#1677ff' },
  { label: '绿色', value: '#52c41a' },
  { label: '紫色', value: '#722ed1' },
  { label: '红色', value: '#f5222d' },
  { label: '橙色', value: '#fa8c16' },
  { label: '青色', value: '#13c2c2' }
]

// 新增分类对话框的显示控制
const showAddCategoryDialog = ref(false)
const newCategory = ref({
  name: '',
  description: '',
  icon: 'Folder',
  color: '#1677ff'
})

// 创建新分类
const createCategory = async () => {
  if (!newCategory.value.name.trim()) {
    ElMessage.warning('请输入分类名称')
    return
  }

  try {
    await axiosInstance.post('/api/v1/todos/categories/', {
      name: newCategory.value.name.trim(),
      description: newCategory.value.description.trim()
    })

    ElMessage.success('创建分类成功')
    showAddCategoryDialog.value = false
    // 重置表单
    newCategory.value = {
      name: '',
      description: '',
      icon: 'Folder',
      color: '#1677ff'
    }
    // 刷新分类列表
    await fetchCategories()
  } catch (error) {
    console.error('创建分类失败:', error)
    handleError(error)
  }
}

// 更新计算属性，加入图标和颜色
const lists = computed(() => categories.value.map((category, index) => ({
  id: category.id,
  title: category.name,
  count: 0,
  icon: category.icon || iconOptions[index % iconOptions.length].value,  // 如果没有图标，循环使用预定义图标
  color: category.color || colorOptions[index % colorOptions.length].value  // 如果没有颜色，循环使用预定义颜色
})))

// 在组件挂载时获取数据
onMounted(() => {
  fetchTodos()
  fetchCategories()
})

// 添加自定义指令以自动聚焦输入框
const vFocus = {
  mounted: (el) => el.querySelector('input').focus()
}

// 创建新待办事项
const addTask = async () => {
  if (!newTask.value.trim()) {
    ElMessage.warning('请输入待办事')
    return
  }

  try {
    await axiosInstance.post('/api/v1/todos/todos/', {
      title: newTask.value.trim(),
      completed: false,
      description: '',
    })

    // 添加成功后刷新列表
    await fetchTodos()
    newTask.value = ''
    ElMessage.success('添加成功')
  } catch (error) {
    console.error('创建待办项失败:', error)
    if (error.response && error.response.data) {
      let errorMessage = '创建失败: '
      if (typeof error.response.data === 'string') {
        errorMessage += error.response.data
      } else if (typeof error.response.data === 'object') {
        const messages = []
        Object.entries(error.response.data).forEach(([field, msg]) => {
          if (Array.isArray(msg)) {
            messages.push(`${field}: ${msg.join(', ')}`)
          } else if (typeof msg === 'string') {
            messages.push(`${field}: ${msg}`)
          }
        })
        errorMessage += messages.join('; ')
      }
      ElMessage.error(errorMessage)
    } else {
      ElMessage.error('创建失败，请稍��重试')
    }
  }
}
</script> 