<template>
    <el-dialog
      v-model="dialogVisible"
      title="问题反馈"
      width="500px"
      :before-close="handleClose"
    >
      <el-form
        ref="feedbackFormRef"
        :model="feedbackForm"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="标题" prop="title">
          <el-input 
            v-model="feedbackForm.title"
            placeholder="请输入反馈标题（至少2个字符）"
          />
        </el-form-item>
        
        <el-form-item label="内容" prop="content">
          <el-input
            v-model="feedbackForm.content"
            type="textarea"
            :rows="4"
            placeholder="请详细描述您的问题（至少5个字符）"
          />
        </el-form-item>
      </el-form>
      
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="submitFeedback" :loading="submitting">
            提交
          </el-button>
        </span>
      </template>
    </el-dialog>
  </template>
  
  <script setup>
  import { ref, defineEmits, defineExpose } from 'vue'
  import { ElMessage } from 'element-plus'
  import axiosInstance from '@/utils/axios'
  
  const emit = defineEmits(['close'])
  const dialogVisible = ref(false)
  const submitting = ref(false)
  const feedbackFormRef = ref(null)
  
  const feedbackForm = ref({
    title: '',
    content: ''
  })
  
  const rules = {
    title: [
      { required: true, message: '请输入标题', trigger: 'blur' },
      { min: 2, message: '标题至少2个字符', trigger: 'blur' }
    ],
    content: [
      { required: true, message: '请输入内容', trigger: 'blur' },
      { min: 5, message: '内容至少5个字符', trigger: 'blur' }
    ]
  }
  
  const open = () => {
    dialogVisible.value = true
  }
  
  const handleClose = () => {
    feedbackForm.value = {
      title: '',
      content: ''
    }
    if (feedbackFormRef.value) {
      feedbackFormRef.value.resetFields()
    }
    dialogVisible.value = false
    emit('close')
  }
  
  const submitFeedback = async () => {
    if (!feedbackFormRef.value) return
    
    await feedbackFormRef.value.validate(async (valid) => {
      if (valid) {
        submitting.value = true
        try {
          await axiosInstance.post('/api/v1/tools/feedback/', feedbackForm.value)
          ElMessage.success('反馈提交成功，感谢您的反馈！')
          handleClose()
        } catch (error) {
          const errorMsg = error.response?.data?.detail || '提交失败，请稍后重试'
          ElMessage.error(errorMsg)
        } finally {
          submitting.value = false
        }
      }
    })
  }
  
  defineExpose({
    open
  })
  </script>
  
  <style scoped>
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  </style>