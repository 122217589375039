<template>
  <div class="favorites-container">
    <el-tabs v-model="activeTab">
      <el-tab-pane lazy label="工具收藏" name="tools">
        <!-- PC端表格 -->
        <el-table
          v-if="!isMobile"
          v-loading="loading"
          :data="tools"
          style="width: 100%"
          :height="400"
          :resize-observer="false"
        >
          <el-table-column prop="title" label="工具名称" width="200" />
          <el-table-column prop="description" label="描述" show-overflow-tooltip />
          <el-table-column prop="category.name" label="分类" width="120">
            <template #default="{ row }">
              {{ row.category?.name || '无分类' }}
            </template>
          </el-table-column>
          <el-table-column label="统计" width="200">
            <template #default="{ row }">
              <div class="stats-wrapper">
                <span class="stat-item">
                  <el-icon><Star /></el-icon>
                  {{ row.collection_count || 0 }}
                </span>
                <span class="stat-item">
                  <el-icon><View /></el-icon>
                  {{ row.views_count || 0 }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template #default="{ row }">
              <a class="action-link primary" @click="viewTool(row.id)">查看</a>
              <a class="action-link danger" @click="cancelCollection(row.id)">取消收藏</a>
            </template>
          </el-table-column>
        </el-table>

        <!-- 移动端卡片列表 -->
        <div v-else class="mobile-list">
          <el-card v-for="item in tools" :key="item.id" class="mobile-card">
            <div class="mobile-card-header">
              <h3 class="mobile-title">{{ item.title }}</h3>
              <div class="mobile-category">{{ item.category?.name || '无分类' }}</div>
            </div>
            <div class="mobile-description">{{ item.description }}</div>
            <div class="mobile-stats">
              <span class="stat-item">
                <el-icon><Star /></el-icon>
                {{ item.collection_count || 0 }}
              </span>
              <span class="stat-item">
                <el-icon><View /></el-icon>
                {{ item.views_count || 0 }}
              </span>
            </div>
            <div class="mobile-actions">
              <el-button type="primary" link @click="viewTool(item.id)">查看</el-button>
              <el-button type="danger" link @click="cancelCollection(item.id)">取消收藏</el-button>
            </div>
          </el-card>
        </div>
      </el-tab-pane>

      <el-tab-pane lazy label="博客收藏" name="blogs">
        <el-table
          v-loading="loading"
          :data="blogs"
          style="width: 100%"
          :height="400"
          :resize-observer="false"
        >
          <el-table-column prop="title" label="博客标题" width="200" />
          <el-table-column prop="description" label="描述" show-overflow-tooltip />
          <el-table-column prop="category.name" label="分类" width="120">
            <template #default="{ row }">
              {{ row.category?.name || '无分类' }}
            </template>
          </el-table-column>
          <el-table-column prop="author" label="作者" width="120" />
          <el-table-column label="统计" width="200">
            <template #default="{ row }">
              <div class="stats-wrapper">
                <span class="stat-item">
                  <el-icon><Star /></el-icon>
                  {{ row.collection_count || 0 }}
                </span>
                <span class="stat-item">
                  <el-icon><View /></el-icon>
                  {{ row.views_count || 0 }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template #default="{ row }">
              <a class="action-link primary" @click="viewBlog(row.id)">查看</a>
              <a class="action-link danger" @click="cancelBlogCollection(row.id)">取消收藏</a>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane lazy label="问答收藏" name="questions">
        <el-table
          v-loading="loading"
          :data="questions"
          style="width: 100%"
          :height="400"
          :resize-observer="false"
        >
          <el-table-column prop="title" label="问题标题" width="200" />
          <el-table-column prop="description" label="描述" show-overflow-tooltip />
          <el-table-column prop="category.name" label="分类" width="120">
            <template #default="{ row }">
              {{ row.category?.name || '无分类' }}
            </template>
          </el-table-column>
          <el-table-column label="统计" width="200">
            <template #default="{ row }">
              <div class="stats-wrapper">
                <span class="stat-item">
                  <el-icon><Star /></el-icon>
                  {{ row.collection_count || 0 }}
                </span>
                <span class="stat-item">
                  <el-icon><View /></el-icon>
                  {{ row.views_count || 0 }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template #default="{ row }">
              <a class="action-link primary" @click="viewQuestion(row.id)">查看</a>
              <a class="action-link danger" @click="cancelQuestionCollection(row.id)">取消收藏</a>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <!-- 分页组件 -->
    <div class="pagination-container">
      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { Star, View } from '@element-plus/icons-vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { API_BASE_URL } from '@/config'

export default {
  name: 'MyFavoritesView',
  components: {
    Star,
    View
  },
  data() {
    return {
      activeTab: 'tools',
      tools: [],
      blogs: [],
      questions: [],
      loading: false,
      isMobile: false
    }
  },
  methods: {
    async fetchData() {
      if (this.activeTab === 'tools') {
        await this.fetchToolsData()
      } else if (this.activeTab === 'blogs') {
        await this.fetchBlogsData()
      } else if (this.activeTab === 'questions') {
        await this.fetchQuestionsData()
      }
    },
    async fetchToolsData() {
      this.loading = true
      try {
        const token = localStorage.getItem('accessToken')
        const response = await axios.get(`${API_BASE_URL}/api/v1/tools/tools/my_collections/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        console.log('工具收藏数据:', response.data)
        this.tools = response.data.results || []
      } catch (error) {
        console.error('获取工具收藏失败:', error)
        ElMessage.error('获取工具收藏列表失败')
      } finally {
        this.loading = false
      }
    },
    async fetchBlogsData() {
      this.loading = true
      try {
        const token = localStorage.getItem('accessToken')
        const response = await axios.get(`${API_BASE_URL}/api/v1/blog/blogs/my_collections/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        console.log('博客收藏数据:', response.data)
        this.blogs = response.data.results || []
      } catch (error) {
        console.error('获取博客收藏失败:', error)
        ElMessage.error('获取博客收藏列表失败')
      } finally {
        this.loading = false
      }
    },
    async fetchQuestionsData() {
      this.loading = true
      try {
        const token = localStorage.getItem('accessToken')
        const response = await axios.get(`${API_BASE_URL}/api/v1/qa/questions/my_collections/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        console.log('问答收藏数据:', response.data)
        this.questions = response.data.results || []
      } catch (error) {
        console.error('获取问答收藏失败:', error)
        ElMessage.error('获取问答收藏列表失败')
      } finally {
        this.loading = false
      }
    },
    viewTool(id) {
      const routePath = `/tool/${id}`
      window.open(routePath, '_blank')
    },
    viewBlog(id) {
      const routePath = `/blog/${id}`
      window.open(routePath, '_blank')
    },
    viewQuestion(id) {
      const routePath = `/qa/${id}`
      window.open(routePath, '_blank')
    },
    async cancelCollection(id) {
      try {
        const token = localStorage.getItem('accessToken')
        await axios.post(`${API_BASE_URL}/api/v1/tools/tools/${id}/toggle_collection/`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        ElMessage.success('取消收藏成功')
        this.fetchToolsData()
      } catch (error) {
        console.error('取消工具收藏失败:', error)
        ElMessage.error('取消收藏失败')
      }
    },
    async cancelBlogCollection(id) {
      try {
        const token = localStorage.getItem('accessToken')
        await axios.post(`${API_BASE_URL}/api/v1/blog/blogs/${id}/toggle_collection/`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        ElMessage.success('取消收藏成功')
        this.fetchBlogsData()
      } catch (error) {
        console.error('取消博客收藏失败:', error)
        ElMessage.error('取消收藏失败')
      }
    },
    async cancelQuestionCollection(id) {
      try {
        const token = localStorage.getItem('accessToken')
        await axios.post(`${API_BASE_URL}/api/v1/qa/questions/${id}/toggle_collection/`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        ElMessage.success('取消收藏成功')
        this.fetchQuestionsData()
      } catch (error) {
        console.error('取消问答收藏失败:', error)
        ElMessage.error('取消收藏失败')
      }
    },
    updateTableLayout() {
      this.$nextTick(() => {
        const tables = document.querySelectorAll('.el-table__inner-wrapper');
        tables.forEach(table => {
          if (table.__vue__?.layout?.updateElsHeight) {
            table.__vue__.layout.updateElsHeight();
          }
        });
      });
    },
    handleResizeObserverError() {
      const resizeObserverError = console.error;
      console.error = (...args) => {
        if (args[0]?.toString().includes('ResizeObserver')) {
          return;
        }
        resizeObserverError.apply(console, args);
      };
    }
  },
  watch: {
    activeTab: {
      handler() {
        this.$nextTick(() => {
          this.fetchData();
          this.updateTableLayout();
        });
      }
    }
  },
  created() {
    this.handleResizeObserverError();
  },
  mounted() {
    this.fetchData();
    window.addEventListener('resize', this.updateTableLayout);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateTableLayout);
  }
}
</script>

<style scoped>
.favorites-container {
  padding: 20px;
  background-color: var(--el-bg-color);
  min-height: calc(100vh - 60px);
}

/* 表格样式优化 */
:deep(.el-table) {
  --el-table-border-color: var(--el-border-color-lighter);
  border: 1px solid var(--el-table-border-color);
  border-radius: 4px;
}

:deep(.el-table__header-wrapper) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

:deep(.el-table__header) {
  background-color: var(--el-fill-color-light);
}

/* 统计信息样式 */
.stats-wrapper {
  display: flex;
  gap: 16px;
}

.stat-item {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: var(--el-text-color-secondary);
}

/* 分页容器样式 */
.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

/* 标签页样式优化 */
:deep(.el-tabs__header) {
  margin-bottom: 20px;
}

:deep(.el-tabs__item) {
  font-size: 16px;
  padding: 0 20px;
}

:deep(.el-tabs__nav-wrap::after) {
  height: 1px;
}

/* 修改操作列的样式 */
.action-link {
  cursor: pointer;
  margin: 0 8px;
  text-decoration: none;
}

.action-link.primary {
  color: var(--el-color-primary);
}

.action-link.danger {
  color: var(--el-color-danger);
}

.action-link:hover {
  opacity: 0.8;
}

</style>