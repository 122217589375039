<template>
  <div>
    <h2>个人信息</h2>
    <p>这里显示个人信息（待实现具体功能）</p>
  </div>
</template>

<script setup>
// defineOptions({
//   name: 'PersonalInfoView'
// })
// 这里可以添加组件的逻辑
</script>

<script>
export default {
  name: 'PersonalInfoView'
}
</script>