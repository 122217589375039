<template>
  <!-- 电脑端导航 -->
  <el-menu
    :default-active="currentRoute"
    class="navbar desktop-nav"
    mode="horizontal"
    :ellipsis="false"
    router
  >
    <!-- Logo 部分 -->
    <el-menu-item index="/" class="logo-container">
      <div class="logo-wrapper">
        <el-icon class="logo-icon"><Tools /></el-icon>
        <span class="logo-text">GongJuYuan</span>
      </div>
    </el-menu-item>
    
    <!-- 导航链接部分 -->
    <div class="nav-links">
      <el-menu-item index="/">首页</el-menu-item>
      <el-menu-item index="/tool">工具</el-menu-item>
      <el-menu-item index="/blog">博客</el-menu-item>
      <el-menu-item index="/qa">问答</el-menu-item>
    </div>

    <!-- 右侧功能区 -->
    <div class="right-section">
      <div class="search-container">
        <el-input
          v-model="searchInput"
          placeholder="搜索"
          :prefix-icon="Search"
          :loading="searchLoading"
          @keyup.enter="handleSearch"
          class="search-input"
          clearable
        >
          <template #append>
            <el-button @click="handleSearch" :loading="searchLoading">
              <el-icon><Search /></el-icon>
            </el-button>
          </template>
        </el-input>
      </div>
      
      <!-- 用户相关按钮/信息 -->
      <div class="user-section">
        <template v-if="!isAuthenticated">
          <el-button type="primary" plain size="small" @click="handleLogin">登录</el-button>
          <el-button type="success" size="small" @click="handleRegister">注册</el-button>
        </template>
        
        <el-dropdown v-else>
          <span class="user-dropdown-link">
            <el-avatar 
              :key="userAvatar" 
              :size="32" 
              :src="userAvatar"
              @error="handleAvatarError"
            >
              {{ userName.charAt(0) }}
            </el-avatar>
            <span class="username">{{ userName }}</span>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleProfileClick">个人中心</el-dropdown-item>
              <el-dropdown-item @click="$router.push('/todo')">
                待办事项
              </el-dropdown-item>
              <el-dropdown-item @click="handleLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </el-menu>

  <!-- 手机端导航 -->
  <div class="mobile-nav">
    <div class="mobile-header">
      <el-button class="search-btn" @click="showSearch = true">
        <el-icon><Search /></el-icon>
      </el-button>
      
      <div class="logo-wrapper mobile-logo-wrapper" @click="$router.push('/')">
        <el-icon class="logo-icon"><Tools /></el-icon>
        <span class="logo-text">GongJuYuan</span>
      </div>
      
      <el-button class="menu-btn" @click="showMobileMenu = true">
        <el-icon><Menu /></el-icon>
      </el-button>
    </div>

    <!-- 移动端菜单抽屉 -->
    <el-drawer
      v-model="showMobileMenu"
      direction="rtl"
      size="65%"
      :with-header="false"
    >
      <div class="mobile-menu-content">
        <!-- 用户信息区域 -->
        <div class="mobile-user-section">
          <template v-if="isAuthenticated">
            <div class="mobile-user-info">
              <el-avatar 
                :size="50" 
                :src="userAvatar"
                @error="handleAvatarError"
              >
                {{ userName.charAt(0) }}
              </el-avatar>
              <span class="mobile-username">{{ userName }}</span>
            </div>
          </template>
          <template v-else>
            <div class="mobile-auth-buttons">
              <el-button type="primary" @click="handleLogin" class="auth-btn">登录</el-button>
              <el-button type="success" @click="handleRegister" class="auth-btn">注册</el-button>
            </div>
          </template>
        </div>

        <!-- 导航链接 -->
        <div class="mobile-nav-links">
          <el-menu
            :default-active="currentRoute"
            mode="vertical"
            router
            @select="showMobileMenu = false"
          >
            <el-menu-item index="/">
              <el-icon><House /></el-icon>
              <span>首页</span>
            </el-menu-item>
            <el-menu-item index="/tool">
              <el-icon><Tools /></el-icon>
              <span>工具</span>
            </el-menu-item>
            <el-menu-item index="/blog">
              <el-icon><Document /></el-icon>
              <span>博客</span>
            </el-menu-item>
            <el-menu-item index="/qa">
              <el-icon><QuestionFilled /></el-icon>
              <span>问答</span>
            </el-menu-item>
            
            <!-- 已登录用户的选项直接放在这里 -->
            <template v-if="isAuthenticated">
              <el-divider />
              <el-menu-item index="/profile">
                <el-icon><User /></el-icon>
                <span>个人中心</span>
              </el-menu-item>
              <el-menu-item index="/todo">
                <el-icon><Edit /></el-icon>
                <span>待办事项</span>
              </el-menu-item>
              <el-menu-item @click="handleLogout">
                <el-icon><SwitchButton /></el-icon>
                <span>退出登录</span>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
      </div>
    </el-drawer>

    <!-- 移动端搜索抽屉 -->
    <el-drawer
      v-model="showSearch"
      direction="ttb"
      size="auto"
      :with-header="false"
    >
      <div class="mobile-search-container">
        <el-input
          v-model="searchInput"
          placeholder="搜索"
          :prefix-icon="Search"
          :loading="searchLoading"
          @keyup.enter="handleMobileSearch"
          clearable
        >
          <template #append>
            <el-button @click="handleMobileSearch" :loading="searchLoading">
              搜索
            </el-button>
          </template>
        </el-input>
        
        <!-- 可以加搜索类型选择 -->
        <div class="search-type-selector">
          <el-radio-group v-model="searchType" size="small">
            <el-radio-button label="all">全部</el-radio-button>
            <el-radio-button label="tools">工具</el-radio-button>
            <el-radio-button label="blogs">博客</el-radio-button>
            <el-radio-button label="qa">问答</el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Search, Menu, House, Tools, Document, QuestionFilled, User, SwitchButton, Edit } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { searchService } from '@/api/search'

const route = useRoute();
const router = useRouter();
const userStore = useUserStore()

// 使用 storeToRefs 保持响应性
const { isAuthenticated, userInfo } = storeToRefs(userStore)

// 其他状态
const searchInput = ref('');
const searchType = ref('all');
const searchResults = ref([])
const searchLoading = ref(false)
const showMobileMenu = ref(false)
const showSearch = ref(false)

const currentRoute = computed(() => route.path);
const userName = computed(() => userInfo.value.username || '用户')
const DEFAULT_AVATAR = 'https://api.gongjuyuan.com/media/images/default_logo.png'
const UI_AVATARS_BASE_URL = 'https://ui-avatars.com/api/'

// 修改头像计算属性
const userAvatar = computed(() => {
  const avatar = userInfo.value.avatar
  if (!avatar || avatar === DEFAULT_AVATAR) {
    const params = new URLSearchParams({
      name: userName.value,
      background: '409EFF',
      color: 'fff',
      size: '128',
      bold: 'true'
    })
    return `${UI_AVATARS_BASE_URL}?${params.toString()}`
  }
  return avatar
})

// 在组件挂载时检查登录状态
onMounted(async () => {
  if (userStore.accessToken) {
    try {
      const isValid = await userStore.checkAuthStatus()
      if (!isValid) {
        await handleLogout()
      }
    } catch (error) {
      console.error('登录状态检查失败:', error)
      await handleLogout()
    }
  }
})

// 修改登录处理方法
const handleLogin = () => {
  showMobileMenu.value = false; // 关闭移动端菜单
  router.push({
    path: '/login',
    query: { redirect: route.fullPath }
  });
};

// 修改注册按钮的点击处理
const handleRegister = () => {
  showMobileMenu.value = false; // 关闭移动端菜单
  router.push('/register');
};

// 修改登出处理方法
const handleLogout = async () => {
  try {
    await userStore.logout()
    ElMessage.success('退出登录成功')
    
    // 如果当前页面需要认证，则跳转到首页
    if (route.meta.requiresAuth) {
      router.push('/')
    }
    
    // 关闭移动端菜单
    showMobileMenu.value = false
  } catch (error) {
    console.error('登出失败:', error)
    ElMessage.error('登出失败，请重试')
  }
}

// 修改个人中心点击处理方法
const handleProfileClick = () => {
  if (!isAuthenticated.value) {
    ElMessage.warning('请先登录')
    handleLogin()
    return
  }
  router.push('/profile')
  showMobileMenu.value = false // 关闭移动端菜单
}

// 修改头像加载错误处理
const handleAvatarError = () => {
  console.error('Avatar failed to load:', userAvatar.value)
  // 如果加载失败使用UI Avatars作为后备方案
  const params = new URLSearchParams({
    name: userName.value,
    background: '409EFF',
    color: 'fff',
    size: '128',
    bold: 'true'
  })
  userAvatar.value = `${UI_AVATARS_BASE_URL}?${params.toString()}`
}

// 处理搜索
const handleSearch = async () => {
  if (!searchInput.value.trim()) {
    ElMessage.warning('输入搜索关键词')
    return
  }

  searchLoading.value = true
  try {
    let response
    if (searchType.value === 'all') {
      response = await searchService.globalSearch(searchInput.value)
    } else {
      response = await searchService.searchByType(searchType.value, {
        query: searchInput.value,
        // 其他参数根据需要添加
      })
    }

    searchResults.value = response.data
    
    // 跳转到搜索结果页面
    router.push({
      path: '/search',
      query: {
        q: searchInput.value,
        type: searchType.value
      }
    })
    
    // 如果是移动端，关闭搜索抽屉
    showSearch.value = false
    
  } catch (error) {
    console.error('搜索失败:', error)
    ElMessage.error('搜索失败，请稍后重试')
  } finally {
    searchLoading.value = false
  }
}

// 处理移动端搜索
const handleMobileSearch = () => {
  handleSearch()
}
</script>

<style scoped>
.navbar {
  padding: 0 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.logo-container {
  padding: 0 20px;
  margin-right: 20px !important;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo-icon {
  font-size: 24px;
  color: #409EFF;
}

.logo-text {
  font-size: 18px;
  font-weight: bold;
  color: #303133;
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
}

/* 选：添加悬浮效果 */
.logo-wrapper:hover .logo-icon {
  transform: rotate(30deg);
  transition: transform 0.3s ease;
}

.logo-wrapper:hover .logo-text {
  color: #409EFF;
  transition: color 0.3s ease;
}

/* 确保在移动端也能正确显示 */
@media (max-width: 768px) {
  .logo-icon {
    font-size: 20px;
  }
  
  .logo-text {
    font-size: 16px;
  }
}

.nav-links {
  display: flex;
  align-items: center;
}

.right-section {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  width: 240px;
  transition: width 0.3s ease;
}

.user-section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-dropdown-link {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.user-dropdown-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.username {
  font-size: 14px;
  color: #606266;
}

/* 响应式设计 */
@media (max-width: 992px) {
  .search-input {
    width: 180px;
  }
  
  .username {
    display: none;
  }
}

@media (max-width: 768px) {
  .search-input {
    width: 140px;
  }
  
  .nav-links {
    gap: 10px;
  }
  
  .logo-container {
    padding: 0 10px;
    margin-right: 10px !important;
  }
}

@media (max-width: 576px) {
  .navbar {
    padding: 0 10px;
  }
  
  .search-container {
    display: none;
  }
}

/* 电脑端导航在移动端隐藏 */
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }
}

/* 移动端导航在电脑端隐藏 */
.mobile-nav {
  display: none;
}

@media (max-width: 768px) {
  .mobile-nav {
    display: block;
  }
  
  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .mobile-logo-wrapper {
    height: 30px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .mobile-header .logo-icon {
    font-size: 20px;
    color: #409EFF;
  }

  .mobile-header .logo-text {
    font-size: 16px;
    font-weight: bold;
    color: #303133;
  }

  .menu-btn,
  .search-btn {
    padding: 8px;
    border: none;
  }

  .mobile-menu-content {
    padding: 15px 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .mobile-user-section {
    margin-bottom: 15px;
    padding: 5px 0;
  }

  .mobile-user-info {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 0;
  }

  .mobile-username {
    font-size: 15px;
  }

  .mobile-auth-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 5px 15px;
    width: 100%;
  }

  .mobile-auth-buttons .auth-btn {
    width: 80%;
    height: 36px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-nav-links {
    flex: 1;
  }

  .mobile-nav-links :deep(.el-menu) {
    border-right: none;
  }

  .mobile-nav-links :deep(.el-menu-item) {
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
  }

  :deep(.el-divider--horizontal) {
    margin: 12px 0;
  }

  .mobile-nav-links :deep(.el-icon) {
    font-size: 18px;
  }
}

/* 添加一些基间距，避免内容被固定导航栏遮挡 */
@media (max-width: 768px) {
  body {
    padding-top: 56px; /* 移动端导航栏的高度 */
  }
}

.search-type-selector {
  margin-top: 15px;
  text-align: center;
}

.mobile-search-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* 确保搜索按钮在加载状态下也能对齐 */
:deep(.el-input-group__append) {
  padding: 0;
}

:deep(.el-input-group__append .el-button) {
  margin: 0;
  border: none;
  height: 32px;
  padding: 8px 15px;
}

.mobile-user-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.mobile-auth-buttons {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* 覆盖 Element Plus 的默认样式 */
.mobile-auth-buttons :deep(.el-button + .el-button) {
  margin-left: 0;  /* 移除默认的左边距 */
}

.auth-btn {
  width: 100% !important;
  height: 36px;
  font-size: 14px;
}
</style>
