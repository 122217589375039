<template>
  <div class="register-container">
    <el-card class="register-card">
      <h2 class="register-title">用户注册</h2>
      <el-form 
        :model="registerForm" 
        :rules="rules" 
        ref="registerFormRef" 
        label-position="top"
      >
        <el-form-item prop="username" label="用户名">
          <el-input v-model="registerForm.username" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item prop="email" label="邮箱">
          <el-input v-model="registerForm.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input v-model="registerForm.password" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="password2" label="确认密码">
          <el-input v-model="registerForm.password2" type="password" placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="captcha" label="验证码">
          <el-input 
            v-model="registerForm.captcha" 
            placeholder="请输入验证码"
            class="captcha-input"
          >
            <template #append>
              <div class="captcha-wrapper" @click="refreshCaptcha">
                <img v-if="captchaImage" :src="captchaImage" alt="验证码" class="captcha-image"/>
                <span v-else>加载中...</span>
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm" class="register-button">注册</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script setup>
import { ref, reactive, onUnmounted, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { API_BASE_URL } from '@/config' 
const router = useRouter();

const registerForm = reactive({
  username: '',
  email: '',
  password: '',
  password2: '',
  captcha: '',
});

const captchaImage = ref('');
const captchaKey = ref('');

const rules = {
  username: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
  ],
  email: [
    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 6, message: '密码长度不能小于 6 个字符', trigger: 'blur' }
  ],
  password2: [
    { required: true, message: '请再次输入密码', trigger: 'blur' },
    {
      validator: (rule, value, callback) => {
        if (value !== registerForm.password) {
          callback(new Error('两次输入密码不一致'));
        } else {
          callback();
        }
      },
      trigger: 'blur'
    }
  ],
  captcha: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { min: 4, max: 4, message: '验证码长度必须为4位', trigger: 'blur' }
  ]
};

const registerFormRef = ref(null);

onUnmounted(() => {
  if (registerFormRef.value) {
    registerFormRef.value.resetFields();
  }
});

const getCaptcha = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/captcha/generate/`);
    captchaImage.value = response.data.image;
    captchaKey.value = response.data.key;
  } catch (error) {
    console.error('获取验证码失败:', error);
    ElMessage.error('获取验证码失败，请刷新重试');
  }
};

const refreshCaptcha = () => {
  registerForm.captcha = '';
  getCaptcha();
};

onMounted(() => {
  getCaptcha();
});

const submitForm = async () => {
  if (!registerFormRef.value) return;
  
  registerFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/v1/user/register/`, {
          username: registerForm.username,
          email: registerForm.email,
          password: registerForm.password,
          password2: registerForm.password2,
          captcha_key: captchaKey.value,
          captcha_code: registerForm.captcha
        });
        console.log('注册成功:', response.data);
        ElMessage.success('注册成功！');
        router.push('/login');
      } catch (error) {
        console.error('注册失败:', error);
        if (error.response && error.response.data) {
          const errorMessages = Object.entries(error.response.data)
            .map(([key, value]) => `${key}: ${value.join(', ')}`)
            .join('; ');
          ElMessage.error(`注册失败: ${errorMessages}`);
          if (error.response.data.captcha) {
            refreshCaptcha();
          }
        } else {
          ElMessage.error('注册失败，请稍后重试');
        }
      }
    } else {
      console.log('表单验证失败');
      return false;
    }
  });
};
</script>

<style scoped>
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(50vh - 120px);
  padding: 20px;
  padding-top: 100px;    /* 电脑版顶部间距 */
  padding-bottom: -20px; /* 电脑版底部间距 */
}

.register-card {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.register-title {
  text-align: center;
  font-size: 24px;
  color: #303133;
  margin: 20px 0 30px;
}

/* 移动端样式 */
@media (max-width: 768px) {
  .register-container {
    min-height: calc(50vh - 60px);
    padding: 10px;
    padding-top: 100px;    /* 移动端顶部间距 */
    padding-bottom: -20px; /* 移动端底部间距 */
  }
}

.register-button {
  width: 100%;
}

.captcha-input :deep(.el-input-group__append) {
  padding: 0;
  background: #fff;
}

.captcha-wrapper {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.captcha-image {
  height: 30px;
  margin: 0 5px;
}
</style>
