<template>
  <div class="tool-page">
    <!-- 幻灯片部分放在外层容器外 -->
    <div class="featured-slider">
      <el-carousel 
        :interval="5000" 
        height="400px"
        indicator-position="none"
        @change="handleSlideChange"
      >
        <el-carousel-item v-for="item in carouselItems" :key="item.id">
          <div class="slider-container">
            <!-- 左侧内容区 -->
            <div class="content-side">
              <div class="post-info">
                <div class="post-category">{{ item.tool?.category?.name || '未分类' }}</div>
                <h2 class="post-title">{{ item.title }}</h2>
                <p class="post-desc">{{ item.description }}</p>
                <div class="post-meta">
                  <div class="meta-item">
                    <el-icon><Calendar /></el-icon>
                    <span>{{ formatDate(item.tool?.updated_at) }}</span>
                  </div>
                  <div class="meta-item">
                    <el-icon><View /></el-icon>
                    <span>{{ item.tool?.views || 0 }}阅读</span>
                  </div>
                </div>
                <el-button 
                  type="primary" 
                  class="read-more"
                  @click="handleCarouselClick(item)"
                >
                  查看详情
                </el-button>
              </div>
            </div>
            
            <!-- 右侧渐变背景区 -->
            <div class="gradient-side">
              <img 
                :src="item.tool?.images?.[0]?.image || '/images/default-tool-bg.jpg'" 
                :alt="item.title"
                class="tool-image"
              />
              <div class="pattern-overlay"></div>
              <div class="floating-shapes">
                <div class="shape shape-1"></div>
                <div class="shape shape-2"></div>
                <div class="shape shape-3"></div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    
    <!-- 工具页其他内容使用原有容器 -->
    <div class="tool-container">
      <el-row :gutter="30">
        <el-col :xs="24" :sm="24" :md="16" :lg="18" :xl="18">
         
          <!-- 优化工具搜索部分 -->
          <el-card class="tool-search" shadow="hover">
            <div class="search-container">
              <div class="search-title">工具搜索</div>
              <el-input 
                v-model.trim="searchForm.keyword" 
                placeholder="请输入关键词搜索" 
                clearable
                size="default"
                @keyup.enter="handleSearch"
                class="search-input"
              >
                <template #append>
                  <el-button @click="handleSearch">
                    <el-icon><Search /></el-icon>
                  </el-button>
                </template>
              </el-input>
            </div>
          </el-card>

          <!-- 工具列表 -->
          <div class="tool-list">
            <el-card 
              v-for="item in filteredTools" 
              :key="item.id" 
              class="tool-card" 
              shadow="hover"
            >
              <h3 class="tool-title">
                <a :href="`/tool/${item.id}`" target="_blank" rel="noopener noreferrer">
                  {{ item.title }}
                </a>
              </h3>
              <p class="tool-meta">
                <el-tag size="small" effect="plain">
                  {{ item.category?.name || '未分类' }}
                </el-tag>
                <span class="tool-date">
                  更新于: {{ item.updated_at || '未知时间' }}
                </span>
              </p>
              <p class="tool-description">{{ item.description || '暂无描述' }}</p>
              <div class="tool-stats">
                <span><el-icon><View /></el-icon> {{ item.views || 0 }}</span>
                <span><el-icon><Star /></el-icon> {{ item.likes_count || 0 }}</span>
              </div>
              <a 
                :href="`/tool/${item.id}`" 
                target="_blank" 
                rel="noopener noreferrer"
                class="view-detail-button"
              >
                查看详情
              </a>
            </el-card>
          </div>

          <!-- 分页 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalTools"
            :current-page="currentPage"
            :page-size="pageSize"
            @current-change="handlePageChange"
            class="pagination"
          ></el-pagination>
        </el-col>

        <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="6">
          <!-- 工具分类 -->
          <el-card class="sidebar" shadow="hover">
            <template #header>
              <div class="card-header">
                <span>工具分类</span>
              </div>
            </template>
            <el-tree 
              :data="categories" 
              :props="defaultProps"
              @node-click="handleCategoryClick"
              :highlight-current="true"
              :default-expand-all="false"
              node-key="id"
            ></el-tree>
          </el-card>

          <!-- 热门工具卡片 -->
          <el-card class="sidebar" shadow="hover">
              <template #header>
                  <div class="card-header">
                      <span>热门工具</span>
                  </div>
              </template>
              <ul class="hot-tools-list">
                  <li v-for="tool in hotTools" :key="tool.id">
                      <a 
                          :href="`/tool/${tool.id}`"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="hot-tool-link"
                      >
                          {{ tool.name }}
                      </a>
                      <span class="hot-tool-stats">
                          <el-icon><Star /></el-icon> {{ tool.collections }}
                      </span>
                  </li>
              </ul>
          </el-card>

          <!-- 广告区域 -->
          <el-card class="sidebar ad-space" shadow="hover">
            <template #header>
              <div class="card-header">
                <span>赞助商</span>
              </div>
            </template>
            <div class="ad-content">
              <!-- 这里放置广告内容 -->
              <img src="https://via.placeholder.com/300x200" alt="广告" class="ad-image">
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { View, Star, Search, Calendar } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { API_BASE_URL } from '@/config'

export default {
  name: 'ToolView',
  components: {
    View,
    Star,
    Search,
    Calendar
  },
  setup() {
    const route = useRoute()
    
    // 更新 featuredTool 数据
    const featuredTool = ref({})

    // 更新 recentTools 数据
    const recentTools = ref([])

    // 添加分页状态
    const currentPage = ref(1)
    const pageSize = 5 // 每页显示的工具数量
    const totalTools = ref(0) // 确保只声明一次
    const tool = ref({}) // 添加 tool 变量

    const API_URL = `${API_BASE_URL}/api/v1/tools/tools/`
    const CATEGORY_API_URL = `${API_BASE_URL}/api/v1/tools/categories/`

    // 从 API 获取工具数据
    const fetchTools = async (page = 1) => {
        try {
            const response = await axios.get(`${API_URL}?page=${page}`)
            const data = response.data
            recentTools.value = data.results
            featuredTool.value = data.results.find(tool => tool.is_featured) || {}
            totalTools.value = data.count
            
            // 只选择有图片的工具作为轮播项
            carouselItems.value = data.results
                .filter(tool => tool.images && tool.images.length > 0)
                .slice(0, 5) // 限制最多5个轮播项
                .map(tool => ({
                    id: tool.id,
                    title: tool.title,
                    description: tool.description,
                    tool: tool // 保存完整的工具数据
                }))
            
            console.log('轮播图数据:', carouselItems.value)
        } catch (error) {
            console.error('获取工具数据失败:', error)
        }
    }

    // 在 setup 中调用 fetchTools
    fetchTools(currentPage.value)

    // 在 setup 函数中修改 hotTools 的定义和获取方式
    const hotTools = ref([])

    // 添加获取热门工具的函数
    const fetchHotTools = async () => {
        try {
            const response = await axios.get(`${API_URL}`)
            const data = response.data
            
            // 获取所有工具并按收藏数排序
            const sortedTools = data.results.sort((a, b) => 
                (b.collection_count || 0) - (a.collection_count || 0)
            )
            
            // 只取前5个工具
            hotTools.value = sortedTools.slice(0, 5).map(tool => ({
                id: tool.id,
                name: tool.title,
                collections: tool.collection_count || 0
            }))
        } catch (error) {
            console.error('获取热门工具失败:', error)
        }
    }

    // 在 onMounted 中调用
    onMounted(() => {
        fetchAllCategories()
        fetchHotTools()
        if (route.params.id) {
            fetchToolDetails(route.params.id)
        }
    })

    const categories = ref([])

    // 只保获取所有分类的函数
    const fetchAllCategories = async () => {
        try {
            let url = CATEGORY_API_URL
            let allCategories = []
            
            while (url) {
                // 将 http 转换为 https
                const secureUrl = url.replace('http://', 'https://')
                const response = await axios.get(secureUrl)
                const data = response.data
                allCategories = [...allCategories, ...(data.results || [])]
                // 确保下一页链接也使用 https
                url = data.next ? data.next.replace('http://', 'https://') : null
            }
            
            categories.value = allCategories.map(category => ({
                id: category.id,
                label: category.name,
                children: category.children.map(child => ({
                    id: child.id,
                    label: child.name
                }))
            }))
            
            console.log('所有分类数据:', categories.value)
        } catch (error) {
            console.error('获取分类数据失败:', error)
            ElMessage.error('获取分类数据失败，请稍后重试')
        }
    }

    const defaultProps = {
      children: 'children',
      label: 'label'
    }

    const searchForm = reactive({
      keyword: ''
    })

    // 修改分类点击处理函数
    const handleCategoryClick = async (data, node) => {
        // 检查是否为二级分类
        if (node.parent && node.parent.level === 1) {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/v1/tools/categories/${data.id}/tools/`)
                const responseData = response.data
                
                // 确保数据格式一致性
                if (Array.isArray(responseData.results)) {
                    recentTools.value = responseData.results.map(tool => ({
                        ...tool,
                        category: { id: data.id, name: data.label },
                        views: tool.views || 0,
                        likes_count: tool.likes_count || 0,
                        updated_at: tool.updated_at || new Date().toISOString()
                    }))
                    totalTools.value = responseData.count
                } else {
                    console.error('返回数据格式不正确:', responseData)
                    ElMessage.error('数据格式错误')
                    return
                }
                
                currentPage.value = 1 // 重置页码
                ElMessage.success(`当前分类: ${data.label}`)
                
            } catch (error) {
                console.error('获取分类工具失败:', error)
                ElMessage.error('获取分类工具失败，请稍后重试')
            }
        }
    }

    // 修改搜索处理函数
    const handleSearch = () => {
      if (!searchForm.keyword.trim()) {
        ElMessage({
          message: '请输入搜索关键词',
          type: 'warning',
          duration: 2000
        })
        return
      }
      
      onSearch() // 调用原有的搜索函数
    }

    // 修改原有的搜索函数
    const onSearch = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/v1/tools/tools/search/?q=${encodeURIComponent(searchForm.keyword)}`
        )
        const data = response.data
        
        if (data.results.length === 0) {
          ElMessage({
            message: '未找到相关工具',
            type: 'info',
            duration: 2000
          })
        } else {
          recentTools.value = data.results
          totalTools.value = data.count
          currentPage.value = 1 // 重置页码
          
          ElMessage({
            message: `找到 ${data.count} 个相关工具`,
            type: 'success',
            duration: 2000
          })
        }
      } catch (error) {
        console.error('搜索失败:', error)
        ElMessage.error('搜索失败，请稍后重试')
      }
    }

    // 修改 carouselItems 的定义
    const carouselItems = ref([])

    // 分页处理函数
    const handlePageChange = (page) => {
        currentPage.value = page
        fetchTools(page) // 重新获取工具数据
    }

    // 根据工具 ID 获取工具详细信息的函数
    const fetchToolDetails = async (id) => {
        if (!id) return
        
        try {
            const response = await axios.get(`${API_URL}${id}/`)
            tool.value = response.data
        } catch (error) {
            console.error('获取工具详情失败:', error)
        }
    }

    // 格式化日期函
    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }
      const date = new Date(dateString)
      return date.toLocaleString('zh-CN', options).replace(/\//g, '-') // 替换斜杠为短横线
    }

    // 添加轮播图点击处理函数
    const handleCarouselClick = (item) => {
        window.open(`/tool/${item.id}`, '_blank')
    }

    // 添加计属性来过滤工具列表
    const filteredTools = computed(() => {
      return recentTools.value.filter(item => item != null)
    })

    const getGradientColor = (id) => {
      const gradients = [
        'linear-gradient(135deg, #6366f1 0%, #a855f7 100%)',
        'linear-gradient(135deg, #3b82f6 0%, #06b6d4 100%)',
        'linear-gradient(135deg, #14b8a6 0%, #22c55e 100%)'
      ]
      return gradients[id % gradients.length]
    }

    const currentIndex = ref(0)
    const handleSlideChange = (newIndex) => {
      currentIndex.value = newIndex
    }

    return {
      featuredTool,
      recentTools,
      hotTools,
      categories,
      defaultProps,
      searchForm,
      handleCategoryClick,
      handleSearch,
      carouselItems, // 添加轮播图数据到返回对象
      totalTools,
      currentPage,
      pageSize,
      handlePageChange,
      tool, // 返回 tool 变量
      formatDate,
      handleCarouselClick,
      filteredTools, // 添加到返回值中
      getGradientColor,
      currentIndex,
      handleSlideChange
    }
  },
}
</script>

<style scoped>
.tool-page {
  width: 100%;
}

.featured-slider {
  width: 100%;
  margin: 30px auto;
  overflow: hidden;  /* 确保超出部分被隐藏 */
}

.slider-container {
  display: flex;
  height: 100%;
  max-width: 1600px;  /* 增加最大宽度 */
  margin: 0 80px;  /* 使用负边距扩展容器 */
  position: relative;
}

.tool-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.page-title {
  font-size: 2.5em;
  color: #303133;
  text-align: center;
  margin-bottom: 40px;
}

.featured-tool, .tool-card, .tool-search {
  margin-bottom: 30px;
  transition: all 0.3s ease;
}

.featured-tool:hover, .tool-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tool-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.tool-date {
  font-size: 0.9em;
  color: #909399;
}

.tool-description {
  color: #606266;
  margin-bottom: 15px;
}

.tool-stats {
  display: flex;
  gap: 20px;
  color: #909399;
  margin-bottom: 10px;
}

.sidebar {
  margin-bottom: 30px;
}

.hot-tools-list {
  list-style-type: none;
  padding: 0;
}

.hot-tools-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.hot-tools-list li:last-child {
  border-bottom: none;
}

.hot-tool-link {
  color: #409EFF;
  text-decoration: none;
  flex: 1;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hot-tool-link:hover {
  color: #66b1ff;
  text-decoration: underline;
}

.hot-tool-stats {
  color: #909399;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 4px;
}

.pagination {
  text-align: center;
  margin-top: 30px;
}

.ad-space {
  text-align: center;
}

.ad-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .page-title {
    font-size: 2em;
  }
}

/* 添加轮播图样式 */
.tool-carousel {
  margin-bottom: 40px;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-title {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.tool-title {
  font-size: 1.5em; /* 设置标题字体大小 */
  color: #409EFF; /* 设置标题颜色 */
  text-decoration: none; /* 去掉下划线 */
}

.tool-title a {
  color: inherit; /* 继承父元素颜色 */
  text-decoration: none; /* 去掉下划线 */
}

.tool-title a:hover {
  text-decoration: underline; /* 标悬停时添加下划线 */
}

.view-detail-button {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #409EFF; /* 按钮背景颜色 */
  color: white; /* 按钮文字色 */
  border-radius: 4px; /* 按钮圆角 */
  text-decoration: none; /* 去掉下划线 */
}

.view-detail-button:hover {
  background-color: #66b1ff; /* 鼠标悬停时按钮颜色 */
}

/* 添加样式 */
.carousel-item {
  cursor: pointer; /* 添加鼠标手型 */
}

.carousel-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  color: white;
}

.carousel-title {
  margin: 0;
  font-size: 1.5em;
}

.carousel-description {
  margin: 10px 0 0;
  font-size: 1em;
}

/* 更新搜索相关样式 */
.tool-search {
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.search-title {
  font-size: 16px;
  font-weight: 500;
  color: #303133;
  white-space: nowrap;
}

.search-input {
  flex: 1;
  max-width: 400px;
}

.search-input :deep(.el-input__wrapper) {
  border-radius: 4px;
}

.search-input :deep(.el-input__inner)::placeholder {
  color: #909399;
}

/* 添加搜索按钮hover效果 */
.search-input :deep(.el-input-group__append .el-button:hover) {
  background-color: #409EFF;
  color: white;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .search-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .search-input {
    max-width: 100%;
  }
}

/* 添加分类相关样式 */
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar :deep(.el-tree-node__content) {
    height: 32px;
}

.sidebar :deep(.el-tree-node__content:hover) {
    background-color: #f5f7fa;
}

.sidebar :deep(.el-tree-node.is-current > .el-tree-node__content) {
    background-color: #ecf5ff;
    color: #409EFF;
}

/* 可选：添加鼠标样式，只在二级分类上显示指针 */
.sidebar :deep(.el-tree-node[level="2"] > .el-tree-node__content) {
    cursor: pointer;
}

/* 复制博客页面的幻灯片样式 */
.featured-slider {
  margin: 40px 0;
}

.slider-container {
  display: flex;
  height: 100%;
  background: #fff;
}

.content-side {
  flex: 0.8;  /* 减小内容区域的比例 */
  padding: 80px 60px 80px 120px;  /* 调整内边距，增加左侧空间 */
  display: flex;
  align-items: center;
  z-index: 2;
  transform: translateX(-80px);  /* 向左移动内容区 */
}

.post-category {
  display: inline-block;
  padding: 6px 16px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
  border-radius: 20px;
  font-size: 14px;
  margin-bottom: 20px;
}

.post-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.3;
  color: #1a1a1a;
}

.post-desc {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 30px;
}

.post-meta {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #999;
  font-size: 14px;
}

.read-more {
  width: fit-content;
  padding: 12px 30px;
  border-radius: 25px;
  font-size: 16px;
}

.gradient-side {
  flex: 1.2;  /* 增加图片区域的比例 */
  position: relative;
  overflow: hidden;
  transform: translateX(80px);  /* 向右移动图片区 */
}

.tool-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 添加图片悬停效果 */
.gradient-side:hover .tool-image {
  transform: scale(1.05);
}

/* 调整遮罩层样式，增加渐变效果 */
.pattern-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: -80px;  /* 扩展遮罩层 */
  bottom: 0;
  background: linear-gradient(
    to right,
    var(--el-bg-color) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  z-index: 1;
}

.floating-shapes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.shape {
  position: absolute;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.shape-1 {
  width: 100px;
  height: 100px;
  top: 20%;
  left: 20%;
  animation: float 6s ease-in-out infinite;
}

.shape-2 {
  width: 150px;
  height: 150px;
  top: 60%;
  right: 20%;
  animation: float 8s ease-in-out infinite;
}

.shape-3 {
  width: 80px;
  height: 80px;
  bottom: 20%;
  left: 40%;
  animation: float 7s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .slider-container {
    margin: 0;
    flex-direction: column-reverse;
  }
  
  .content-side,
  .gradient-side {
    transform: none;
    width: 100%;
  }
  
  .content-side {
    padding: 30px;
  }
  
  .pattern-overlay {
    right: 0;
  }
}

/* 确保左侧内容在图片上层显示 */
.content-side {
  position: relative;
  z-index: 3;
  background: rgba(255, 255, 255, 0.9);
}
</style>
