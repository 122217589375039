<template>
  <!-- 顶级评论 -->
  <div class="comment">
    <div class="comment-info">
      <el-avatar :src="getAvatarUrl(comment.user)" size="small" />
      <span class="comment-author">{{ comment.user.username }}</span>
      <span class="comment-date">{{ formatDate(comment.created_at) }}</span>
      <div class="comment-actions">
        <div class="action-buttons">
          <el-button 
            v-if="isLoggedIn" 
            type="primary" 
            plain
            size="small"
            @click="handleReply(comment)"
          >
            <el-icon><ChatLineRound /></el-icon>
            回复
          </el-button>
          <el-button 
            v-if="canDelete(comment)" 
            type="danger" 
            plain
            size="small"
            @click="$emit('delete', comment)"
          >
            <el-icon><Delete /></el-icon>
            删除
          </el-button>
          <el-button 
            type="primary"
            plain
            size="small"
            @click="handleLike(comment)"
            :class="{ 'is-liked': isLoggedIn && comment.is_liked }"
          >
            <el-icon>
              <ThumbUpFilled v-if="isLoggedIn && comment.is_liked" />
              <ThumbUp v-else />
            </el-icon>
            {{ isLoggedIn && comment.is_liked ? '已点赞' : '点赞' }} {{ comment.likes_count }}
          </el-button>
        </div>
      </div>
    </div>
    
    <div class="comment-content">{{ comment.text }}</div>
    
    <!-- 顶级评论的回复框 -->
    <div v-if="showReplyBox && activeReplyId === comment.id" class="reply-form">
      <el-input
        v-model="replyContent"
        type="textarea"
        :rows="2"
        :placeholder="`回复 @${comment.user.username}：`"
      />
      <div class="reply-actions">
        <el-button size="small" @click="hideReplyBox">取消</el-button>
        <el-button 
          type="primary" 
          size="small" 
          @click="submitReply(comment)"
        >提交回复</el-button>
      </div>
    </div>
  </div>

  <!-- 将所有子评论平铺展示 -->
  <div v-if="flattenedReplies.length > 0" class="child-comments">
    <div 
      v-for="reply in flattenedReplies" 
      :key="reply.id"
      class="comment child-comment"
    >
      <div class="comment-header">
        <div class="comment-info">
          <el-avatar :src="getAvatarUrl(reply.user)" size="small" />
          <span class="comment-author">{{ reply.user.username }}</span>
          <span class="reply-to" v-if="reply.parent_user">
            回复 @{{ reply.parent_user.username }}
          </span>
          <span class="comment-date">{{ formatDate(reply.created_at) }}</span>
          <div class="comment-actions">
            <div class="action-buttons">
              <el-button 
                v-if="isLoggedIn" 
                type="primary"
                plain
                size="small"
                @click="handleReply(reply)"
              >
                <el-icon><ChatLineRound /></el-icon>
                回复
              </el-button>
              <el-button 
                v-if="canDelete(reply)" 
                type="danger"
                plain
                size="small"
                @click="$emit('delete', reply)"
              >
                <el-icon><Delete /></el-icon>
                删除
              </el-button>
              <el-button 
                type="primary"
                plain
                size="small"
                @click="handleLike(reply)"
                :class="{ 'is-liked': isLoggedIn && reply.is_liked }"
              >
                <el-icon>
                  <ThumbUpFilled v-if="isLoggedIn && reply.is_liked" />
                  <ThumbUp v-else />
                </el-icon>
                {{ isLoggedIn && reply.is_liked ? '已点赞' : '点赞' }} {{ reply.likes_count }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
      
      <div class="comment-content">{{ reply.text }}</div>
      
      <!-- 子评论的回复框 -->
      <div v-if="showReplyBox && activeReplyId === reply.id" class="reply-form">
        <el-input
          v-model="replyContent"
          type="textarea"
          :rows="2"
          :placeholder="`回复 @${reply.user.username}：`"
        />
        <div class="reply-actions">
          <el-button size="small" @click="hideReplyBox">取消</el-button>
          <el-button 
            type="primary" 
            size="small" 
            @click="submitReply(reply)"
          >提交回复</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { 
  ChatLineRound, 
  Delete, 
  ThumbUp,
  ThumbUpFilled
} from '@element-plus/icons-vue'
import { useUserStore } from '@/stores/user'

// 定义默认头像常量
const DEFAULT_AVATAR = 'https://api.gongjuyuan.com/media/images/default_logo.png'
const UI_AVATARS_BASE_URL = 'https://ui-avatars.com/api/'

export default {
  name: 'CommentTree',
  components: {
    ChatLineRound,
    Delete,
    ThumbUp,
    ThumbUpFilled
  },
  props: {
    comment: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Function,
      required: true
    }
  },
  setup(props, { emit }) {
    const showReplyBox = ref(false)
    const replyContent = ref('')
    const activeReplyId = ref(null)
    const userStore = useUserStore()

    const handleReply = (targetComment) => {
      if (!userStore.isAuthenticated) {
        ElMessage.warning('请先登录后再回复')
        return
      }
      showReplyBox.value = true
      activeReplyId.value = targetComment.id
      replyContent.value = ''
    }

    const hideReplyBox = () => {
      showReplyBox.value = false
      activeReplyId.value = null
      replyContent.value = ''
    }

    const submitReply = (targetComment) => {
      if (!userStore.isAuthenticated) {
        ElMessage.warning('请先登录后再回复')
        return
      }

      if (!replyContent.value.trim()) {
        ElMessage.warning('回复内容不能为空')
        return
      }
      
      emit('reply', targetComment, replyContent.value.trim())
      hideReplyBox()
    }

    const formatDate = (dateString) => {
      const date = new Date(dateString)
      return date.toLocaleString()
    }

    const getAvatarUrl = (user) => {
      if (!user.avatar_url || user.avatar_url === DEFAULT_AVATAR) {
        // 如果没有头像或是默认头像，使用 UI Avatars
        const params = new URLSearchParams({
          name: user.username,
          background: '409EFF',
          color: 'fff',
          size: '128',
          bold: 'true'
        })
        return `${UI_AVATARS_BASE_URL}?${params.toString()}`
      }
      return user.avatar_url
    }

    const flattenReplies = (comment) => {
      let replies = []
      if (comment.replies) {
        comment.replies.forEach(reply => {
          replies.push(reply)
          if (reply.replies) {
            replies = replies.concat(flattenReplies(reply))
          }
        })
      }
      return replies
    }

    const flattenedReplies = computed(() => {
      return flattenReplies(props.comment)
    })

    const handleLike = async (comment) => {
      if (!userStore.isAuthenticated) {
        ElMessage.warning('请先登录后再点赞')
        return
      }

      try {
        await emit('like', comment)
      } catch (error) {
        console.error('点赞操作失败:', error)
        ElMessage.error('点赞失败，请稍后重试')
      }
    }

    const isLoggedIn = computed(() => userStore.isAuthenticated)

    return {
      showReplyBox,
      replyContent,
      activeReplyId,
      handleReply,
      hideReplyBox,
      submitReply,
      formatDate,
      getAvatarUrl,
      flattenedReplies,
      handleLike,
      isLoggedIn
    }
  }
}
</script>

<style scoped>
/* 统一的评论样式 */
.comment {
  margin-bottom: 12px;
  padding: 16px;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 8px;
}

/* 子评论的缩进 */
.child-comment {
  margin-left: 48px;
}

/* 评论信息布局 */
.comment-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

/* 用户信息样式 */
.comment-author {
  font-weight: 500;
  font-size: 14px;
  color: #333;
}

.reply-to {
  color: #8590a6;
  font-size: 14px;
}

.comment-date {
  color: #8590a6;
  font-size: 12px;
}

/* 评论内容样式 */
.comment-content {
  margin: 12px 0;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  padding-left: 36px; /* 内容缩进，与头像对齐 */
}

/* 操作按钮样式 */
.comment-actions {
  margin-left: auto;
}

.action-buttons {
  display: inline-flex;
}

.action-buttons .el-button {
  margin: 0 !important; /* 覆盖 element-plus 的默认边距 */
  border-right: none;  /* 移除右边框 */
}

.action-buttons .el-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.action-buttons .el-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-right: 1px solid #dcdfe6; /* 为最后一个按钮添加右边框 */
}

.action-buttons .el-button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

/* 当按钮被hover时显示边框 */
.action-buttons .el-button:hover {
  border-right: 1px solid #dcdfe6;
  margin-right: -1px !important;
  z-index: 1;
}

/* 已点赞状态的样式 */
.is-liked {
  background-color: #ecf5ff !important;
  border-color: #409eff !important;
  color: #409eff !important;
}

.is-liked:hover {
  color: #f56c6c !important;
  border-color: #f56c6c !important;
  background-color: #fef0f0 !important;
}

/* 点赞数量样式 */
.likes-count {
  font-size: 12px;
  margin-left: 4px;
  color: inherit;
}

/* 回复框样式 */
.reply-form {
  margin-top: 12px;
  padding: 12px;
  padding-left: 36px; /* 与评论内容对齐 */
  border-top: 1px solid #eee;
}

.reply-actions {
  margin-top: 12px;
  text-align: right;
  gap: 8px;
}

/* 头像样式 */
.el-avatar {
  border: 1px solid #eee;
}

/* 动画效果 */
.is-liked .el-icon {
  animation: like-animation 0.3s ease;
}

@keyframes like-animation {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

/* 子评论容器 */
.child-comments {
  margin-top: 12px;
}

/* 添加点赞按钮的禁用状态样式 */
.el-button.is-disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
</style>
