<template>
  <!-- 幻灯片部分 -->
  <div class="featured-slider">
    <el-carousel 
      :interval="5000" 
      height="400px"
      indicator-position="none"
      @change="handleSlideChange"
    >
      <el-carousel-item v-for="post in featuredPosts" :key="post.id">
        <div class="slider-container">
          <!-- 左侧内容区 -->
          <div class="content-side">
            <div class="post-info">
              <div class="post-category">{{ post.category?.name || '未分类' }}</div>
              <h2 class="post-title">{{ post.title }}</h2>
              <p class="post-desc">{{ post.description }}</p>
              <div class="post-meta">
                <div class="meta-item">
                  <el-icon><Calendar /></el-icon>
                  <span>{{ post.date }}</span>
                </div>
                <div class="meta-item">
                  <el-icon><View /></el-icon>
                  <span>2.5k阅读</span>
                </div>
              </div>
              <el-button 
                type="primary" 
                class="read-more"
                @click="goToBlogContent(post.id)"
              >
                阅读全文
              </el-button>
            </div>
          </div>
          
          <!-- 右侧改为封面图 -->
          <div class="cover-side" :style="{ backgroundImage: `url(${post.cover})` }">
            <div class="cover-overlay"></div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>

  <!-- 博客主体内容 -->
  <div class="blog-container">
    <el-row :gutter="30">
      <!-- 左侧主内容区 -->
      <el-col :span="16">
        <!-- 搜索框 -->
        <el-card class="search-box">
          <el-input
            v-model="searchQuery"
            placeholder="搜索文章..."
            class="search-input"
            :prefix-icon="Search"
            clearable
          >
            <template #append>
              <el-button :icon="Search" @click="handleSearch">搜索</el-button>
            </template>
          </el-input>
        </el-card>

        <!-- 文章列表 -->
        <el-card 
          v-for="post in recentPosts" 
          :key="post.id" 
          class="post-card"
          shadow="hover"
        >
          <div class="post-content">
            <div class="post-header">
              <el-tag v-if="post.category" size="small" effect="plain">
                {{ post.category.name }}
              </el-tag>
              <el-tag v-else size="small" effect="plain">未分类</el-tag>
            </div>
            <h3 class="post-title" @click="goToBlogContent(post.id)">{{ post.title }}</h3>
            <p class="post-desc">{{ post.description }}</p>
            <div class="post-meta">
              <el-space wrap :size="20">
                <el-text class="text-muted">
                  <el-icon><Calendar /></el-icon>
                  {{ post.date }}
                </el-text>
                <el-text class="text-muted">
                  <el-icon><View /></el-icon>
                  <span>2.5k 阅读</span>
                </el-text>
              </el-space>
            </div>
          </div>
        </el-card>

        <!-- 分页 -->
        <div class="pagination-container">
          <el-pagination
            v-if="totalPosts > 0"
            background
            layout="total, sizes, prev, pager, next"
            :total="totalPosts"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 50]"
            :current-page="currentPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-col>

      <!-- 右侧边栏 -->
      <el-col :span="8">
        <!-- 最新文章 -->
        <el-card class="sidebar-card">
          <template #header>
            <div class="card-header">
              <span>最新文章</span>
            </div>
          </template>
          <div 
            v-for="post in latestPosts" 
            :key="post.id" 
            class="recent-post-item"
            @click="goToBlogContent(post.id)"
          >
            <div class="recent-info">
              <el-text class="recent-title" truncated>{{ post.title }}</el-text>
              <div class="recent-meta">
                <el-text type="info" size="small">
                  <el-icon><Calendar /></el-icon>
                  {{ post.date }}
                </el-text>
              </div>
            </div>
          </div>
        </el-card>

        <!-- 标签云 -->
        <el-card class="sidebar-card">
          <template #header>
            <div class="card-header">
              <span>标签云</span>
            </div>
          </template>
          <div class="tag-cloud">
            <el-tag
              v-for="tag in tags"
              :key="tag.name"
              :type="tag.type"
              effect="light"
              class="tag-item"
              @click="filterByTag(tag.name)"
            >
              {{ tag.name }}
              <template #suffix>
                <el-text class="tag-count" type="info">{{ tag.count }}</el-text>
              </template>
            </el-tag>
          </div>
        </el-card>

        <!-- 广告位 -->
        <el-card class="sidebar-card ad-card">
          <el-image src="/ads/sample-ad.jpg" fit="cover" />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { Calendar, View, Search } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { API_BASE_URL } from '@/config'

const router = useRouter()
const searchQuery = ref('')
const currentPage = ref(1)
const pageSize = ref(10)
const totalPosts = ref(0)
const recentPosts = ref([])

// 幻灯片数据
const featuredPosts = ref([])
const currentIndex = ref(0)

// 修改计算属性，改为正序显示最新的5篇文章
const latestPosts = computed(() => {
  return recentPosts.value
    .slice() // 创建数组副本
    .sort((a, b) => new Date(b.date) - new Date(a.date)) // 先按日期倒序排序获取最新的
    .slice(0, 5) // 取前5篇
    .reverse() // 将结果反转为正序
})

// 获取博客数据
const fetchBlogData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/blog/blogs/`, {
      params: {
        page: currentPage.value,
        page_size: pageSize.value
      }
    })
    const data = response.data

    totalPosts.value = data.count
    const blogs = data.results

    if (blogs.length > 0) {
      // 修改筛选逻辑，确保只选择 cover 不为 null 的文章
      const postsWithCover = blogs.filter(blog => blog.cover !== null && blog.cover !== '')
      
      // 如果有带封面的文章，取前5篇
      if (postsWithCover.length > 0) {
        featuredPosts.value = postsWithCover
          .slice(0, 5)
          .map(blog => ({
            id: blog.id,
            title: blog.title,
            category: blog.category,
            date: new Date(blog.created_at).toLocaleDateString(),
            description: blog.description || '暂无描述',
            cover: blog.cover
          }))
      } else {
        // 如果没有带封面的文章，不显示幻灯片或使用默认封面
        featuredPosts.value = []
      }

      // 处理文章列表
      recentPosts.value = blogs.map(blog => ({
        id: blog.id,
        title: blog.title,
        category: blog.category,
        date: new Date(blog.created_at).toLocaleDateString(),
        is_top: blog.is_top,
        is_featured: blog.is_featured,
        is_highlighted: blog.is_highlighted,
        level: blog.level,
        description: blog.description || '暂无描述'
      }))
    }
  } catch (error) {
    console.error('获取博客数据失败:', error)
    ElMessage.error('获取博客数据失败，请稍后重试')
  }
}

// 页面跳转
const goToBlogContent = (blogId) => {
  router.push(`/blog/${blogId}`)
}

// 分页处理
const handleSizeChange = (newSize) => {
  pageSize.value = newSize
  currentPage.value = 1
  fetchBlogData()
}

const handleCurrentChange = (newPage) => {
  currentPage.value = newPage
  fetchBlogData()
}

// 搜索处理
const handleSearch = () => {
  console.log('搜索:', searchQuery.value)
  // 实现搜索逻辑
}

// 幻灯片切换处理
const handleSlideChange = (index) => {
  currentIndex.value = index
}

onMounted(fetchBlogData)
</script>

<style scoped>
.featured-slider {
  width: 100%;
  margin: 30px auto;
}

.slider-container {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.content-side {
  flex: 0.8;
  padding: 80px;
  background: var(--el-bg-color);
  display: flex;
  align-items: center;
  z-index: 2;
}

.cover-side {
  flex: 1.2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  transform: translateX(-80px);
  overflow: hidden;
}

.cover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    var(--el-bg-color) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.post-info {
  max-width: 500px;
}

.post-category {
  display: inline-block;
  padding: 4px 12px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
  border-radius: 15px;
  font-size: 14px;
  margin-bottom: 16px;
}

.post-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 16px;
  color: var(--el-text-color-primary);
}

.post-desc {
  color: var(--el-text-color-secondary);
  margin-bottom: 24px;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post-meta {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--el-text-color-secondary);
}

.read-more {
  padding: 12px 30px;
  border-radius: 25px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .slider-container {
    flex-direction: column-reverse;
  }

  .content-side {
    flex: 1;
    padding: 30px;
  }

  .cover-side {
    height: 200px;
  }

  .cover-overlay {
    background: linear-gradient(
      to bottom,
      transparent 0%,
      var(--el-bg-color) 100%
    );
  }

  .post-title {
    font-size: 24px;
  }
}

.blog-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

/* 文章卡片样式 */
.post-card {
  margin-bottom: 20px;
  transition: transform 0.3s;
}

.post-card:hover {
  transform: translateY(-5px);
}


.post-header {
  margin-bottom: 16px;
}

.post-category {
  display: inline-block;
  padding: 4px 12px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
  border-radius: 15px;
  font-size: 14px;
}

.post-title {
  font-size: 1.5em;
  margin: 10px 0;
  cursor: pointer;
  color: var(--el-text-color-primary);
}

.post-title:hover {
  color: var(--el-color-primary);
}

.post-summary {
  color: var(--el-text-color-secondary);
  margin-bottom: 16px;
  line-height: 1.6;
}

.post-meta {
  margin-top: 16px;
}

.post-tags {
  display: inline-flex;
  gap: 8px;
}

/* 确保图片样式正确 */
:deep(.post-summary img) {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 10px 0;
}

.text-muted {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.text-muted .el-icon {
  margin-right: 4px;
}

/* 确保图标和文字对齐 */
.text-muted span {
  vertical-align: middle;
}

/* 侧边栏样式 */
.sidebar-card {
  margin-bottom: 20px;
}

.card-header {
  font-weight: bold;
}

.recent-post-item {
  padding: 12px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--el-border-color-lighter);
  transition: background-color 0.3s;
}

.recent-post-item:last-child {
  border-bottom: none;
}

.recent-post-item:hover {
  background-color: var(--el-fill-color-light);
}

.recent-title {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 8px;
  display: block;
}

.recent-meta {
  display: flex;
  gap: 16px;
  font-size: 12px;
  color: var(--el-text-color-secondary);
}

.recent-meta .el-icon {
  margin-right: 4px;
}

/* 标签云样式 */
.tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag-item {
  cursor: pointer;
  transition: all 0.3s;
}

.tag-item:hover {
  transform: scale(1.05);
}

.tag-count {
  margin-left: 4px;
  font-size: 12px;
}

/* 搜索框样式 */
.search-box {
  margin-bottom: 20px;
}

/* 分页容器样式 */
.pagination-container {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .el-col-16, .el-col-8 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .post-title {
    font-size: 1.2em;
  }
}

/* 添加浮动形状 */
.cover-side {
  flex: 1.2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  transform: translateX(-80px);
  overflow: hidden;
}

/* 添加浮动形状容器 */
.floating-shapes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/* 定义浮动形状 */
.shape {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.cover-side::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, transparent 60%);
  animation: pulse 4s ease-in-out infinite;
  z-index: 1;
}

/* 添加动画关键帧 */
@keyframes pulse {
  0%, 100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

/* 图片悬停效果 */
.cover-side {
  transition: transform 0.3s ease-in-out;
}

.slider-container:hover .cover-side {
  transform: translateX(-80px) scale(1.02);
}

/* 内容区域动画 */
.content-side {
  transition: transform 0.3s ease-out;
}

.slider-container:hover .content-side {
  transform: translateX(10px);
}

/* 标题动画 */
.post-title {
  transition: transform 0.3s ease-out;
}

.slider-container:hover .post-title {
  transform: translateX(5px);
}

/* 按钮悬停效果 */
.read-more {
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.read-more::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: left 0.3s ease;
}

.read-more:hover::after {
  left: 100%;
}
</style>