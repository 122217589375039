<template>
  <div class="publish-blog-container">
    <el-row :gutter="20">
      <el-col :span="16">
        <h2>发布博客</h2>
        <el-form :model="blogForm" :rules="blogRules" ref="blogFormRef" label-width="100px">
          <el-form-item label="标题" prop="title">
            <el-input v-model="blogForm.title"></el-input>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <editor
              v-model="blogForm.content"
              :init="tinymceInit"
              api-key="237kbwdvtifkc7jpu32ukserssrcfsfypt9o63zgaxskmeb4"
            />
          </el-form-item>
          <el-form-item label="分类" prop="category">
            <el-cascader
              v-model="blogForm.category"
              :options="categoryOptions"
              :props="{ 
                checkStrictly: true,
                emitPath: false
              }"
              clearable
              placeholder="请选择分类"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input type="textarea" v-model="blogForm.description" rows="3"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha">
            <el-input 
              v-model="blogForm.captcha" 
              placeholder="请输入验证码"
              class="captcha-input"
            >
              <template #append>
                <div class="captcha-wrapper" @click="refreshCaptcha">
                  <img v-if="captchaImage" :src="captchaImage" alt="验证码" class="captcha-image"/>
                  <span v-else>加载中...</span>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitBlog">发布博客</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="8">
        <h2>分类管理</h2>
        <el-form :model="categoryForm" :rules="categoryRules" ref="categoryFormRef" label-width="100px">
          <el-form-item label="分类名称" prop="name">
            <el-input v-model="categoryForm.name"></el-input>
          </el-form-item>
          <el-form-item label="父分类">
            <el-cascader
              v-model="categoryForm.parent"
              :options="categoryOptions"
              :props="{ checkStrictly: true }"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitCategory">添加分类</el-button>
          </el-form-item>
        </el-form>
        <el-tree
          :data="categories"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
        >
          <template #default="{ node, data }">
            <span class="custom-tree-node">
              <span>{{ node.label }}</span>
              <span>
                <el-button link type="primary" size="small" @click="editCategory(data)">
                  编辑
                </el-button>
                <el-button link type="danger" size="small" @click="deleteCategory(node, data)">
                  删除
                </el-button>
              </span>
            </span>
          </template>
        </el-tree>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import axios from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus';
import Editor from '@tinymce/tinymce-vue';
import { useRouter } from 'vue-router';

import { API_BASE_URL } from '@/config' 
export default {
  name: 'PublishBlogView',
  components: {
    Editor
  },
  setup() {
    const blogFormRef = ref(null);
    const categoryFormRef = ref(null);
    const categories = ref([]);
    const categoryOptions = ref([]);
    const captchaImage = ref('');
    const captchaKey = ref('');
    const router = useRouter();

    const blogForm = reactive({
      title: '',
      content: '',
      category: null,
      description: '', // 添加描述字段
      captcha: ''
    });

    const categoryForm = reactive({
      name: '',
      parent: null
    });

    const blogRules = {
      title: [{ required: true, message: '输入博客标题', trigger: 'blur' }],
      content: [{ required: true, message: '请输入博内容', trigger: 'blur' }],
      category: [{ required: true, message: '选择博客分类', trigger: 'change' }],
      description: [{ required: true, message: '请输入博客描述', trigger: 'blur' }], // 添加描述的验证规则
      captcha: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { min: 4, max: 4, message: '验证码长度必须为4位', trigger: 'blur' }
      ]
    };

    const categoryRules = {
      name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }]
    };

    const getAuthHeaders = () => {
      const token = localStorage.getItem('accessToken');
      console.log('Current token:', token); // 添加这行来检查 token
      return {
        'Authorization': `Bearer ${token}`,


        'Connection': 'keep-alive'
      };
    };

    const axiosInstance = axios.create({
      baseURL: '${API_BASE_URL}', // 注意这里改回 http
    });

    axiosInstance.interceptors.request.use(config => {
      config.headers = {
        ...config.headers,
        ...getAuthHeaders()
      };
      return config;
    });

    const fetchCategories = async () => {
      try {
        console.log('获取分类...');
        const response = await axiosInstance.get(`${API_BASE_URL}/api/v1/blog/categories/all_categories/`);
        console.log('分类响应:', response.data);
        
        if (Array.isArray(response.data)) {
          categories.value = formatCategories(response.data);
          categoryOptions.value = formatCategoryOptions(categories.value);
        } else {
          console.error('意外的响应格式:', response.data);
          ElMessage.error('获取分类失败：意外的响应格式');
        }
        
        console.log('更新后的分类:', categories.value);
        console.log('更新后的分类选项:', categoryOptions.value);
      } catch (error) {
        console.error('获取分类失败:', error);
        ElMessage.error(`获取分类失败: ${error.message}`);
      }
    };

    const formatCategories = (categoriesData) => {
      return categoriesData.map(category => ({
        id: category.id,
        label: category.name,
        level: category.level,
        children: category.children ? formatCategories(category.children) : []
      }));
    };

    const formatCategoryOptions = (categories) => {
      return categories.map(category => ({
        value: category.id,
        label: category.label,
        children: category.children.length > 0 ? formatCategoryOptions(category.children) : undefined
      }));
    };

    const getCaptcha = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/captcha/generate/`);
        captchaImage.value = response.data.image;
        captchaKey.value = response.data.key;
      } catch (error) {
        console.error('获取验证码失败:', error);
        ElMessage.error('获取验证码失败，请刷新重试');
      }
    };

    const refreshCaptcha = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/captcha/generate/`);
        captchaImage.value = response.data.image;
        captchaKey.value = response.data.key;
        blogForm.captcha = ''; // 确保清空验证码输入
      } catch (error) {
        console.error('获取验证码失败:', error);
        ElMessage.error('获取验证码失败，请刷新重试');
      }
    };

    const submitBlog = async () => {
      if (!blogFormRef.value) return;
      
      await blogFormRef.value.validate(async (valid) => {
        if (valid) {
          try {
            // 将内容中的绝对路径转换为相对路径
            const relativeContent = blogForm.content.replace(new RegExp(`${API_BASE_URL}`, 'g'), '');

            const response = await axios.post(`${API_BASE_URL}/api/v1/blog/blogs/`, {
              title: blogForm.title,
              content: relativeContent,
              category_id: blogForm.category,
              description: blogForm.description,
              captcha_key: captchaKey.value,
              captcha_code: blogForm.captcha
            }, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
              }
            });
            
            // 先刷新验证码
            await refreshCaptcha();
            
            // 重置表单
            blogForm.title = '';
            blogForm.content = '';
            blogForm.category = null;
            blogForm.description = '';
            blogForm.captcha = '';
            blogFormRef.value.resetFields();
            
            ElMessage.success('博客发布成功');
            
            // 跳转到博客详情页
            router.push(`/blog/${response.data.id}`);
          } catch (error) {
            console.error('发布博客失败:', error);
            if (error.response && error.response.data) {
              const errorMessages = Object.values(error.response.data)
                .flat()
                .join(', ');
              ElMessage.error(errorMessages || '发布博客失败');
              if (error.response.data.captcha) {
                refreshCaptcha();
              }
            } else {
              ElMessage.error('发布博客失败');
            }
          }
        }
      });
    };

    const submitCategory = async () => {
      if (!categoryFormRef.value) return;
      
      await categoryFormRef.value.validate(async (valid) => {
        if (valid) {
          try {
            await axiosInstance.post(`${API_BASE_URL}/api/v1/blog/categories/`, {
              name: categoryForm.name,
              parent: categoryForm.parent ? categoryForm.parent[categoryForm.parent.length - 1] : null
            });
            
            ElMessage.success('分类添加成功');
            categoryFormRef.value.resetFields();
            
            // 更新分类数据
            await fetchCategories();
            
          } catch (error) {
            console.error('分类添加失败:', error);
            if (error.response && error.response.data) {
              const errorMessage = error.response.data.non_field_errors 
                ? error.response.data.non_field_errors.join(', ')
                : '分类添加失败';
              ElMessage.error(errorMessage);
            } else {
              ElMessage.error('分类添加失败');
            }
          }
        }
      });
    };

    const editCategory = async (data) => {
      try {
        const { value: newName } = await ElMessageBox.prompt('请输入新的分类名称', '编辑分类', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: data.name
        });
        
        if (newName && newName !== data.name) {
          try {
            await axiosInstance.patch(`${API_BASE_URL}/api/v1/blog/categories/${data.id}/`, {
              name: newName
            });
            
            ElMessage.success('分类更新成功');
            fetchCategories();
          } catch (error) {
            console.error('分类更新失败:', error);
            ElMessage.error('分类更新失败');
          }
        }
      } catch (error) {
        // 用户取消操作，不做任何处理
        if (error !== 'cancel') {
          console.error('编辑分类操作失败:', error);
          ElMessage.error('编辑分类操作失败');
        }
      }
    };

    const deleteCategory = async (node, data) => {
      try {
        await ElMessageBox.confirm('此操作将永久删除该分类，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        
        await axiosInstance.delete(`${API_BASE_URL}/api/v1/blog/categories/${data.id}/`);
        
        ElMessage.success('分类删除成');
        fetchCategories();
      } catch (error) {
        if (error !== 'cancel') {
          console.error('分类删除失败:', error);
          ElMessage.error('分类删除失败');
        }
      }
    };

    onMounted(() => {
      console.log('Component mounted, fetching categories...');
      fetchCategories();
      getCaptcha();
    });

    const tinymceInit = {
      height: 500,
      menubar: true,
      width: "100%",
      plugins: [
    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
    'insertdatetime', 'media', 'table', 'help', 'wordcount',
    'emoticons', // 表情
    'paste', // 粘贴
    'codesample', // 代码示例
    'hr', // 水平线
    'pagebreak', // 分页符
    'nonbreaking', // 插入不间断空格
    'template', // 模板
    'toc' // 目录
   ],
    toolbar: 'undo redo | formatselect | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | image | help',
      relative_urls: false,
      branding: false, // 移除 TinyMCE 品牌
      elementpath: false, // 移除底部的元素路径
      resize: true, // 允许调整大小
      autosave_interval: '30s', // 自动保存间隔
      fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 36px 48px 72px", 
      remove_script_host: true,
      language: "zh_CN",
      images_upload_url: '${API_BASE_URL}/api/upload-image/',
      images_upload_handler: function (blobInfo, success, failure) {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          const formData = new FormData();
          
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          
          xhr.open('POST', `${API_BASE_URL}/api/upload-image/`);
          xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
          
          xhr.onload = function() {
            if (xhr.status === 201) {
              try {
                const response = JSON.parse(xhr.responseText);
                if (response.location) {
                  const cleanPath = response.location.replace(/\\/g, '/');
                  const imageUrl = `${API_BASE_URL}${cleanPath}`;
                  console.log('图片上传成功，URL:', imageUrl);
                  success(imageUrl);
                  resolve(imageUrl);
                } else {
                  const error = '未获取到图片URL';
                  failure(error);
                  reject(error);
                }
              } catch (e) {
                const error = '解析响应失败';
                failure(error);
                reject(error);
              }
            } else {
              const error = '图片上传失败：' + xhr.status;
              failure(error);
              reject(error);
            }
          };
          
          xhr.onerror = function () {
            const error = '图片上传失败：网络错误';
            failure(error);
            reject(error);
          };
          
          xhr.send(formData);
        });
      },
 
    };

    return {
      blogForm,
      categoryForm,
      blogRules,
      categoryRules,
      blogFormRef,
      categoryFormRef,
      categories,
      categoryOptions,
      submitBlog,
      submitCategory,
      editCategory,
      deleteCategory,
      tinymceInit,
      captchaImage,
      refreshCaptcha
    };
  }
}
</script>

<style scoped>
.publish-blog-container {
  padding: 20px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.captcha-input :deep(.el-input-group__append) {
  padding: 0;
  background: #fff;
}

.captcha-wrapper {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.captcha-image {
  height: 30px;
  margin: 0 5px;
}
</style>

