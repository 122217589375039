<template>
  <div class="articles-container">
    <div class="articles-header">
      <h2>我的文章</h2>
    </div>

    <!-- 使用 el-table 替代当前的行布局 -->
    <el-table
      v-loading="loading"
      :data="blogs"
      :expand-row-keys="expandedRowKeys"
      row-key="id"
      style="width: 100%"
    >
      <!-- 添加展开列 -->
      <el-table-column type="expand">
        <template #default="{ row }">
          <!-- 将编辑表单移动到这里 -->
          <div v-if="row.isEditing && currentBlog" class="edit-form">
            <el-form 
              :model="currentBlog" 
              :rules="editRules" 
              ref="editFormRef" 
              label-width="120px"
            >
              <el-form-item label="标题" prop="title">
                <el-input v-model="currentBlog.title" />
              </el-form-item>
              <el-form-item label="分类" prop="category" required>
                <el-cascader
                  v-if="categoryOptions.length > 0"
                  v-model="currentBlog.category.id"
                  :options="categoryOptions"
                  :props="{ 
                    checkStrictly: true,
                    emitPath: false,
                    value: 'id',
                    label: 'name'
                  }"
                  clearable
                  placeholder="请选择分类"
                  @change="handleCategoryChange(currentBlog)"
                />
              </el-form-item>
              <el-form-item label="描述" prop="description" required>
                <el-input 
                  v-model="currentBlog.description" 
                  type="textarea" 
                  :rows="3" 
                  placeholder="请输入文章描述" 
                />
              </el-form-item>
              <el-form-item label="内容" prop="content">
                <div class="tinymce-container">
                  <editor
                    api-key="237kbwdvtifkc7jpu32ukserssrcfsfypt9o63zgaxskmeb4"
                    v-if="showEditor && currentBlog?.id === row.id"
                    :key="`editor-${row.id}-${editorKey}`"
                    :id="`tinymce-${row.id}`"
                    :init="tinymceInit"
                    :model-value="currentBlog.content"
                    @update:model-value="updateContent"
                  />
                </div>
              </el-form-item>
              <el-form-item>
                <div class="form-buttons">
                  <el-button 
                    type="primary" 
                    size="default"
                    @click="updateBlog(currentBlog)"
                  >
                    更新
                  </el-button>
                  <el-button 
                    type="info"
                    size="default"
                    @click="cancelEdit(currentBlog)"
                  >
                    取消
                  </el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="title" label="文章名称" width="200" />
      <el-table-column prop="description" label="描述" />
      <el-table-column prop="created_at" label="发布时间" width="180">
        <template #default="{ row }">
          {{ formatDate(row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column prop="category" label="分类" width="120">
        <template #default="{ row }">
          {{ getCategoryName(row.category) }}
        </template>
      </el-table-column>
      <el-table-column prop="is_approved" label="审核状态" width="120">
        <template #default="{ row }">
          <el-tag :type="row.is_approved ? 'success' : 'warning'">
            {{ row.is_approved ? '已审核' : '待审核' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template #default="{ row }">
          <div class="action-buttons">
            <el-button 
              :type="row.isEditing ? 'info' : 'primary'" 
              size="small"
              @click="toggleEdit(row)"
            >
              {{ row.isEditing ? '取消' : '编辑' }}
            </el-button>
            <el-button 
              type="danger" 
              size="small"
              @click="deleteBlog(row.id)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加分页组件 -->
    <div class="pagination-container">
      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick, onBeforeUnmount } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import axios from 'axios';
import { useRouter } from 'vue-router';
import Editor from '@tinymce/tinymce-vue';
import { API_BASE_URL } from '@/config' 
const router = useRouter();
const blogs = ref([]);
const categories = ref([]);
const categoryOptions = ref([]);
const editFormRef = ref(null);
const editRules = {
  title: [{ required: true, message: '请输入博客标题', trigger: 'blur' }],
  description: [{ required: true, message: '请输入博客描述', trigger: 'blur' }],
  content: [{ required: true, message: '请输博客内容', trigger: 'blur' }],
  category: [{ required: true, message: '请选择博客分类', trigger: 'change' }]
};

const tinymceInit = {
  
  height: 400,
  width: "100%",
  menubar: true,
  language: 'zh_CN',
  relative_urls: false,
  remove_script_host: true,
  
  plugins: [
    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
    'insertdatetime', 'media', 'table', 'help', 'wordcount'
  ],
  
  toolbar: 'undo redo | formatselect | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | image | help',
    
  fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 36px 48px 72px",
  
  images_upload_handler: function (blobInfo, success, failure) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const formData = new FormData();
      
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      
      xhr.open('POST', `${API_BASE_URL}/api/upload-image/`);
      xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
      
      xhr.onload = function() {
        if (xhr.status === 201) {
          try {
            const response = JSON.parse(xhr.responseText);
            if (response.location) {
              const cleanPath = response.location.replace(/\\/g, '/');
              const imageUrl = `${API_BASE_URL}${cleanPath}`;
              console.log('图片上传成功，URL:', imageUrl);
              success(imageUrl);
              resolve(imageUrl);
            } else {
              const error = '未获取到图片URL';
              failure(error);
              reject(error);
            }
          } catch (e) {
            const error = '解析响应失败';
            failure(error);
            reject(error);
          }
        } else {
          const error = '图片上传失败：' + xhr.status;
          failure(error);
          reject(error);
        }
      };
      
      xhr.onerror = function () {
        const error = '图片上传失败：网络错误';
        failure(error);
        reject(error);
      };
      
      xhr.send(formData);
    });
  },
  
  content_style: `
    body {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 16px;
      line-height: 1.6;
      padding: 1rem;
    }
    img { max-width: 100%; height: auto; }
  `
};

const axiosInstance = axios.create({
  baseURL: '${API_BASE_URL}',
});

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken');
  config.headers = {
    ...config.headers,
    'Authorization': `Bearer ${token}`,

    'Connection': 'keep-alive'
  };
  return config;
});

// 添加分页相关的响应式变量
const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(0);

const fetchMyBlogs = async () => {
  loading.value = true;
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/api/v1/blog/blogs/my_blogs/`, {
      params: {
        page: currentPage.value,
        page_size: pageSize.value
      }
    });
    
    if (response.data && response.data.results) {
      blogs.value = response.data.results.map(blog => ({
        ...blog,
        content: processContent(blog.content || ''),
        isEditing: false
      }));
      total.value = response.data.count;
    } else {
      console.error('Unexpected API response format:', response.data);
      blogs.value = [];
      ElMessage.error('获取博客列表失败：意外的数据格式');
    }
  } catch (error) {
    console.error('获取博客列表失败:', error);
    blogs.value = [];
    handleError(error);
  } finally {
    loading.value = false;
  }
};

const fetchCategories = async () => {
  try {
    console.log('获取分类...');
    const response = await axiosInstance.get(`${API_BASE_URL}/api/v1/blog/categories/all_categories/`);
    console.log('分类响应:', response.data);
    
    if (Array.isArray(response.data)) {
      categories.value = formatCategories(response.data);
      categoryOptions.value = formatCategoryOptions(categories.value);
    } else {
      console.error('意外的响应格式:', response.data);
      ElMessage.error('获取分类失败：意外的响应格式');
    }
    
    console.log('更新后的分类:', categories.value);
    console.log('更新后的分类选项:', categoryOptions.value);
  } catch (error) {
    console.error('获取分类失:', error);
    ElMessage.error(`获取分类失败: ${error.message}`);
  }
};

const formatCategories = (categoriesData) => {
  return categoriesData.map(category => ({
    id: category.id,
    name: category.name,
    children: category.children ? formatCategories(category.children) : []
  }));
};

const formatCategoryOptions = (categories) => {
  return categories.map(category => ({
    id: category.id,
    name: category.name,
    children: category.children.length > 0 ? formatCategoryOptions(category.children) : undefined
  }));
};

const getCategoryName = (category) => {
  if (category && typeof category === 'object' && category.name) {
    return category.name;
  }
  return '无分类';
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString();
};

const toggleEdit = async (blog) => {
  // 先关闭编辑器
  showEditor.value = false;
  
  // 重置其他博客编辑状态
  blogs.value.forEach(item => {
    if (item.id !== blog.id) {
      item.isEditing = false;
    }
  });

  if (currentBlog.value && currentBlog.value.id === blog.id) {
    // 取消编辑
    cancelEdit(blog);
  } else {
    // 设置新的编辑博客
    const editingBlog = JSON.parse(JSON.stringify(blog));
    editingBlog.isEditing = true;
    editingBlog.content = editingBlog.content || '';
    editingBlog.category = editingBlog.category || { id: null, name: '无分类' };
    editingBlog.originalData = { ...editingBlog };
    
    // 更新状态
    blog.isEditing = true;
    currentBlog.value = editingBlog;
    expandedRowKeys.value = [blog.id];
    editorKey.value++;

    // 等待 DOM 更新后显示编辑器
    await nextTick();
    showEditor.value = true;
  }
};

const cancelEdit = (blog) => {
  // 先找到对应的博客
  const targetBlog = blogs.value.find(b => b.id === blog.id);
  if (targetBlog) {
    targetBlog.isEditing = false;
  }
  
  // 关闭编辑器
  showEditor.value = false;
  
  // 清空展开的行
  expandedRowKeys.value = [];
  
  // 最后再清空 currentBlog
  currentBlog.value = null;
};

const updateBlog = async (blog) => {
  try {
    const actualBaseUrl = API_BASE_URL;
    const relativeContent = blog.content.replace(new RegExp(`${actualBaseUrl}`, 'g'), '');

    if (!blog.category || blog.category.id === null) {
      ElMessage.warning('选择一个分类');
      return;
    }

    if (!relativeContent || relativeContent.trim() === '') {
      ElMessage.warning('博客内容不能为空');
      return;
    }

    if (!blog.description || blog.description.trim() === '') {
      ElMessage.warning('博客描述不能为空');
      return;
    }

    const categoryId = blog.category.id;

    await axiosInstance.put(`${API_BASE_URL}/api/v1/blog/blogs/${blog.id}/`, {
      title: blog.title,
      content: relativeContent,
      category_id: categoryId,
      description: blog.description
    });

    ElMessage.success('博客更新成功');
    showEditor.value = false;
    blog.isEditing = false;
    currentBlog.value = null;
    expandedRowKeys.value = []; // 清空展开的行
    await fetchMyBlogs();
  } catch (error) {
    console.error('更新博客失败:', error);
    if (error.response && error.response.data) {
      let errorMessage = '更新失败: ';
      
      // 处理错误消息
      if (typeof error.response.data === 'string') {
        errorMessage += error.response.data;
      } else if (typeof error.response.data === 'object') {
        const messages = [];
        Object.entries(error.response.data).forEach(([field, msg]) => {
          if (Array.isArray(msg)) {
            messages.push(`${field}: ${msg.join(', ')}`);
          } else if (typeof msg === 'string') {
            messages.push(`${field}: ${msg}`);
          }
        });
        errorMessage += messages.join('; ');
      }
      
      ElMessage.error(errorMessage);
    } else {
      ElMessage.error('操作失败，请稍后重试');
    }
  }
};

const deleteBlog = async (blogId) => {
  try {
    await ElMessageBox.confirm('确定要删除这篇文章吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    });

    await axiosInstance.delete(`${API_BASE_URL}/api/v1/blog/blogs/${blogId}/`);
    
    // 如果正在编辑的博客被删除，关闭编辑窗口
    if (currentBlog.value && currentBlog.value.id === blogId) {
      showEditor.value = false;
      currentBlog.value = null;
    }
    
    ElMessage.success('删除成功');
    await fetchMyBlogs();
  } catch (error) {
    if (error !== 'cancel') {
      console.error('删除博客失败:', error);
      ElMessage.error('删除失败，请稍后重试');
    }
  }
};

const handleError = (error) => {
  if (error.response && error.response.status === 401) {
    ElMessage.error('登录已过期，请重新登录');
    localStorage.removeItem('accessToken');
    router.push('/login');
  } else {
    console.error('Error details:', error);
    ElMessage.error(error.response?.data?.message || '操作失败，请稍后重试');
  }
};

const handleCategoryChange = (blog) => {
  if (!blog) return;
  
  if (blog.category.id === null) {
    blog.category = null;
  } else {
    const selectedCategory = findCategory(categoryOptions.value, blog.category.id);
    if (selectedCategory) {
      blog.category = { id: selectedCategory.id, name: selectedCategory.name };
    }
  }
};

const findCategory = (categories, id) => {
  for (const category of categories) {
    if (category.id === id) {
      return category;
    }
    if (category.children) {
      const found = findCategory(category.children, id);
      if (found) return found;
    }
  }
  return null;
};

const processContent = (content) => {
  if (!content) return '';
  if (typeof content !== 'string') return String(content);
  return content.replace(/src="\/media\//g, `src="${API_BASE_URL}/media/`);
};

// 添加 loading 状态
const loading = ref(false);
const currentBlog = ref(null);

// 添加新的响应式变量
const showEditor = ref(false);
const editorKey = ref(0);

// 更新内容的方法
const updateContent = (content) => {
  if (currentBlog.value) {
    currentBlog.value.content = content;
  }
};

watch(() => currentBlog.value, (newVal) => {
  if (newVal) {
    // 确保内容不为 null
    newVal.content = newVal.content || '';
  }
}, { deep: true });

onMounted(() => {
  fetchMyBlogs();
  fetchCategories();
});

// 在组件卸载时确保清理
onBeforeUnmount(() => {
  showEditor.value = false;
  currentBlog.value = null;
  expandedRowKeys.value = [];
});

// 添加新的响应式变量
const expandedRowKeys = ref([]);

// 添加分页处理函数
const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
  currentPage.value = 1; // 重置到第一页
  fetchMyBlogs();
};

const handleCurrentChange = (newPage) => {
  currentPage.value = newPage;
  fetchMyBlogs();
};
</script>

<style scoped>
.articles-container {
  padding: 20px;
}

.articles-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.edit-form {
  padding: 20px;
  background-color: #f8f9fa;
  width: 100%;
}

.tinymce-container {
  min-height: 400px;
  margin: 10px 0;
  width: 100%;
}

:deep(.tox.tox-tinymce) {
  width: 100% !important;
}

:deep(.el-table__expanded-cell) {
  padding: 0 !important;
}

:deep(.el-form-item) {
  width: 100%;
}

:deep(.el-input),
:deep(.el-textarea) {
  width: 100%;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.form-buttons {
  display: flex;
  gap: 12px;
}

:deep(.el-button) {
  min-width: 80px;
}

/* 覆盖 Element Plus 的默认样式 */
.action-buttons :deep(.el-button + .el-button) {
  margin-left: 0;
}
</style>





