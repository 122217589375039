<template>
  <div class="security-settings">
    <h2>安全设置</h2>
    <el-form 
      :model="passwordForm" 
      :rules="rules" 
      ref="passwordFormRef"
      class="password-form"
    >
      <el-form-item prop="old_password">
        <span class="label-text">当前密码</span>
        <el-input 
          v-model="passwordForm.old_password" 
          type="password"
          placeholder="请输入当前密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="new_password">
        <span class="label-text">新密码</span>
        <el-input 
          v-model="passwordForm.new_password" 
          type="password"
          placeholder="请输入新密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="confirm_password">
        <span class="label-text">确认新密码</span>
        <el-input 
          v-model="passwordForm.confirm_password" 
          type="password"
          placeholder="请再次输入新密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="captcha">
        <span class="label-text">验证码</span>
        <el-input 
          v-model="passwordForm.captcha" 
          placeholder="请输入验证码"
          class="captcha-input"
        >
          <template #append>
            <div class="captcha-wrapper" @click="refreshCaptcha">
              <img v-if="captchaImage" :src="captchaImage" alt="验证码" class="captcha-image"/>
              <span v-else>加载中...</span>
            </div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitPasswordChange" class="submit-btn">更改密码</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { API_BASE_URL } from '@/config' 

export default {
  name: 'SecuritySettingsView',
  setup() {
    const passwordFormRef = ref(null)
    const captchaImage = ref('')
    const captchaKey = ref('')

    const passwordForm = reactive({
      old_password: '',
      new_password: '',
      confirm_password: '',
      captcha: ''
    })

    const rules = {
      old_password: [
        { required: true, message: '请输入当前密码', trigger: 'blur' }
      ],
      new_password: [
        { required: true, message: '请输入新密码', trigger: 'blur' },
        { min: 8, message: '密码长度至少为8个字符', trigger: 'blur' }
      ],
      confirm_password: [
        { required: true, message: '请确认新密码', trigger: 'blur' },
        {
          validator: (rule, value, callback) => {
            if (value !== passwordForm.new_password) {
              callback(new Error('两次输入的密码不一致'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      captcha: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { len: 4, message: '验证码长度应为 4 位', trigger: 'blur' }
      ]
    }

    const getCaptcha = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/captcha/generate/`)
        captchaImage.value = response.data.image
        captchaKey.value = response.data.key
      } catch (error) {
        console.error('获取验证码失败:', error)
        ElMessage.error('获取验证码失败，请刷新重试')
      }
    }

    const refreshCaptcha = () => {
      passwordForm.captcha = ''
      getCaptcha()
    }

    const submitPasswordChange = () => {
      passwordFormRef.value.validate(async (valid) => {
        if (valid) {
          try {
            const response = await axios.post(`${API_BASE_URL}/api/v1/user/change-password/`, {
              old_password: passwordForm.old_password,
              new_password: passwordForm.new_password,
              captcha_key: captchaKey.value,
              captcha_code: passwordForm.captcha
            }, {
              headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
            })
            
            if (response.status === 200) {
              ElMessage.success('密码修改成功')
              passwordForm.old_password = ''
              passwordForm.new_password = ''
              passwordForm.confirm_password = ''
              passwordForm.captcha = ''
              passwordFormRef.value.resetFields()
              refreshCaptcha()
            } else {
              throw new Error('密码修改失败')
            }
          } catch (error) {
            console.error('修改密码失败:', error)
            if (error.response && error.response.data) {
              const errorMessages = Object.values(error.response.data).flat()
              ElMessage.error(errorMessages.join(', '))
              if (error.response.data.captcha) {
                refreshCaptcha()
              }
            } else {
              ElMessage.error('修改密码失败，请稍后重试')
            }
          }
        }
      })
    }

    onMounted(() => {
      getCaptcha()
    })

    return {
      passwordForm,
      rules,
      passwordFormRef,
      submitPasswordChange,
      captchaImage,
      refreshCaptcha
    }
  }
}
</script>

<style scoped>
.security-settings {
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
}

h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 24px;
  font-weight: 500;
}

.password-form {
  max-width: 100%;
}

.label-text {
  display: block;
  font-size: 14px;
  color: #606266;
  margin-bottom: 8px;
}

.label-text::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

:deep(.el-form-item) {
  margin-bottom: 24px;
}

:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #dcdfe6 inset;
  border-radius: 8px;
  padding: 0 12px;
}

:deep(.el-input__wrapper:hover) {
  box-shadow: 0 0 0 1px #409eff inset;
}

:deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 1px #409eff inset;
}

:deep(.el-input__inner) {
  height: 40px;
  color: #333;
}

:deep(.el-input__inner::placeholder) {
  color: #909399;
}

:deep(.el-form-item__error) {
  padding-top: 4px;
  font-size: 12px;
}

:deep(.el-button--primary) {
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  background: #409eff;
  border: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

:deep(.el-button--primary:hover) {
  background: #66b1ff;
  transform: translateY(-1px);
}

:deep(.el-button--primary:active) {
  transform: translateY(0);
}

.submit-btn {
  min-width: 120px;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .security-settings {
    padding: 16px;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .label-text {
    font-size: 14px;
    margin-bottom: 6px;
  }

  :deep(.el-form-item) {
    margin-bottom: 20px;
  }

  :deep(.el-input__inner) {
    height: 38px;
  }

  :deep(.el-button--primary) {
    height: 38px;
    margin-top: 4px;
  }
}

.captcha-input :deep(.el-input-group__append) {
  padding: 0;
  background: #fff;
}

.captcha-wrapper {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.captcha-image {
  height: 30px;
  margin: 0 5px;
}
</style>
