<template>
  <el-dialog
    v-model="dialogVisible"
    title="提交反馈"
    width="500px"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form
      ref="ticketFormRef"
      :model="ticketForm"
      :rules="rules"
      label-width="80px"
      class="ticket-form"
    >
      <el-form-item label="标题" prop="title">
        <el-input 
          v-model="ticketForm.title" 
          placeholder="请输入标题"
          maxlength="100"
          show-word-limit
        />
      </el-form-item>

      <el-form-item label="类型" prop="ticket_type">
        <el-select v-model="ticketForm.ticket_type" placeholder="请选择反馈类型" class="w-full">
          <el-option label="功能建议" value="feature" />
          <el-option label="问题反馈" value="bug" />
          <el-option label="使用咨询" value="question" />
          <el-option label="其他" value="other" />
        </el-select>
      </el-form-item>

      <el-form-item label="优先级" prop="priority">
        <el-select v-model="ticketForm.priority" placeholder="请选择优先级" class="w-full">
          <el-option 
            v-for="item in priorities" 
            :key="item.value" 
            :label="item.label" 
            :value="item.value"
          >
            <template #default="{ label }">
              <el-tag :type="getPriorityType(item.value)" size="small">
                {{ label }}
              </el-tag>
            </template>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="内容" prop="content">
        <el-input
          v-model="ticketForm.content"
          type="textarea"
          :rows="6"
          placeholder="请详细描述您的反馈内容..."
          maxlength="1000"
          show-word-limit
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitTicket" :loading="submitting">
          提交
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, computed, defineProps, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { API_BASE_URL } from '@/config'

const props = defineProps({
  modelValue: Boolean,
  toolId: {
    type: [String, Number],
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

const dialogVisible = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
})

const ticketFormRef = ref(null)
const submitting = ref(false)

const ticketForm = reactive({
  title: '',
  content: '',
  ticket_type: '',
  priority: 'medium'
})

const priorities = [
  { label: '低', value: 'low' },
  { label: '中', value: 'medium' },
  { label: '高', value: 'high' },
  { label: '紧急', value: 'urgent' }
]

const rules = {
  title: [
    { required: true, message: '请输入标题', trigger: 'blur' },
    { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
  ],
  ticket_type: [
    { required: true, message: '请选择反馈类型', trigger: 'change' }
  ],
  priority: [
    { required: true, message: '请选择优先级', trigger: 'change' }
  ],
  content: [
    { required: true, message: '请输入反馈内容', trigger: 'blur' },
    { min: 10, max: 1000, message: '长度在 10 到 1000 个字符', trigger: 'blur' }
  ]
}

const getPriorityType = (priority) => {
  const types = {
    low: 'info',
    medium: 'warning',
    high: 'danger',
    urgent: 'danger'
  }
  return types[priority] || 'info'
}

const submitTicket = async () => {
  if (!ticketFormRef.value) return
  
  await ticketFormRef.value.validate(async (valid) => {
    if (!valid) return

    submitting.value = true
    try {
      const token = localStorage.getItem('accessToken')
      if (!token) {
        ElMessage.warning('请先登录后再提交反馈')
        return
      }

      await axios.post(
        `${API_BASE_URL}/api/v1/tools/tickets/`,
        {
          ...ticketForm,
          tool: props.toolId
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      )

      ElMessage.success('反馈提交成功')
      dialogVisible.value = false
      handleClose()
    } catch (error) {
      console.error('提交反馈失败:', error)
      ElMessage.error('提交失败，请稍后重试')
    } finally {
      submitting.value = false
    }
  })
}

const handleClose = () => {
  ticketFormRef.value?.resetFields()
  Object.assign(ticketForm, {
    title: '',
    content: '',
    ticket_type: '',
    priority: 'medium'
  })
}
</script>

<style scoped>
.ticket-form {
  padding: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding-top: 20px;
}

:deep(.el-select) {
  width: 100%;
}

:deep(.el-tag) {
  width: 100%;
  text-align: center;
}
</style> 