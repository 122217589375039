<template>
  <div class="publish-question-container">
    <el-form :model="questionForm" :rules="questionRules" ref="questionFormRef" label-width="100px">
      <el-form-item label="标题" prop="title">
        <el-input 
          v-model="questionForm.title"
          maxlength="100"
          show-word-limit
          placeholder="请输入问题标题"
        ></el-input>
      </el-form-item>

      <el-form-item label="内容" prop="content">
        <editor
          v-model="questionForm.content"
          :init="tinymceInit"
          api-key="237kbwdvtifkc7jpu32ukserssrcfsfypt9o63zgaxskmeb4"
        />
      </el-form-item>

      <el-form-item label="标签" prop="tag_names">
        <el-tag
          v-for="tag in questionForm.tag_names"
          :key="tag"
          closable
          class="mx-1"
          @close="removeTag(tag)"
        >
          {{ tag }}
        </el-tag>
        
        <el-input
          v-if="inputVisible"
          ref="tagInputRef"
          v-model="inputValue"
          class="w-100"
          size="small"
          @keyup.enter="handleInputConfirm"
          @blur="handleInputConfirm"
        />
        <el-button 
          v-else 
          class="button-new-tag" 
          size="small" 
          @click="showInput"
        >
          + 添加标签
        </el-button>
      </el-form-item>

      <el-form-item label="关联工具" prop="tool">
        <el-select
          v-model="questionForm.tool"
          filterable
          clearable
          placeholder="请选择关联工具（可选）"
          style="width: 100%"
        >
          <el-option
            v-for="tool in tools"
            :key="tool.id"
            :label="tool.title"
            :value="tool.id"
          >
            <div class="tool-option">
              <div class="tool-logo">
                <el-image
                  :src="tool.logo.startsWith('http') ? tool.logo : `${API_BASE_URL}${tool.logo}`"
                  :preview-src-list="[]"
                  fit="contain"
                >
                  <template #error>
                    <div class="image-slot">
                      <el-icon><Picture /></el-icon>
                    </div>
                  </template>
                </el-image>
              </div>
              <span class="tool-title">{{ tool.title }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="验证码" prop="captcha">
        <el-input 
          v-model="questionForm.captcha" 
          placeholder="请输入验证码"
          class="captcha-input"
        >
          <template #append>
            <div class="captcha-wrapper" @click="refreshCaptcha">
              <img v-if="captchaImage" :src="captchaImage" alt="验证码" class="captcha-image"/>
              <span v-else>加载中...</span>
            </div>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitQuestion">发布问题</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref, reactive, nextTick, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { API_BASE_URL } from '@/config'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    Editor
  },
  
  setup() {
    const questionFormRef = ref(null)
    const inputVisible = ref(false)
    const inputValue = ref('')
    const tagInputRef = ref(null)
    const tools = ref([])
    const router = useRouter()
    const captchaImage = ref('')
    const captchaKey = ref('')

    const questionForm = reactive({
      title: '',
      content: '',
      tag_names: [],
      tool: null,
      captcha: ''
    })

    // TinyMCE 编辑器配置
    const tinymceInit = {
      height: 500,
      menubar: true,
      width: "100%",
      plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
        'insertdatetime', 'media', 'table', 'help', 'wordcount'
      ],
      toolbar: 'undo redo | formatselect | ' +
        'bold italic backcolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'removeformat | image | help',
      relative_urls: false,
      fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 36px 48px 72px",
      remove_script_host: true,
      language: "zh_CN",
      images_upload_url: `${API_BASE_URL}/api/upload-image/`,
      images_upload_handler: function (blobInfo, success, failure) {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          const formData = new FormData();
          
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          
          xhr.open('POST', `${API_BASE_URL}/api/upload-image/`);
          xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
          
          xhr.onload = function() {
            if (xhr.status === 201) {
              try {
                const response = JSON.parse(xhr.responseText);
                if (response.location) {
                  const cleanPath = response.location.replace(/\\/g, '/');
                  const imageUrl = `${API_BASE_URL}${cleanPath}`;
                  console.log('图片上传成功，URL:', imageUrl);
                  success(imageUrl);
                  resolve(imageUrl);
                } else {
                  const error = '未获取到图片URL';
                  failure(error);
                  reject(error);
                }
              } catch (e) {
                const error = '解析响应失败';
                failure(error);
                reject(error);
              }
            } else {
              const error = '图片上传失败：' + xhr.status;
              failure(error);
              reject(error);
            }
          };
          
          xhr.onerror = function () {
            const error = '图片上传失败：网络错误';
            failure(error);
            reject(error);
          };
          
          xhr.send(formData);
        });
      }
    }

    // 表单验证规则
    const questionRules = {
      title: [
        { required: true, message: '请输入问题标题', trigger: 'blur' },
        { max: 100, message: '标题最大长度为100个字符', trigger: 'blur' }
      ],
      content: [
        { required: true, message: '请输入问题内容', trigger: 'blur' }
      ],
      captcha: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { len: 4, message: '验证码长度应为 4 位', trigger: 'blur' }
      ]
    }

    // 获取工具列表
    const fetchTools = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/qa/questions/available_tools/`)
        tools.value = response.data.tools || []
      } catch (error) {
        console.error('获取工具列表失败:', error)
        ElMessage.error('获取工具列表失败')
      }
    }

    const showInput = async () => {
      inputVisible.value = true
      await nextTick()
      tagInputRef.value.input.focus()
    }

    const handleInputConfirm = async () => {
      if (inputValue.value) {
        const tagName = inputValue.value.trim()
        
        // 检查是否已在当前问题的标签列表中
        if (questionForm.tag_names.includes(tagName)) {
          ElMessage.warning('已添加该标签')
          inputVisible.value = false
          inputValue.value = ''
          return
        }

        try {
          // 尝试创建新标签
          await axios.post(
            `${API_BASE_URL}/api/v1/qa/tags/`,
            { name: tagName },
            {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
              }
            }
          )
          // 创建成功或标签已存在，都添加到标签列表
          questionForm.tag_names.push(tagName)
          ElMessage.success('标签添加成功')
        } catch (error) {
          if (error.response?.data?.error?.includes('已存在')) {
            // 如果标签已存在，直接添加到列表中
            questionForm.tag_names.push(tagName)
            ElMessage.success('标签添加成功')
          } else {
            // 其他错误
            console.error('创建标签失败:', error)
            ElMessage.error('标签添加失败')
          }
        }
      }
      inputVisible.value = false
      inputValue.value = ''
    }

    const removeTag = (tag) => {
      questionForm.tag_names = questionForm.tag_names.filter((t) => t !== tag)
    }

    const getCaptcha = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/captcha/generate/`)
        captchaImage.value = response.data.image
        captchaKey.value = response.data.key
      } catch (error) {
        console.error('获取验证码失败:', error)
        ElMessage.error('获取验证码失败，请刷新重试')
      }
    }

    const refreshCaptcha = () => {
      questionForm.captcha = ''
      getCaptcha()
    }

    // 添加提交问题的方法
    const submitQuestion = async () => {
      if (!questionFormRef.value) return;
      
      await questionFormRef.value.validate(async (valid) => {
        if (valid) {
          try {
            // 将内容中的绝对路径转换为相对路径
            const relativeContent = questionForm.content.replace(new RegExp(`${API_BASE_URL}`, 'g'), '');

            const formData = {
              title: questionForm.title,
              content: relativeContent,
              tag_names: questionForm.tag_names,
              captcha_key: captchaKey.value,
              captcha_code: questionForm.captcha
            };

            // 只有当选择了工具时才添加 tool_id
            if (questionForm.tool) {
              formData.tool_id = questionForm.tool;
            }

            const response = await axios.post(
              `${API_BASE_URL}/api/v1/qa/questions/`,
              formData,
              {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
              }
            );
            
            ElMessage.success('问题发布成功');
            // 发布成功后跳转到问题详情页
            router.push(`/qa/question/${response.data.id}`);
          } catch (error) {
            console.error('发布问题失败:', error);
            if (error.response && error.response.data) {
              const errorMessages = Object.values(error.response.data)
                .flat()
                .join(', ');
              ElMessage.error(errorMessages || '发布问题失败');
              if (error.response.data.captcha) {
                refreshCaptcha()
              }
            } else {
              ElMessage.error('发布问题失败');
            }
          }
        }
      });
    };

    onMounted(() => {
      fetchTools()
      getCaptcha()
    })

    return {
      questionForm,
      questionRules,
      questionFormRef,
      inputVisible,
      inputValue,
      tagInputRef,
      tools,
      showInput,
      handleInputConfirm,
      removeTag,
      tinymceInit,
      API_BASE_URL,
      submitQuestion,
      captchaImage,
      refreshCaptcha
    }
  }
}
</script>

<style scoped>
.publish-question-container {
  padding: 20px;
}

.tool-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tool-logo {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-logo :deep(.el-image) {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tool-title {
  font-size: 14px;
  color: #606266;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #909399;
  font-size: 12px;
}

.el-tag {
  margin-right: 8px;
  margin-bottom: 8px;
}

.button-new-tag {
  margin-left: 8px;
  height: 32px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 8px;
  vertical-align: bottom;
}

.captcha-input :deep(.el-input-group__append) {
  padding: 0;
  background: #fff;
}

.captcha-wrapper {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.captcha-image {
  height: 30px;
  margin: 0 5px;
}
</style>
