import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ToolView from '../views/ToolView.vue';
import BlogView from '../views/BlogView.vue';
import QaView from '../views/QaView.vue';
import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import ProfileView from '../views/ProfileView.vue'; // 新增个人中心视图
import ToolContentView from '../views/ToolContentView.vue'; // 新增
import BlogContentView from '../views/BlogContentView.vue'; // 新增
import TodoView from '../views/TodoView.vue'; // 添加这行导入语句
import QaContentView from '../views/QaContentView.vue'; // 新增
import { useUserStore } from '@/stores/user'

const routes = [
  { 
    path: '/', 
    component: HomeView,
    meta: { title: '首页' }
  },
  { 
    path: '/tool', 
    component: ToolView,
    meta: { title: '工具' }
  },
  { 
    path: '/blog', 
    component: BlogView,
    meta: { title: '博客' }
  },
  { 
    path: '/qa', 
    component: QaView,
    meta: { title: '问答' }
  },
  { 
    path: '/login', 
    component: LoginView,
    meta: { title: '登录' }
  },
  { 
    path: '/register', 
    component: RegisterView,
    meta: { title: '注册' }
  },
  { 
    path: '/profile', 
    component: ProfileView, 
    meta: { 
      requiresAuth: true,
      title: '个人中心',
      dynamicTitle: false
    }
  },
  { 
    path: '/tool/:id', 
    name: 'ToolContent',
    component: ToolContentView,
    props: true,
    meta: { 
      dynamicTitle: true,
      title: '工具'
    }
  },
  { 
    path: '/blog/:id', 
    component: BlogContentView,
    meta: { 
      dynamicTitle: true,
      title: '博客'
    }
  },
  { 
    path: '/qa/question/:id', 
    component: QaContentView,
    meta: { 
      dynamicTitle: true,
      title: '问答'
    }
  },
  {
    path: '/user/publish-question',
    name: 'PublishQuestion',
    component: () => import('@/views/components/PublishQuestionView.vue'),
    meta: {
      requiresAuth: true,
      title: '发布问题'
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/SearchResultView.vue')
  },
  {
    path: '/todo',
    component: TodoView,
    meta: { 
      requiresAuth: true,
      title: '待办事项'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { 
        top: 0,
        behavior: 'smooth'
      }
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const store = useUserStore()
  
  if (localStorage.getItem('logout') === 'true') {
    store.clearUserInfo()
    if (to.meta.requiresAuth) {
      next({ path: '/login', query: { redirect: to.fullPath } })
      return
    }
  }
  
  if (to.meta.requiresAuth) {
    const isAuthenticated = await store.checkAuthStatus()
    
    if (!isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
      return
    }
  }
  
  if ((to.path === '/login' || to.path === '/register') && store.isAuthenticated) {
    next('/')
    return
  }
  
  if (from.path === '/register' && to.path === '/register' && store.isAuthenticated) {
    next('/')
    return
  }
  
  // 修改基础标题为"工具狗"
  const baseTitle = '工具猿'
  if (to.meta.title) {
    // 如果是动态标题的页面，让组件自己设置标题
    if (!to.meta.dynamicTitle) {
      if (to.meta.title === '首页') {
        document.title = baseTitle
      } else if (to.meta.title === '个人中心') {
        document.title = `${baseTitle} - ${to.meta.title}`
      } else {
        document.title = `${to.meta.title} - ${baseTitle}`
      }
    }
  } else {
    document.title = baseTitle
  }
  
  next()
})

export default router;
