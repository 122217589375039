<template>
  <div class="home-container">
    <!-- 英雄区域 -->
    <el-row class="hero" justify="center" align="middle">
      <el-col :span="24">
        <div class="hero-content">
          <h1 class="hero-title">工欲善其事，必先利其器。</h1>
          <h2 class="hero-subtitle">轻松工作，多点生活</h2>
          <el-icon class="hero-icon" :size="320"><tools /></el-icon>
        </div>
      </el-col>
    </el-row>

    <!-- 功能区域 -->
    <el-row class="features" :gutter="20">
      <el-col 
        :xs="24" 
        :sm="24"
        :md="8" 
        v-for="feature in features" 
        :key="feature.title"
        class="feature-col"
      >
        <div class="feature-card">
          <div class="icon-wrapper">
            <div class="icon-circle">
              <el-icon class="feature-icon"><component :is="feature.icon" /></el-icon>
            </div>
          </div>
          
          <div class="content">
            <h3 class="title">{{ feature.title }}</h3>
            <p class="description">{{ feature.description }}</p>
          </div>
          
          <div class="action">
            <div class="view-more-link" @click="handleFeatureClick(feature.route)">
              {{ feature.buttonText }}
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 添加反馈对话框 -->
    <el-dialog
      v-model="dialogVisible"
      title="提交工具"
      :width="isMobile ? '90%' : '50%'"
      :before-close="handleClose"
      class="submit-tool-dialog"
    >
      <el-form
        ref="feedbackFormRef"
        :model="feedbackForm"
        :rules="rules"
        :label-position="isMobile ? 'top' : 'right'"
        label-width="80px"
      >
        <el-form-item label="工具名称" prop="title">
          <el-input 
            v-model="feedbackForm.title"
            placeholder="请输入工具名称（至少2个字符）"
          />
        </el-form-item>
        <el-form-item label="工具详情" prop="content">
          <el-input
            v-model="feedbackForm.content"
            type="textarea"
            :rows="4"
            placeholder="请输入工具详情（至少5个字符）"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitFeedback">
            提交
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
// eslint-disable-next-line no-unused-vars
import { Search, Upload, Reading, Tools } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { API_BASE_URL } from '@/config'

const router = useRouter()

const features = ref([
  {
    title: '探索工具',
    description: '探索我们丰富的工具集合。',
    icon: Search,
    route: '/tool',
    buttonText: '查看详情'
  },
  {
    title: '提交工具',
    description: '分享您发现的优秀工具。',
    icon: Upload,
    route: '/submit-tool',
    buttonText: '我要提交'
  },
  {
    title: '工具教程',
    description: '提供各种工具教程,节省你的时间。',
    icon: Reading,
    route: '/blog',
    buttonText: '查看详情'
  }
])

// 添加新的响应式变量
const dialogVisible = ref(false)
const feedbackFormRef = ref(null)
const feedbackForm = ref({
  title: '',
  content: ''
})

// 表单验证规则
const rules = {
  title: [
    { required: true, message: '请输入工具名称', trigger: 'blur' },
    { min: 2, message: '标题至少需要2个字符', trigger: 'blur' }
  ],
  content: [
    { required: true, message: '请入工具详情', trigger: 'blur' },
    { min: 5, message: '详情至少需要5个字符', trigger: 'blur' }
  ]
}

const handleFeatureClick = (route) => {
  if (route === '/submit-tool') {
    dialogVisible.value = true
  } else {
    router.push(route)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

// 关闭对话框前的处理
const handleClose = (done) => {
  feedbackFormRef.value?.resetFields()
  done()
}



// 提交反馈
const submitFeedback = async () => {
  if (!feedbackFormRef.value) return
  
  await feedbackFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        // 使用显式的 URL 拼接
        await axios.post(`${API_BASE_URL}/api/v1/tools/feedback/`, {
          title: feedbackForm.value.title,
          content: feedbackForm.value.content
        })
        
        ElMessage({
          type: 'success',
          message: '提交成功！'
        })
        
        dialogVisible.value = false
        feedbackFormRef.value.resetFields()
      } catch (error) {
        console.error('提交失败:', error)
        ElMessage({
          type: 'error',
          message: error.response?.data?.message || '提交失败，请重试'
        })
      }
    }
  })
}

// 添加移动端检测
const isMobile = computed(() => {
  return window.innerWidth <= 768
})
</script>

<style scoped>
.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.hero {
  text-align: center;
  margin-bottom: 60px;
}

.hero-title {
  font-size: 48px;
  font-weight: 600;
  color: #303133;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 18px;
  color: #606266;
  margin-bottom: 40px;
}

.hero-icon {
  color: #409EFF;
  transition: transform 0.3s ease-in-out;
}

.hero-icon:hover {
  transform: scale(1.1);
}

.features {
  max-width: 1200px;
  margin: 60px auto;
  padding: 0 20px;
}

.feature-col {
  margin-bottom: 30px;
}

.feature-card {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebeef5;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02);
  padding: 0;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.icon-wrapper {
  padding: 32px 24px 24px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.icon-circle {
  width: 100px;
  height: 100px;
  background: rgba(64, 158, 255, 0.08);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-icon {
  font-size: 40px;
  color: #409EFF;
}

.content {
  padding: 0 24px;
  width: 100%;
  text-align: center;
  flex-grow: 1;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #303133;
}

.description {
  font-size: 14px;
  color: #606266;
  line-height: 1.6;
  margin-bottom: 24px;
}

.action {
  width: 100%;
  margin-top: auto;
}

.view-more-link {
  width: 100%;
  padding: 12px 0;
  color: #409EFF;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  border-top: 1px solid #ebeef5;
}

.view-more-link:hover {
  background-color: #409EFF;
  color: #ffffff;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .features {
    margin: 30px auto;
    padding: 0 16px;
  }

  .feature-col {
    margin-bottom: 20px;
    padding: 0 10px;
  }
  
  .feature-col:last-child {
    margin-bottom: 0;
  }
  
  .icon-wrapper {
    padding: 24px 16px 16px;
  }
  
  .icon-circle {
    width: 80px;
    height: 80px;
  }
  
  .feature-icon {
    font-size: 32px;
  }
  
  .content {
    padding: 0 16px;
  }
  
  .title {
    font-size: 18px;
    margin-bottom: 12px;
  }
  
  .description {
    font-size: 14px;
    margin-bottom: 16px;
    line-height: 1.5;
  }
  
  .view-more-link {
    padding: 12px 0;
    font-size: 14px;
  }
}

/* 超小屏幕的优化 */
@media (max-width: 375px) {
  .features {
    padding: 0 12px;
  }
  
  .icon-circle {
    width: 70px;
    height: 70px;
  }
  
  .feature-icon {
    font-size: 28px;
  }
  
  .title {
    font-size: 16px;
  }
  
  .description {
    font-size: 13px;
  }
  
  .view-more-link {
    padding: 10px 0;
  }
}

/* 处理横屏模式 */
@media (max-width: 768px) and (orientation: landscape) {
  .features {
    display: flex;
    flex-wrap: wrap;
  }
  
  .feature-card {
    margin-bottom: 12px;
  }
  
  .icon-wrapper {
    padding: 20px 16px 12px;
  }
}

/* 平板设备优化 */
@media (min-width: 769px) and (max-width: 1024px) {
  .features {
    padding: 0 24px;
  }
  
  .feature-card {
    margin-bottom: 30px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

:deep(.el-dialog__body) {
  padding: 20px 40px;
}

/* 添加移动端对话框样式 */
:deep(.submit-tool-dialog) {
  .el-dialog__body {
    padding: 20px;
  }
  
  .el-form-item {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .el-dialog__body {
      padding: 15px;
    }

    .el-form-item__label {
      padding-bottom: 8px;
    }

    .dialog-footer {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    }

    .el-button {
      flex: 1;  /* 按钮等宽 */
      margin-left: 0 !important;
    }

    .el-input__wrapper,
    .el-textarea__wrapper {
      width: 100%;
    }
  }
}

/* 确保对话框在移动端有合适的最大高度 */
@media (max-width: 768px) {
  :deep(.el-dialog) {
    max-height: 90vh;
    overflow-y: auto;
    margin: 5vh auto !important;
  }
}
</style>
