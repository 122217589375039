<template>
  <div class="profile-container">
    <el-row :gutter="20" class="main-content">
      <!-- 左侧面板 -->
      <el-col :span="24" :md="6" class="left-panel">
        <el-card class="user-card glass-effect">
          <el-avatar :size="80" :src="userAvatar"></el-avatar>
          <h2>{{ username }}</h2>
          <p class="user-email">{{ email }}</p>
          <p class="user-phone">手机号: {{ phoneNumber }}</p>
          <el-button type="primary" class="edit-btn" @click="showEditDialog = true">
            编辑资料
          </el-button>
        </el-card>
        
        <!-- 统一的菜单，移除 hide-on-mobile 类 -->
        <el-menu
          :default-active="activeMenu"
          class="profile-menu glass-effect"
          @select="handleSelect"
        >
          <!-- 保持原有菜单项不变 -->
          <el-menu-item index="1">
            <el-icon><User /></el-icon>
            <span>个人信息</span>
          </el-menu-item>
          <el-menu-item index="2">
            <el-icon><Lock /></el-icon>
            <span>安全设置</span>
          </el-menu-item>
          <el-menu-item index="3">
            <el-icon><Bell /></el-icon>
            <span>消息通知</span>
          </el-menu-item>
          <el-menu-item index="8">
            <el-icon><QuestionFilled /></el-icon>
            <span>发布问题</span>
          </el-menu-item>
          <el-menu-item index="4">
            <el-icon><Document /></el-icon>
            <span>我的文章</span>
          </el-menu-item>
          <el-menu-item index="5">
            <el-icon><Star /></el-icon>
            <span>我的收藏</span>
          </el-menu-item>
          <el-menu-item index="6">
            <el-icon><Edit /></el-icon>
            <span>发布博客</span>
          </el-menu-item>
          <el-menu-item index="7">
            <el-icon><Tickets /></el-icon>
            <span>我的工单</span>
          </el-menu-item>
        </el-menu>
      </el-col>

      <!-- 右侧内容区 -->
      <el-col :span="24" :md="18">
        <el-card class="content-card glass-effect">
          <component :is="currentComponent"></component>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog v-model="showEditDialog" title="编辑个人资料">
      <el-form :model="userForm" :rules="rules" ref="userFormRef" label-width="100px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="userForm.username"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="userForm.email" disabled></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone_number">
          <el-input v-model="userForm.phone_number"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            :auto-upload="false"
            :show-file-list="false"
            :on-change="handleAvatarChange"
          >
            <img 
              v-if="userForm.avatar" 
              :src="userForm.avatar" 
              class="avatar preview-avatar"
            >
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="验证码" prop="captcha">
          <el-input 
            v-model="userForm.captcha" 
            placeholder="请输入验证码"
            class="captcha-input"
          >
            <template #append>
              <div class="captcha-wrapper" @click="refreshCaptcha">
                <img v-if="captchaImage" :src="captchaImage" alt="验证码" class="captcha-image"/>
                <span v-else>加载中...</span>
              </div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showEditDialog = false">取消</el-button>
          <el-button type="primary" @click="submitForm">确定</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="showBlogDialog" title="发布博客">
      <el-form :model="blogForm" :rules="blogRules" ref="blogFormRef" label-width="100px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="blogForm.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" v-model="blogForm.content" :rows="6"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showBlogDialog = false">取消</el-button>
          <el-button type="primary" @click="submitBlog">发布</el-button>
        </span>
      </template>
    </el-dialog>

    
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, shallowRef, watch } from 'vue';
import { ElMessage, ElForm } from 'element-plus';
import { User, Lock, Bell, Document, Star, Plus, Edit, Tickets, QuestionFilled } from '@element-plus/icons-vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { API_BASE_URL } from '@/config' 
// 导入组件
import PersonalInfoComponent from './components/PersonalInfo.vue';
import SecuritySettingsComponent from './components/SecuritySettings.vue';
import NotificationsComponent from './components/NotificationsView.vue';
import MyArticlesComponent from './components/MyArticlesView.vue';
import MyFavoritesComponent from './components/MyFavoritesView.vue';
import PublishBlogComponent from './components/PublishBlogView.vue';
import { useUserStore } from '@/stores/user';  // 导入用户状态存储
import MyTicketsView from '@/components/MyTicketsView.vue'
import PublishQuestionComponent from './components/PublishQuestionView.vue';

// 初始化 store
const userStore = useUserStore();
const router = useRouter();

// 用户信息
const username = ref('');
const email = ref('');
const userAvatar = ref('');
const phoneNumber = ref('');
const userId = ref('');

// 编辑对话框
const showEditDialog = ref(false);
const userForm = reactive({
  username: '',
  email: '',
  avatar: '',
  phone_number: '',
  captcha: ''
});

// 活动菜单项
const activeMenu = ref('1');

// 当前显示的组件
const currentComponent = shallowRef(PersonalInfoComponent);

// 添加验证码相关的响应式数据
const captchaImage = ref('');
const captchaKey = ref('');

// 处理菜单选择
const handleSelect = (key) => {
  activeMenu.value = key;
  updateTitle(key);
  switch (key) {
    case '1': currentComponent.value = PersonalInfoComponent; break;
    case '2': currentComponent.value = SecuritySettingsComponent; break;
    case '3': currentComponent.value = NotificationsComponent; break;
    case '8': currentComponent.value = PublishQuestionComponent; break;
    case '4': currentComponent.value = MyArticlesComponent; break;
    case '5': currentComponent.value = MyFavoritesComponent; break;
    case '6': 
      currentComponent.value = PublishBlogComponent;
    
      break;
    case '7': currentComponent.value = MyTicketsView; break;
  }
};

// 检查认证状态
const checkAuthStatus = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) {
    ElMessage.warning('请先登录');
    router.push('/login');
    return false;
  }
  return true;
};

// 获取用户信息
const fetchUserInfo = async () => {
  if (!checkAuthStatus()) return;

  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/user/detail/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    });
    
    const userData = response.data;
    username.value = userData.username;
    email.value = userData.email;
    userAvatar.value = userData.avatar || 'default-avatar-url';
    phoneNumber.value = userData.phone_number || '未设置';
    userId.value = userData.id;
    
    Object.assign(userForm, {
      username: userData.username,
      email: userData.email,
      avatar: userData.avatar,
      phone_number: userData.phone_number || ''
    });

    // 更新 store 中的用户信息
    userStore.setUserInfo(userData);
  } catch (error) {
    console.error('获取用户信息失败:', error);
    if (error.response?.status === 401) {
      localStorage.removeItem('accessToken');
      ElMessage.error('登录已过期，请重新登录');
      router.push('/login');
    } else {
      ElMessage.error('获取用户信息失败，请稍后重试');
    }
  }
};


onMounted(() => {
  if (checkAuthStatus()) {
    fetchUserInfo();

  }
});

// 组件切换
const avatarFile = ref(null);

const handleAvatarChange = (file) => {
  avatarFile.value = file.raw;
  userForm.avatar = URL.createObjectURL(file.raw);
};

const userFormRef = ref(null);

const rules = reactive({
  username: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
  ],
  phone_number: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号', trigger: 'blur' }
  ],
  captcha: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { len: 4, message: '验证码长度应为 4 位', trigger: 'blur' }
  ]
});

const submitForm = () => {
  userFormRef.value.validate((valid) => {
    if (valid) {
      updateProfile();
    } else {
      console.log('表单验证失败');
      return false;
    }
  });
};

const handleUnauthenticated = (error) => {
  if (error.response?.status === 401) {
    localStorage.removeItem('accessToken');
    ElMessage.error('登录已过期，请重新登录');
    router.push('/login');
  }
};

// 获取验证码
const getCaptcha = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/captcha/generate/`);
    captchaImage.value = response.data.image;
    captchaKey.value = response.data.key;
  } catch (error) {
    console.error('获取验证码失败:', error);
    ElMessage.error('获取验证码失败，请刷新重试');
  }
};

// 刷新验证码
const refreshCaptcha = () => {
  userForm.captcha = '';
  getCaptcha();
};

// 监听对话框的显示状态
watch(() => showEditDialog.value, (newVal) => {
  if (newVal) {
    getCaptcha();
  } else {
    userForm.captcha = '';
  }
});

const updateProfile = async () => {
  if (!checkAuthStatus()) return;

  try {
    const formData = new FormData();
    formData.append('username', userForm.username);
    formData.append('phone_number', userForm.phone_number);
    if (avatarFile.value) {
      formData.append('avatar', avatarFile.value);
    }
    formData.append('captcha_key', captchaKey.value);
    formData.append('captcha_code', userForm.captcha);
    
    console.log('Sending update request with data:', Object.fromEntries(formData));
    
    const response = await axios.put(`${API_BASE_URL}/api/v1/user/update/`, formData, {
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    
    console.log('Update response:', response.data);
    
    if (response.data && response.data.status === 200) {
      ElMessage.success(response.data.message || '个人资料更新成功');
      showEditDialog.value = false;
      
      // 更新显示的用户信息
      username.value = userForm.username;
      phoneNumber.value = userForm.phone_number;
      if (response.data.avatar) {
        userAvatar.value = response.data.avatar + '?t=' + new Date().getTime();
      }
      
      // 使用 Pinia store 更新用户信息
      userStore.updateUserInfo({
        username: username.value,
        avatar: userAvatar.value,
        phone_number: phoneNumber.value,
        email: email.value,
        id: userId.value
      });
      
      // 更新 localStorage
      localStorage.setItem('userName', username.value);
      localStorage.setItem('userAvatar', userAvatar.value);
      
      // 重新获取用户信息以确保所有字段是最新的
      await fetchUserInfo();
    } else {
      throw new Error(response.data.message || '更新失败');
    }
  } catch (error) {
    console.error('更新个人资料失败:', error);
    handleUnauthenticated(error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      if (error.response.data.phone_number) {
        ElMessage.error(error.response.data.phone_number[0] || '手机号更新失败');
      } else if (error.response.data.captcha) {
        ElMessage.error(error.response.data.captcha[0] || '验证码错误');
        refreshCaptcha();
      } else {
        ElMessage.error(error.response.data.message || '更新个人资料失败,请稍后重试');
      }
    } else if (error.request) {
      console.error('No response received:', error.request);
      ElMessage.error('服务器无响应,请检查网络连接');
    } else {
      console.error('Error details:', error.message);
      ElMessage.error(error.message || '更新个人资料失败,请稍后重试');
    }
  }
};


// 根据当前选中的菜单项更新标题
const updateTitle = (menuKey) => {
  const titles = {
    '1': '个人信息',
    '2': '安全设置',
    '3': '消息通知',
    '8': '发布问题',
    '4': '我的文章',
    '5': '我的收藏',
    '6': '发布博客',
    '7': '我的工单'
  }
  document.title = `个人中心 - ${titles[menuKey]}`
}
</script>
<style scoped>
.profile-container {
  padding: 12px;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.glass-effect {
  background: rgba(255, 255, 255, 0.95) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0 4px 16px rgba(31, 38, 135, 0.07) !important;
}

.user-card {
  text-align: center;
  margin-bottom: 16px;
  padding: 20px;
  border-radius: 12px;
}

.user-card .el-avatar {
  margin-bottom: 12px;
  border: 3px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-card h2 {
  margin: 8px 0;
  color: #2c3e50;
  font-size: 1.2rem;
  font-weight: 600;
}

.user-email, .user-phone {
  color: #606266;
  margin: 4px 0;
  font-size: 0.9rem;
}

.edit-btn {
  margin-top: 12px;
  width: 100%;
  border-radius: 6px;
  height: 36px;
  font-size: 0.95rem;
}

/* 菜单样式优化 */
.profile-menu {
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 16px;
}

.profile-menu .el-menu-item {
  height: 45px;
  line-height: 45px;
  margin: 4px 0;
  padding: 0 16px;
  font-size: 0.95rem;
  border-radius: 6px;
}

.profile-menu .el-menu-item:hover {
  background-color: rgba(64, 158, 255, 0.1);
}

.profile-menu .el-menu-item.is-active {
  background-color: #409eff;
  color: white;
}

.profile-menu .el-menu-item .el-icon {
  margin-right: 12px;
  font-size: 18px;
}

.content-card {
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
}

/* 移动端特别优化 */
@media screen and (max-width: 768px) {
  .profile-container {
    padding: 10px;
  }

  .main-content {
    margin-top: 0;
  }

  .left-panel {
    margin-bottom: 12px;
  }

  /* 安全设置表单样式 */
  :deep(.el-form-item__label) {
    font-size: 0.95rem;
    padding-bottom: 4px;
  }

  :deep(.el-input__inner) {
    height: 38px;
    font-size: 0.95rem;
  }

  :deep(.el-form-item) {
    margin-bottom: 16px;
  }

  :deep(.el-button) {
    height: 38px;
    font-size: 0.95rem;
  }

  /* 对话框样式 */
  :deep(.el-dialog) {
    width: 92% !important;
    border-radius: 12px;
    margin: 16px auto !important;
  }

  :deep(.el-dialog__title) {
    font-size: 1.1rem;
  }

  :deep(.el-dialog__body) {
    padding: 16px;
  }

  :deep(.el-dialog__footer) {
    padding: 12px 16px;
  }
}

/* 表单项样式 */
:deep(.el-form-item__required) {
  color: #f56c6c;
  margin-right: 4px;
}

:deep(.el-input__wrapper) {
  border-radius: 6px;
  box-shadow: 0 0 0 1px #dcdfe6 inset;
}

:deep(.el-input__wrapper:hover) {
  box-shadow: 0 0 0 1px #409eff inset;
}

:deep(.el-button--primary) {
  background-color: #409eff;
  border-color: #409eff;
}

/* 添加过渡动画 */
.el-menu-item,
.el-card,
.el-button {
  transition: all 0.3s ease;
}

/* 美化滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

/* 头像上传和预览样式 */
.avatar-uploader {
  text-align: center;
}

.preview-avatar {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 6px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-uploader-icon:hover {
  border-color: #409EFF;
}

/* 对话框底部按钮样式 */
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.dialog-footer .el-button {
  min-width: 80px;  /* 设置最小宽度 */
  margin: 0;  /* 清除默认外边距 */
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .dialog-footer {
    flex-direction: row;  /* 确保在移动端也保持水平排列 */
    width: 100%;
  }
  
  .dialog-footer .el-button {
    flex: 1;  /* 在移动端下平均分配空间 */
  }
}

.captcha-input :deep(.el-input-group__append) {
  padding: 0;
  background: #fff;
}

.captcha-wrapper {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.captcha-image {
  height: 30px;
  margin: 0 5px;
}
</style>

