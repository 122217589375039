import axios from 'axios'
import { useUserStore } from '@/stores/user'
import { API_BASE_URL } from '@/config'
import router from '@/router'
import { ElMessage } from 'element-plus'

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000
})

// 请求拦截器
axiosInstance.interceptors.request.use(async config => {
  const userStore = useUserStore()
  
  // 如果存在token，添加到请求头
  if (userStore.accessToken) {
    try {
      // 检查token是否即将过期
      if (userStore.isTokenExpiringSoon) {
        await userStore.refreshAccessToken()
      }
      config.headers.Authorization = `Bearer ${userStore.accessToken}`
    } catch (error) {
      // 如果是需要认证的请求，则清除用户信息并重定向
      if (config.requiresAuth) {
        userStore.clearUserInfo()
        router.push('/login')
        throw error
      }
      // 非认证请求继续发送，但不带token
      delete config.headers.Authorization
    }
  }
  
  return config
})

// 响应拦截器
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const userStore = useUserStore()
    const originalRequest = error.config

    // 如果是 401 错误且有 token（可能是 token 过期）
    if (error.response?.status === 401 && userStore.accessToken && !originalRequest._retry) {
      originalRequest._retry = true
      
      try {
        // 尝试刷新 token
        await userStore.refreshAccessToken()
        // 使用新 token 重试原请求
        originalRequest.headers.Authorization = `Bearer ${userStore.accessToken}`
        return axiosInstance(originalRequest)
      } catch (refreshError) {
        // token 刷新失败的处理
        userStore.clearUserInfo() // 清除用户信息
        
        if (originalRequest.requiresAuth) {
          // 对于需要认证的请求，显示提示信息
          ElMessage.warning('登录已过期，请重新登录')
        }
        
        // 继续抛出错误，让业务代码处理
        return Promise.reject(refreshError)
      }
    }
    
    return Promise.reject(error)
  }
)

export default axiosInstance 